import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Banner from './index';


const AboutUsBanner = ({image, imageMb, children}) => {
	const banner = {
		id: 'main-banner-1',
		image: '/img/about-us/banner.png',
		imageMb: '/img/about-us/banner-mobile.png',
		title: 'Về chúng tôi',
	};
	return (
		<Banner {...banner}>
			<div className="absolute top-[-90px] md:top-[-60px] w-full h-full flex flex-col justify-center">
				<div className='text-center font-bold text-3xl md:w-[845px]'>{banner.title}</div>
			</div>
		</Banner>
	);
};

export default AboutUsBanner;