// src/components/Home.js
import React from 'react';
import HiringBanner from '../components/Banner/HiringBanner';
import {useParams} from "react-router-dom";
import {JOB_SLUG} from "../data/hiring";

const Hiring = () => {
	const params = useParams();
	const {slug} = params || {}
	
	return (
		<div className="mx-auto w-full animate-fadeIn text-sm">
			<HiringBanner/>
			<br/><h2
			className='text-center w-full pt-6 font-bold text-xl uppercase text-primary'>{JOB_SLUG?.[slug]?.title || ''}</h2>
			{JOB_SLUG?.[slug]?.content || ''}
		</div>
	);
}

export default Hiring;