import React from 'react';
import Button from '../Button';
import cn from 'classnames';
import './style.css';
import NewLink from "../../NewLink/NewLink";

const InfoImageBlock = ({
	                        id,
	                        title,
	                        icon,
	                        description,
	                        button,
	                        image,
	                        position,
	                        bgClass,
	                        className,
	                        textClass,
	                        heightClass
                        }) => {
	return (
		<div id={id}
		     className={cn('relative md:px-[256px] md:h-auto pt-8 gap-5 flex flex-col md:flex-row rounded-lg items-center md:justify-center', heightClass || 'h-[450px]', className, bgClass ? bgClass : 'bg-[#FAFAFA]')}>
			<div
				className={cn('absolute md:relative z-10 md:flex flex-col justify-center items-center md:items-start px-8', textClass, position === 'right' && 'md:order-last')}>
				{icon && <img className='mb-3' src={icon} alt="icon"/>}
				<h2 className="font-bold mb-4 text-xl md:text-2xl text-gray-800">{title}</h2>
				<p className="text-gray-600 mb-6 text-sm md:text-left pr-8"
				   dangerouslySetInnerHTML={{__html: description}}/>
				{
					button && (
						<NewLink to={button.to}>
							<Button variant={button.variant} className="text-sm">
								{button.text}
							</Button>
						</NewLink>
					)}
			</div>
			<div className={cn('absolute md:relative bottom-0 md:w-fit flex w-full', position === 'right' && 'justify-end')}>
				<img
					src={image}
					alt="InfoImageBlock"
					className="image-opacity w-full h-full"
				/>
			</div>
		</div>
	);
};

export default InfoImageBlock;
