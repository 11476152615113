// src/components/Home.js
import React from 'react';
import CardList from '../components/CardList/CardList';
import ImageList from '../components/ImageList';
import FeatureBlock from '../components/FeatureBlock';
import FloatQRCode from '../components/FloatQRCode';
import GuildTabs from '../components/GuildTabs';
import MasterBanner from '../components/HomeBanner';
import InfoImageBlock from '../components/Common/InfoImageBlock';
import { PATH, VARIANT } from '../constants';

const Home = () => (
  <div className="mx-auto w-full animate-fadeIn">
    <h1 className="hidden">GooPay - Giải pháp thanh toán toàn diện</h1>
    <MasterBanner />
    <CardList />
    <InfoImageBlock
        title='VỀ CHÚNG TÔI'
        image='/img/about-us-frame.png'
        textClass='md:w-[533px] text-center md:text-left'
        heightClass='h-[550px]'
        description='GooPay ra đời với sứ mệnh cung cấp những giải pháp tài chính chuyên biệt giúp giải quyết các vấn đề thanh toán
					khi di chuyển, chuyển phát hàng hóa trở nên thuận tiện và nhanh chóng, cùng hệ sinh thái đa dạng và phong phú,
					mang tới những trải nghiệm dịch vụ hoàn hảo cho khách hàng và đối tác.'
        button={{
          text: 'Xem thêm',
            variant: VARIANT.PRIMARY,
          to: PATH.ABOUT
        }}
    />
    <ImageList />
    <FeatureBlock />
    <GuildTabs />
    <FloatQRCode />
  </div>
);

export default Home;