// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {FAQ_ITEMS} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQ = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner />
		<div className='px-8 md:px-[150px] py-8 grid md:grid-cols-3'>
			{
				FAQ_ITEMS.map((data, index) => (
					<div key={index} className='flex py-4 gap-2 items-center mr-4 hover:text-[#0073DD]'>
						<img className="w-[10px] h-[20px]" src="/img/icon/rectangle.png" alt="rectangle"/>
						<NewLink className='font-bold text-lg' to={data.path}>{data.title}</NewLink>
					</div>
				))
			}
		</div>
	</div>
);

export default FAQ;