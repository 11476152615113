// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {Link} from "react-router-dom";
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQSafetySecure = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='An toàn và bảo mật'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>An toàn và bảo mật</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<h2 style="font-size: 18px;font-weight:bold; text-align: center; color: #338FE3;">X&aacute;c thực NFC</h2><br/>' +
				     '<p><strong>1. Tại sao phải x&aacute;c thực CCCD gắn chip? Chứng từ kh&aacute;c được kh&ocirc;ng?</strong></p>\n' +
				     '<p>Theo Quyết định 2345/QĐ-NHNN về việc triển khai c&aacute;c giải ph&aacute;p an to&agrave;n, bảo mật trong thanh to&aacute;n trực tuyến v&agrave; thanh to&aacute;n thẻ ng&acirc;n h&agrave;ng, việc tạo t&agrave;i khoản, đăng nhập tr&ecirc;n thiết bị kh&aacute;c, hoặc thực hiện c&aacute;c giao dịch vượt hạn mức quy định tr&ecirc;n nền tảng V&iacute; điện tử (như GooPay) cần phải x&aacute;c thực bằng phương ph&aacute;p sinh trắc học (ở GooPay l&agrave; x&aacute;c thực khu&ocirc;n mặt). Th&ocirc;ng tin x&aacute;c thực n&agrave;y phải được lưu trữ trong cơ sở dữ liệu sinh trắc học của kh&aacute;ch h&agrave;ng v&agrave; so khớp với dữ liệu sinh trắc học thu thập từ qu&eacute;t NFC thẻ CCCD gắn chip do Cơ quan C&ocirc;ng an cấp.</p>\n' +
				     '<p>Theo đ&oacute;, việc x&aacute;c thực bằng c&aacute;ch chụp ảnh CMND/CCCD/Hộ chiếu trước đ&acirc;y sẽ kh&ocirc;ng c&ograve;n được chấp nhận từ khi Quyết định 2345 c&oacute; hiệu lực từ 01/07/2024. Để đảm bảo t&agrave;i khoản của bạn được x&aacute;c thực ch&iacute;nh chủ v&agrave; tu&acirc;n thủ quy định ph&aacute;p luật, bạn cần cập nhật x&aacute;c thực bằng c&aacute;ch qu&eacute;t NFC thẻ CCCD gắn chip c&agrave;ng sớm c&agrave;ng tốt.</p>\n' +
				     '<p><strong>2. C&ocirc;ng nghệ NFC l&agrave; g&igrave;?</strong></p>\n' +
				     '<p>NFC l&agrave; c&ocirc;ng nghệ kết nối kh&ocirc;ng d&acirc;y tầm ngắn trong khoảng c&aacute;ch 4 cm. C&ocirc;ng nghệ n&agrave;y sử dụng cảm ứng từ trường để thực hiện kết nối giữa c&aacute;c thiết bị (smartphone, tablet,...) với CCCD gắn chip khi c&oacute; sự tiếp x&uacute;c trực tiếp (chạm) để chuyển đổi dữ liệu.</p>\n' +
				     '<p>GooPay &aacute;p dụng c&ocirc;ng nghệ qu&eacute;t NFC để đọc dữ liệu từ CCCD gắn chip của bạn v&agrave; tiến h&agrave;nh x&aacute;c thực th&ocirc;ng tin với Bộ C&ocirc;ng an, nhằm đảm bảo t&agrave;i khoản của bạn l&agrave; ch&iacute;nh chủ v&agrave; tu&acirc;n thủ theo quy định ph&aacute;p luật.</p>\n' +
				     '<p><strong>3. Th&ocirc;ng tin từ việc qu&eacute;t NFC kh&ocirc;ng đ&uacute;ng với th&ocirc;ng tin của t&ocirc;i?</strong></p>\n' +
				     '<p>Th&ocirc;ng tin đọc ra từ thẻ căn cước c&ocirc;ng d&acirc;n của bạn l&agrave; th&ocirc;ng tin m&agrave; Cơ quan C&ocirc;ng An địa phương thu thập v&agrave; lưu trữ trong bộ dữ liệu d&acirc;n cư quốc gia. Th&ocirc;ng tin n&agrave;y bao gồm: số căn cước c&ocirc;ng d&acirc;n, họ v&agrave; t&ecirc;n, họ v&agrave; t&ecirc;n kh&aacute;c, ng&agrave;y, th&aacute;ng, năm sinh, giới t&iacute;nh, quốc tịch, d&acirc;n tộc, t&ocirc;n gi&aacute;o, ...</p>\n' +
				     '<p>Trong một số trường hợp, th&ocirc;ng tin đọc ra từ thẻ CCCD c&oacute; thể kh&ocirc;ng tr&ugrave;ng khớp ho&agrave;n to&agrave;n với th&ocirc;ng tin được in tr&ecirc;n thẻ, do vấn đề về độ d&agrave;i k&yacute; tự của thẻ vật l&yacute; kh&ocirc;ng đủ kh&ocirc;ng gian để n&ecirc;u r&otilde;.</p>\n' +
				     '<p>Trong trường hợp th&ocirc;ng tin đọc ra từ thẻ CCCD gắn chip kh&aacute;c ho&agrave;n to&agrave;n với th&ocirc;ng tin c&aacute; nh&acirc;n của bạn, bạn vui l&ograve;ng đến cơ quan C&ocirc;ng an ở địa phương để khai b&aacute;o v&agrave; x&eacute;t duyệt cấp lại nếu cần.</p>\n' +
				     '<p><strong>4. Bao l&acirc;u th&igrave; x&aacute;c thực NFC của t&ocirc;i sẽ được duyệt?</strong></p>\n' +
				     '<p>Th&ocirc;ng thường, qu&aacute; tr&igrave;nh x&aacute;c thực NFC của bạn sẽ được x&eacute;t duyệt v&agrave; kết quả sẽ được trả ngay khi th&ocirc;ng tin đọc ra từ NFC được x&aacute;c nhận tr&ugrave;ng khớp với th&ocirc;ng tin c&aacute; nh&acirc;n của bạn.</p>\n' +
				     '<p>Tuy nhi&ecirc;n, trong trường hợp bạn c&oacute; hoạt động điều chỉnh/thay đổi th&ocirc;ng tin hoặc GooPay cần th&ecirc;m thời gian để x&aacute;c thực th&ocirc;ng tin của bạn, bạn sẽ nhận được th&ocirc;ng b&aacute;o từ GooPay tr&ecirc;n m&agrave;n h&igrave;nh kết quả x&aacute;c thực NFC. Th&ocirc;ng thường, qu&aacute; tr&igrave;nh n&agrave;y sẽ kh&ocirc;ng mất qu&aacute; 24 giờ.</p>\n' +
				     '<p><strong>5. Nếu kh&ocirc;ng x&aacute;c thực NFC thẻ CCCD gắn chip th&igrave; t&ocirc;i sẽ gặp vấn đề g&igrave; ở GooPay?</strong></p>\n' +
				     '<p>Trong trường hợp bạn kh&ocirc;ng x&aacute;c thực NFC thẻ CCCD gắn chip, sau ng&agrave;y 01/07/2024, bạn sẽ bị hạn chế một số loại giao dịch t&ugrave;y theo hạn mức, theo Quyết định 2345/QĐ-NHNN. Điều n&agrave;y sẽ ảnh hưởng đến trải nghiệm sử dụng GooPay của bạn v&agrave; GooPay kh&ocirc;ng thể đảm bảo an to&agrave;n cho t&agrave;i khoản của bạn.</p>\n' +
				     '<p><strong>6. Điện thoại của t&ocirc;i c&oacute; qu&eacute;t được NFC kh&ocirc;ng? L&agrave;m sao để bật t&iacute;nh năng NFC tr&ecirc;n điện thoại?</strong></p>\n' +
				     '<p>- Đối với thiết bị sử dụng hệ điều h&agrave;nh iOS, đầu đọc NFC được hỗ trợ từ d&ograve;ng điện thoại iPhone 6s trở l&ecirc;n. Th&ocirc;ng thường, t&iacute;nh năng NFC lu&ocirc;n được k&iacute;ch hoạt tr&ecirc;n c&aacute;c d&ograve;ng điện thoại iPhone.</p>\n' +
				     '<p>- Đối với thiết bị Android, bạn c&oacute; thể kiểm tra xem thiết bị của bạn c&oacute; hỗ trợ NFC hay kh&ocirc;ng bằng c&aacute;ch t&igrave;m kiếm tr&ecirc;n internet với c&uacute; ph&aacute;p: ""[t&ecirc;n thi=""""] c&oacute; hỗ trợ NFC kh&ocirc;ng?"" Nếu thiết bị của bạn c&oacute; hỗ trợ NFC, bạn c&oacute; thể bật hoặc tắt NFC theo c&aacute;c bước sau:</p>\n' +
				     '<p>V&agrave;o C&agrave;i đặt tr&ecirc;n điện thoại của bạn.</p>\n' +
				     '<p>Chọn mục ""Thiết bị"" hoặc ""Kết nối"" (t&ugrave;y thuộc v&agrave;o giao diện của thiết bị).</p>\n' +
				     '<p>T&igrave;m v&agrave; chọn t&ugrave;y chọn NFC.</p>\n' +
				     '<p>K&eacute;o thanh trượt để bật hoặc tắt NFC.</p>\n' +
				     '<p>Ngo&agrave;i ra, bạn cũng c&oacute; thể bật hoặc tắt NFC tr&ecirc;n điện thoại Android bằng c&aacute;ch k&eacute;o thanh th&ocirc;ng b&aacute;o xuống v&agrave; chạm v&agrave;o biểu tượng NFC (nếu c&oacute;) để k&iacute;ch hoạt hoặc tắt t&iacute;nh năng n&agrave;y.</p>\n' +
				     '<p><strong>7. T&ocirc;i c&oacute; thể x&aacute;c thực bằng c&aacute;ch kh&aacute;c nếu kh&ocirc;ng qu&eacute;t được NFC kh&ocirc;ng?</strong></p>\n' +
				     '<p>Theo Quyết định 2345/QĐ-NHNN về việc triển khai c&aacute;c giải ph&aacute;p an to&agrave;n, bảo mật trong thanh to&aacute;n trực tuyến v&agrave; thanh to&aacute;n thẻ ng&acirc;n h&agrave;ng, việc thực hiện c&aacute;c h&agrave;nh vi tạo t&agrave;i khoản, đăng nhập tr&ecirc;n thiết bị kh&aacute;c, hoặc thực hiện c&aacute;c giao dịch chạm hạn mức quy định trong Quyết định n&agrave;y tr&ecirc;n nền tảng V&iacute; điện tử (ở đ&acirc;y l&agrave; GooPay) cần phải x&aacute;c thực bằng phương ph&aacute;p x&aacute;c thực sinh trắc học (ở GooPay l&agrave; X&aacute;c thực khu&ocirc;n mặt), v&agrave; th&ocirc;ng tin x&aacute;c thực n&agrave;y cần phải được lưu trong cơ sở dữ liệu sinh trắc học về kh&aacute;ch h&agrave;ng đ&atilde; thu thập v&agrave; so khớp với dữ liệu sinh trắc học đọc ra bằng c&aacute;c qu&eacute;t NFC thẻ CCCD gắn chip do Cơ quan C&ocirc;ng an cấp.</p>\n' +
				     '<p>Điều n&agrave;y dẫn đến việc bạn cần phải x&aacute;c thực NFC thẻ CCCD gắn chip (nếu chưa x&aacute;c thực trước đ&oacute;) khi thực hiện tạo t&agrave;i khoản, đổi thiết bị hoặc giao dịch chạm hạn mức v&agrave; x&aacute;c thực khu&ocirc;n mặt (so khớp th&ocirc;ng tin khu&ocirc;n mặt với khu&ocirc;n mặt trong thẻ CCCD gắn chip đ&atilde; thu thập trước đ&oacute;).</p>\n' +
				     '<br/><h2 style="font-size: 18px;font-weight:bold; text-align: center; color: #338FE3;">Ch&iacute;nh s&aacute;ch bảo mật v&agrave; quyền ri&ecirc;ng tư</h2><br/>' +
				     '<ol start="1">' +
				     '<li><strong>Li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng của t&ocirc;i với GooPay c&oacute; an to&agrave;n kh&ocirc;ng?</strong></li>\n' +
				     '<p>Bạn ho&agrave;n to&agrave;n an t&acirc;m khi li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng của bạn với t&agrave;i khoản GooPay.</p>\n' +
				     '<p>Trước hết, để li&ecirc;n kết, bạn phải nhập m&atilde; OTP được gửi từ ng&acirc;n h&agrave;ng nhằm đảm bảo l&agrave; ch&iacute;nh chủ của t&agrave;i khoản đang thực hiện li&ecirc;n kết. Trong qu&aacute; tr&igrave;nh sử dụng, mọi giao dịch ph&aacute;t sinh tr&ecirc;n GooPay hoặc t&agrave;i khoản ng&acirc;n h&agrave;ng li&ecirc;n kết đều phải được bạn x&aacute;c nhận. Ngo&agrave;i ra, GooPay tuyệt đối kh&ocirc;ng lưu th&ocirc;ng tin t&agrave;i khoản ng&acirc;n h&agrave;ng/ thẻ của bạn.</p>\n' +
				     '</ol>\n' +
				     '<ol start="2">' +
				     '<li><strong>GooPay c&oacute; những cơ chế g&igrave; để ph&ograve;ng chống lừa đảo v&agrave; lấy cắp t&agrave;i khoản?</strong></li>' +
				     '<p>GooPay sử dụng c&aacute;c c&ocirc;ng nghệ sau để ph&ograve;ng ngừa rủi ro kẻ gian lấy cắp t&agrave;i khoản của bạn:</p>\n' +
				     '<ol>\n' +
				     '<li>Khi c&oacute; người đăng nhập t&agrave;i khoản GooPay của bạn tr&ecirc;n một thiết bị di động kh&aacute;c, GooPay sẽ gửi Voice OTP k&egrave;m lời cảnh b&aacute;o đến số điện thoại đăng k&yacute; t&agrave;i khoản GooPay của bạn với nội dung: &ldquo;Bạn đang đăng nhập V&iacute; GooPay tr&ecirc;n thiết bị kh&aacute;c. Tuyệt đối kh&ocirc;ng chia sẻ m&atilde; x&aacute;c thực OTP n&agrave;y với bất kỳ ai. M&atilde; x&aacute;c thực l&agrave;: xxxxxx.&rdquo;</li>\n' +
				     '</ol>\n' +
				     '<p>Ngo&agrave;i ra, với một số giao dịch đầu ti&ecirc;n được thực hiện tr&ecirc;n thiết bị mới v&agrave; được GooPay đ&aacute;nh gi&aacute; l&agrave; đ&aacute;ng ngờ, GooPay cũng y&ecirc;u cầu nhập m&atilde; x&aacute;c thực OTP được gửi qua tin nhắn đến số điện thoại đăng k&yacute; GooPay.</p>\n' +
				     '<ol start="2">\n' +
				     '<li>C&ocirc;ng nghệ th&ocirc;ng minh của GooPay tự động đ&aacute;nh gi&aacute; v&agrave; nhận biết c&aacute;c giao dịch bất thường, dựa tr&ecirc;n việc ph&acirc;n t&iacute;ch c&aacute;c giao dịch trong qu&aacute; khứ tr&ecirc;n t&agrave;i khoản GooPay của bạn. Khi ph&aacute;t hiện bất thường, GooPay lập tức chặn giao dịch, ngăn ngừa sự thất tho&aacute;t tiền trong t&agrave;i khoản của của bạn.</li>\n' +
				     '</ol>\n' +
				     '</ol>\n' +
				     
				     '<ol start="3">\n' +
				     '<li>GooPay thu thập c&aacute;c th&ocirc;ng tin n&agrave;o của t&ocirc;i v&agrave; d&ugrave;ng để l&agrave;m g&igrave;?</li>\n' +
				     '</ol>\n' +
				     '<p>👉 Ch&uacute;ng t&ocirc;i thu thập c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>Th&ocirc;ng tin do bạn cung cấp.</p>\n' +
				     '<p>Th&ocirc;ng tin được thu thập tự động.</p>\n' +
				     '<p>Hoạt động của bạn v&agrave; c&aacute;c th&ocirc;ng tin được tạo ra khi bạn tương t&aacute;c với c&aacute;c Dịch vụ của GooPay.</p>\n' +
				     '<p>Th&ocirc;ng tin từ c&aacute;c nguồn kh&aacute;c như từ đối t&aacute;c thương mại v&agrave; c&aacute;c b&ecirc;n thứ ba kh&aacute;c.</p>\n' +
				     '<p>👉 C&aacute;c th&ocirc;ng tin của bạn được ch&uacute;ng t&ocirc;i sử dụng để vận h&agrave;nh, tăng cường bảo mật cho t&agrave;i khoản, cung cấp v&agrave; cải tiến Dịch vụ, tu&acirc;n thủ c&aacute;c quy định ph&aacute;p luật (chỉ sau khi được bạn đồng &yacute;). Một số th&ocirc;ng tin l&agrave; kh&ocirc;ng bắt buộc, tuy nhi&ecirc;n nếu kh&ocirc;ng được cung cấp sẽ ảnh hưởng đến chất lượng v&agrave; trải nghiệm của bạn. Chi tiết bạn c&oacute; thể xem tại Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư.</p>\n' +
				     '<p><br /><br /></p>\n' +
				     '<ol start="4">\n' +
				     '<li>T&ocirc;i c&oacute; đọc một số điều khoản nhưng chưa hiểu r&otilde;, t&ocirc;i phải l&agrave;m sao?</li>\n' +
				     '</ol>\n' +
				     '<p>Trong trường hợp c&oacute; bất kỳ thắc mắc n&agrave;o về c&aacute;c điều kiện điều khoản n&agrave;y, bạn vui l&ograve;ng gửi th&ocirc;ng tin tại mục \'Trung t&acirc;m trợ gi&uacute;p\' tr&ecirc;n ứng dụng để GooPay hỗ trợ kiểm tra v&agrave; xử l&yacute; kịp thời.</p>\n' +
				     '<ol start="5">\n' +
				     '<li>V&igrave; sao GooPay kh&ocirc;ng y&ecirc;u cầu x&aacute;c thực bằng m&atilde; OTP khi nạp tiền từ ng&acirc;n h&agrave;ng li&ecirc;n kết?</li>\n' +
				     '</ol>\n' +
				     '<p>Việc kh&ocirc;ng y&ecirc;u cầu x&aacute;c thực bằng m&atilde; OTP khi nạp tiền từ t&agrave;i khoản ng&acirc;n h&agrave;ng li&ecirc;n kết v&agrave;o GooPay gi&uacute;p bạn thanh to&aacute;n tiện lợi, nhanh ch&oacute;ng m&agrave; vẫn đảm bảo an to&agrave;n. Khi bạn li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng với t&agrave;i khoản GooPay, bạn đ&atilde; phải trải qua c&aacute;c bước x&aacute;c thực danh t&iacute;nh v&agrave; nhập m&atilde; OTP.</p>\n' +
				     '<p>Một số ng&acirc;n h&agrave;ng như BIDV, Vietinbank v&agrave; VIB vẫn &aacute;p dụng x&aacute;c thực bằng m&atilde; OTP khi bạn thực hiện giao dịch vượt qu&aacute; hạn mức quy định của c&aacute;c ng&acirc;n h&agrave;ng n&agrave;y.</p>\n' +
				     '<p>Ngo&agrave;i ra, bạn ho&agrave;n to&agrave;n y&ecirc;n t&acirc;m l&agrave; hệ thống bảo mật của GooPay đảm bảo an to&agrave;n cho t&agrave;i khoản v&agrave; mọi giao dịch của bạn.&nbsp;</p>\n' +
				     '<ol start="6">\n' +
				     '<li>GooPay hỗ trợ xử l&yacute; lừa đảo v&agrave; lấy cắp t&agrave;i khoản như thế n&agrave;o?</li>\n' +
				     '</ol>\n' +
				     '<p>Khi GooPay tiếp nhận b&aacute;o c&aacute;o về h&agrave;nh vi lừa đảo, GooPay sẽ hỗ trợ bạn kiểm tra nguồn gốc giao dịch bất thường v&agrave; kh&oacute;a t&agrave;i khoản V&iacute; GooPay của bạn. Đồng thời, GooPay khuyến nghị bạn tr&igrave;nh b&aacute;o vấn đề của m&igrave;nh với c&ocirc;ng an PC50 - Ph&ograve;ng cảnh s&aacute;t ph&ograve;ng chống tội phạm c&ocirc;ng nghệ cao để được hỗ trợ. GooPay sẽ phối hợp với đơn vị điều tra khi nhận được y&ecirc;u cầu cung cấp c&aacute;c dữ liệu li&ecirc;n quan để phục vụ cho qu&aacute; tr&igrave;nh điều tra.</p>\n' +
				     '<p>Th&ocirc;ng tin li&ecirc;n lạc của Ph&ograve;ng cảnh s&aacute;t ph&ograve;ng chống tội phạm c&ocirc;ng nghệ cao.</p>\n' +
				     '<p>Địa chỉ:</p>\n' +
				     '<p>- H&agrave; Nội: số 47 Phạm Văn Đồng, quận Cầu Giấy</p>\n' +
				     '<p>- TP.HCM: số 258 Nguyễn Tr&atilde;i, quận 1</p>\n' +
				     '<p>Số điện thoại: 069.232.1154 hoặc 069.232.1161</p>\n' +
				     '<p>Email: C50@canhsat.vn</p>\n' +
				     '<ol start="7">\n' +
				     '<li>T&ocirc;i muốn x&oacute;a c&aacute;c th&ocirc;ng tin m&agrave; GooPay đ&atilde; thu thập của t&ocirc;i?</li>\n' +
				     '</ol>\n' +
				     '<p>GooPay thu thập c&aacute;c dữ liệu v&agrave; th&ocirc;ng tin cần thiết để đảm bảo việc cung cấp v&agrave; vận h&agrave;nh c&aacute;c sản phẩm, dịch vụ theo quy định ph&aacute;p luật (chỉ sau khi được bạn đồng &yacute;).</p>\n' +
				     '<p>👉 Nếu bạn y&ecirc;u cầu x&oacute;a th&ocirc;ng tin, GooPay sẽ kh&ocirc;ng thể c&oacute; đủ th&ocirc;ng tin về bạn để duy tr&igrave; đầy đủ t&iacute;nh năng nhằm đảm bảo trải nghiệm của bạn tr&ecirc;n GooPay. V&iacute; dụ, nếu bạn kh&ocirc;ng cung cấp th&ocirc;ng tin định danh, GooPay sẽ kh&ocirc;ng thể đ&aacute;nh gi&aacute; điểm tin cậy để bạn c&oacute; thể tiếp cận được c&aacute;c sản phẩm đầu tư hay t&agrave;i ch&iacute;nh. Bạn h&atilde;y xem x&eacute;t kỹ trước khi y&ecirc;u cầu xo&aacute; th&ocirc;ng tin.</p>\n' +
				     '<p>👉 Trong trường hợp bạn vẫn muốn x&oacute;a c&aacute;c th&ocirc;ng tin m&agrave; bạn đ&atilde; cung cấp, bạn thể xem x&eacute;t y&ecirc;u cầu x&oacute;a t&agrave;i khoản v&iacute; GooPay (xem th&ocirc;ng tin chi tiết tại đ&acirc;y). Tuy nhi&ecirc;n, khi đ&atilde; xo&aacute; c&aacute;c th&ocirc;ng tin, GooPay rất tiếc kh&ocirc;ng thể tiếp tục cung cấp dịch vụ cho bạn.</p>\n' +
				     '<br/><h2 style="font-size: 18px;font-weight:bold; text-align: center; color: #338FE3;">Biện ph&aacute;p tăng cường bảo mật</h2><br/>' +
				     '<p><strong>1. C&agrave;i đặt x&aacute;c thực mật khẩu khi mở m&agrave;n h&igrave;nh ứng dụng</strong></p>\n' +
				     '<p>Để đảm bảo bảo mật t&agrave;i khoản, ứng dụng GooPay sẽ tự động y&ecirc;u cầu mật khẩu khi bạn mở ứng dụng sau 5 ph&uacute;t kh&ocirc;ng sử dụng hoặc sau khi đ&atilde; tắt ứng dụng.</p>\n' +
				     '<p>Bạn c&oacute; thể tăng cường độ bảo mật bằng c&aacute;ch c&agrave;i đặt lu&ocirc;n y&ecirc;u cầu mật khẩu mỗi khi mở m&agrave;n h&igrave;nh ứng dụng GooPay, theo c&aacute;c bước sau:</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn ""V&iacute; của t&ocirc;i""</p>\n' +
				     '<p>Bước 2: Chọn ""C&agrave;i đặt"" rồi chọn ""Thiết lập bảo vệ t&agrave;i khoản"".</p>\n' +
				     '<p>Bước 3: Bật t&iacute;nh năng ""X&aacute;c thực mật khẩu khi mở ứng dụng"" (bạn sẽ cần nhập lại mật khẩu để tắt t&iacute;nh năng n&agrave;y)</p>\n' +
				     '<p><strong>2. V&igrave; sao cần x&aacute;c thực t&agrave;i khoản?</strong></p>\n' +
				     '<p>Theo Th&ocirc;ng tư 23/2019/TT-NHNN ng&agrave;y 22 th&aacute;ng 11 năm 2019 sửa đổi, bổ sung một số điều trong Th&ocirc;ng tư 39/2014/TT-NHNN ng&agrave;y 11 th&aacute;ng 12 năm 2014 của Thống đốc Ng&acirc;n h&agrave;ng Nh&agrave; nước Việt Nam, tất cả V&iacute; điện tử đang hoạt động phải tiến h&agrave;nh x&aacute;c thực hồ sơ người d&ugrave;ng trước khi đến thời hạn (dự kiến l&agrave; ng&agrave;y 07/07/2020).</p>\n' +
				     '<p>Sau thời gian dự kiến n&agrave;y, nếu t&agrave;i khoản V&iacute; GooPay của bạn chưa được x&aacute;c thực, V&iacute; GooPay của bạn sẽ được tạm kh&oacute;a dịch vụ cho đến khi việc x&aacute;c thực ho&agrave;n tất.</p>\n' +
				     '<p><strong>3. C&aacute;ch bật t&iacute;nh năng x&aacute;c thực v&acirc;n tay</strong></p>\n' +
				     '<p>"&aacute;c thực v&acirc;n tay d&ugrave;ng cho việc sử dụng dấu v&acirc;n tay để đăng nhập v&agrave;o ứng dụng v&agrave; x&aacute;c nhận thanh to&aacute;n một c&aacute;ch nhanh ch&oacute;ng v&agrave; tiện lợi. Lưu &yacute;: tất cả v&acirc;n tay đ&atilde; được đăng k&yacute; trong thiết bị của bạn đều đ&atilde; được x&aacute;c thực.</p>\n' +
				     '<p>Để bật t&iacute;nh năng x&aacute;c thực v&acirc;n tay, bạn h&atilde;y thực hiện c&aacute;c bước sau:</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn ""V&iacute; của t&ocirc;i"".</p>\n' +
				     '<p>Bước 2: Chọn ""C&agrave;i đặt"" rồi chọn ""Thiết lập bảo vệ t&agrave;i khoản"". Truy cập nhanh tại đ&acirc;y.</p>\n' +
				     '<p>Bước 3: Bật chức năng x&aacute;c thực v&acirc;n tay (bạn sẽ cần nhập lại mật khẩu để bật chức năng n&agrave;y)</p>\n' +
				     '<p><strong>4. Khi n&agrave;o n&ecirc;n đổi mật khẩu?</strong></p>\n' +
				     '<p>GooPay c&oacute; quy định đổi mật khẩu định kỳ 6 th&aacute;ng 1 lần v&agrave; mật khẩu mới kh&ocirc;ng được tr&ugrave;ng với mật khẩu đang d&ugrave;ng.</p>\n' +
				     '<p>Tuy nhi&ecirc;n GooPay khuyến nghị bạn đổi mật khẩu thường xuy&ecirc;n hoặc khi bạn nghi ngờ c&oacute; kẻ gian đang t&igrave;m c&aacute;ch truy cập v&agrave;o t&agrave;i khoản GooPay của bạn. Bạn lưu &yacute; đặt mật khẩu c&oacute; độ bảo mật cao (kh&ocirc;ng li&ecirc;n quan đến c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n như ng&agrave;y sinh, số điện thoại, kh&ocirc;ng đặt 6 số tr&ugrave;ng nhau, 6 số li&ecirc;n tiếp...).</p>\n' +
				     '<ol start="5"><li><strong>Nhận biết dấu hiệu lừa đảo như thế n&agrave;o?</strong></li>' +
				     '<p>Những kẻ lừa đảo thường giả mạo GooPay li&ecirc;n lạc với bạn để y&ecirc;u cầu tiết lộ c&aacute;c th&ocirc;ng tin bảo mật như mật khẩu, th&ocirc;ng tin t&agrave;i khoản ng&acirc;n h&agrave;ng, m&atilde; OTP, v.v&hellip;</p>\n' +
				     '<p>Để cảnh gi&aacute;c với kẻ gian, xin bạn lưu &yacute; những điều sau:</p>\n' +
				     '<ol>\n' +
				     '<li>GooPay KH&Ocirc;NG BAO GIỜ y&ecirc;u cầu bạn cung cấp m&atilde; OTP, mật khẩu t&agrave;i khoản GooPay hay số t&agrave;i khoản ng&acirc;n h&agrave;ng. H&atilde;y b&aacute;o ngay với GooPay khi c&oacute; người y&ecirc;u cầu bạn cung cấp những th&ocirc;ng tin n&agrave;y.</li>\n' +
				     '</ol>\n' +
				     '<ol start="2">\n' +
				     '<li>GooPay chỉ li&ecirc;n hệ trực tiếp với bạn th&ocirc;ng qua c&aacute;c h&igrave;nh thức sau:</li>\n' +
				     '</ol>\n' +
				     '<p>- Điện thoại: 19006038</p>\n' +
				     '<p>- Email: <u style="color: #0073DD">cskh@goopay.vn</u></p>\n' +
				     '<p>- Facebook của V&iacute; GooPay: https://www.facebook.com/goopay/&nbsp;</p>\n' +
				     '<ol start="3">\n' +
				     '<li>Bạn c&oacute; thể cập nhật hoặc kiểm tra th&ocirc;ng tin ch&iacute;nh thức từ GooPay qua c&aacute;c k&ecirc;nh sau:</li>\n' +
				     '</ol>\n' +
				     '<p>- Website GooPay: Goopay.vn</p>\n' +
				     '<p>- Fanpage Facebook của V&iacute; GooPay: https://www.facebook.com/goopay/&nbsp;</p>\n' +
				     '<p>- Li&ecirc;n hệ với bộ phận CSKH của GooPay. Xem c&aacute;ch thức li&ecirc;n hệ tại đ&acirc;y.</p>\n' +
				     '</ol>\n' +
				     '<p><strong>6. T&ocirc;i muốn b&aacute;o c&aacute;o h&agrave;nh vi giả mạo GooPay</strong></p>\n' +
				     '<p>Vui l&ograve;ng để lại lời nhắn b&ecirc;n dưới cho bộ phận CSKH v&agrave; cung cấp c&aacute;c th&ocirc;ng tin sau:</p>\n' +
				     '<p>- Nội dung: ph&aacute;t hiện h&agrave;nh vi giả mạo</p>\n' +
				     '<p>- M&ocirc; tả chi tiết h&agrave;nh vi</p>\n' +
				     '<p>Hoặc bạn c&oacute; thể li&ecirc;n hệ với bộ phận CSKH của GooPay qua c&aacute;c c&aacute;ch kh&aacute;c.&nbsp;</p>\n' +
				     '<p><strong>7. V&igrave; sao cần x&aacute;c thực email?</strong></p>\n' +
				     '<p>Sau bước tạo t&agrave;i khoản với GooPay, GooPay sẽ gửi email đến địa chỉ email đ&atilde; nhập của bạn để x&aacute;c nhận đ&acirc;y đ&uacute;ng l&agrave; địa chỉ email của bạn. Sau khi đ&atilde; x&aacute;c thực email, bạn sẽ nhận được c&aacute;c th&ocirc;ng tin li&ecirc;n quan tới bảo mật t&agrave;i khoản trong c&aacute;c trường hợp như:</p>\n' +
				     '<p>- Cảnh b&aacute;o t&agrave;i khoản được đăng nhập tr&ecirc;n thiết bị kh&aacute;c</p>\n' +
				     '<p>- Lấy lại mật khẩu khi bạn c&oacute; y&ecirc;u cầu</p>\n' +
				     '<p>- Th&ocirc;ng tin kết quả giao dịch</p>\n' +
				     '<br/><h2 style="font-size: 18px;font-weight:bold; text-align: center; color: #338FE3;">Sự cố khẩn cấp</h2><br/>' +
				     '<p><strong>1. Hướng dẫn kiểm tra khi ph&aacute;t hiện giao dịch bất thường</strong></p>\n' +
				     '<p>Trước hết, bạn vui l&ograve;ng kiểm tra trong mục "Lịch Sử Giao Dịch" xem t&agrave;i khoản GooPay của bạn c&oacute; ph&aacute;t sinh giao dịch n&agrave;o m&agrave; bạn kh&ocirc;ng thực hiện hay kh&ocirc;ng. Trong trường hợp t&agrave;i khoản GooPay đ&atilde; ph&aacute;t sinh giao dịch m&agrave; bạn kh&ocirc;ng thực hiện hoặc bạn đ&atilde; cung cấp th&ocirc;ng tin mật khẩu/ m&atilde; OTP cho người kh&aacute;c hoặc nghi ngờ c&oacute; người kh&aacute;c biết được th&ocirc;ng tin V&iacute; GooPay của bạn, h&atilde;y ngay lập tức đổi mật khẩu V&iacute; GooPay v&agrave; li&ecirc;n hệ tổng đ&agrave;i GooPay tại số 19006038 để tạm kh&oacute;a gấp t&agrave;i khoản. C&aacute;c bước tạm kh&oacute;a v&iacute; tự động xemtại đ&acirc;y</p>\n' +
				     '<p><strong>2. T&agrave;i khoản của t&ocirc;i bị kh&oacute;a</strong></p>\n' +
				     '<p>Khi đăng nhập v&agrave;o ứng dụng v&agrave; bạn nhận được th&ocirc;ng b&aacute;o t&agrave;i khoản bị kh&oacute;a, c&oacute; thể do 1 trong c&aacute;c nguy&ecirc;n nh&acirc;n sau:</p>\n' +
				     '<p>Bạn đ&atilde; nhập sai mật khẩu đăng nhập nhiều lần n&ecirc;n t&agrave;i khoản GooPay của bạn tạm thời bị kh&oacute;a.</p>\n' +
				     '<p>Bạn đ&atilde; vi phạm điều khoản sử dụng ứng dụng GooPay.</p>\n' +
				     '<p>Hệ thống GooPay ghi nhận h&agrave;nh vi bất thường n&ecirc;n tạm thời kh&oacute;a t&agrave;i khoản GooPay của bạn.</p>\n' +
				     '<p>Trong trường hợp n&agrave;y, bạn vui l&ograve;ng d&ugrave;ng ch&iacute;nh số điện thoại đăng k&yacute; t&agrave;i khoản GooPay để li&ecirc;n hệ đến tổng đ&agrave;i GooPay 19006038 . Nh&acirc;n vi&ecirc;n CSKH sẽ x&aacute;c minh th&ocirc;ng tin v&agrave; hỗ trợ.</p>\n' +
				     '<p><strong>3. C&aacute;c bước tạm kh&oacute;a t&agrave;i khoản tự động qua tổng đ&agrave;i</strong></p>\n' +
				     '<p>Trong trường hợp t&agrave;i khoản GooPay của bạn ph&aacute;t sinh giao dịch m&agrave; bạn kh&ocirc;ng thực hiện hoặc bạn đ&atilde; cung cấp th&ocirc;ng tin mật khẩu/ m&atilde; OTP cho người kh&aacute;c hoặc nghi ngờ c&oacute; người kh&aacute;c biết được th&ocirc;ng tin V&iacute; GooPay của bạn, bạn cần thực hiện kh&oacute;a v&iacute; ngay bằng c&aacute;ch:</p>\n' +
				     '<p>Bước 1: Li&ecirc;n hệ ngay lập tức đến số tổng đ&agrave;i 19006038</p>\n' +
				     '<p>Bước 2: Để b&aacute;o kh&oacute;a t&agrave;i khoản khẩn cấp, vui l&ograve;ng bấm ph&iacute;m 1.</p>\n' +
				     '<p>Bước 3:</p>\n' +
				     '<p>Để kh&oacute;a t&agrave;i khoản số điện thoại đang gọi: bấm ph&iacute;m 1</p>\n' +
				     '<p>Để kh&oacute;a t&agrave;i khoản của số điện thoại kh&aacute;c: bấm ph&iacute;m 2.</p>\n' +
				     '<p>Bước 4:</p>\n' +
				     '<p>Trường hợp kh&oacute;a t&agrave;i khoản số điện thoại đang gọi: Hệ thống sẽ đọc lại số điện thoại cần kh&oacute;a. Bạn vui l&ograve;ng bấm ph&iacute;m 1 sau tiếng b&iacute;p để x&aacute;c nhận lệnh kh&oacute;a.</p>\n' +
				     '<p>Trường hợp kh&oacute;a t&agrave;i khoản của số điện thoại kh&aacute;c: Hệ thống sẽ chuyển đến Điện thoại vi&ecirc;n để thực hiện kh&oacute;a V&iacute;.</p>\n' +
				     '<p>&diams; Kh&oacute;a số GooPay</p>\n' +
				     '<p>&diams; Mở kh&oacute;a số GooPay</p>\n' +
				     '<p><strong>4. T&ocirc;i bị mất điện thoại</strong></p>\n' +
				     '<p>Bạn c&oacute; thể gặp 1 trong 2 trường hợp:</p>\n' +
				     '<p>Trường hợp 1: Mất cả điện thoại k&egrave;m sim đăng k&yacute; t&agrave;i khoản v&iacute; GooPay</p>\n' +
				     '<p>Kẻ gian c&oacute; thể kh&ocirc;ng truy cập v&agrave;o t&agrave;i khoản GooPay tr&ecirc;n điện thoại của bạn nếu kh&ocirc;ng c&oacute; mật khẩu đăng nhập/c&aacute;c th&ocirc;ng tin x&aacute;c minh li&ecirc;n quan. Tuy nhi&ecirc;n để đảm bảo an to&agrave;n tuyệt đối, bạn cần gọi ngay Tổng đ&agrave;i nh&agrave; mạng để thực hiện kh&oacute;a 2 chiều nghe v&agrave; gọi của sim, đồng thời li&ecirc;n hệ Tổng đ&agrave;i GooPay 19006038 để được hỗ trợ kh&oacute;a t&agrave;i khoản GooPay.</p>\n' +
				     '<p>Trường hợp 2: Mất điện thoại nhưng vẫn giữ sim đăng k&yacute; t&agrave;i khoản GooPay</p>\n' +
				     '<p>H&atilde;y lắp sim v&agrave;o 1 thiết bị điện thoại kh&aacute;c v&agrave; đăng nhập ngay v&agrave;o t&agrave;i khoản GooPay của m&igrave;nh. Bạn sẽ được tổng đ&agrave;i tự động của GooPay li&ecirc;n hệ để cung cấp m&atilde; OTP. Đồng thời, t&agrave;i khoản GooPay của bạn tr&ecirc;n thiết bị cũ sẽ tự động đăng xuất. Kẻ gian sẽ kh&ocirc;ng thể đăng nhập được v&agrave;o t&agrave;i khoản V&iacute; GooPay của bạn tr&ecirc;n thiết bị cũ khi kh&ocirc;ng c&oacute; m&atilde; OTP cũng như mật khẩu của bạn</p>\n' +
				     '<br/><h2 style="font-size: 18px;font-weight:bold; text-align: center; color: #338FE3;">X&aacute;c thực t&agrave;i khoản&nbsp;</h2><br/>' +
				     '<p><strong>1. Tại sao t&ocirc;i cần x&aacute;c thực t&agrave;i khoản V&iacute; GooPay?</strong></p>\n' +
				     '<p>Theo quy định của Ng&acirc;n h&agrave;ng nh&agrave; nước tại Th&ocirc;ng tư 23/2019/TT-NHNN, người d&ugrave;ng sử dụng V&iacute; điện tử bắt buộc phải x&aacute;c thực t&agrave;i khoản V&iacute; v&agrave; li&ecirc;n kết với T&agrave;i khoản ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>V&iacute; GooPay của bạn cần thực hiện đ&uacute;ng theo quy định tr&ecirc;n để tiếp tục sử dụng dịch vụ.</p>\n' +
				     '<p>Ngo&agrave;i ra, GooPay khuyến kh&iacute;ch người d&ugrave;ng x&aacute;c thực khu&ocirc;n mặt. Điều n&agrave;y sẽ gi&uacute;p:</p>\n' +
				     '<p>☑️ Khẳng định người d&ugrave;ng l&agrave; chủ sở hữu của t&agrave;i khoản V&iacute; GooPay.</p>\n' +
				     '<p>☑️ Tăng cường bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n v&agrave; th&ocirc;ng tin giao dịch trong V&iacute;.</p>\n' +
				     '<p>☑️ Giảm thiểu gian lận, trộm cắp danh t&iacute;nh, hoạt động rửa tiền v&agrave; c&aacute;c hoạt động phạm ph&aacute;p kh&aacute;c.</p>\n' +
				     '<p>☑️. Nhận th&ecirc;m nhiều ưu đ&atilde;i từ GooPay.</p>\n' +
				     '<p><strong>2. Ai l&agrave; người phải x&aacute;c thực t&agrave;i khoản V&iacute; GooPay?</strong></p>\n' +
				     '<p>Theo quy định của Ng&acirc;n h&agrave;ng nh&agrave; nước tại Th&ocirc;ng tư 23/2019/TT-NHNN, c&aacute; nh&acirc;n sử dụng V&iacute; điện tử bắt buộc phải x&aacute;c thực t&agrave;i khoản V&iacute; v&agrave; li&ecirc;n kết với T&agrave;i khoản ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>V&iacute; GooPay của Kh&aacute;ch h&agrave;ng cần thực hiện đ&uacute;ng theo quy định tr&ecirc;n để tiếp tục sử dụng dịch vụ.</p>\n' +
				     '<p>Nếu t&agrave;i khoản V&iacute; GooPay của bạn chưa được x&aacute;c thực, V&iacute; GooPay của bạn sẽ bị tạm kh&oacute;a dịch vụ cho đến khi việc x&aacute;c thực ho&agrave;n tất.</p>\n' +
				     '<p><strong>3. X&aacute;c thực t&agrave;i khoản v&iacute; c&oacute; lợi &iacute;ch g&igrave;?</strong></p>\n' +
				     '<p>X&aacute;c thực th&ocirc;ng tin người d&ugrave;ng (KYC - Know Your Customer) V&iacute; điện tử l&agrave; quy định của Ng&acirc;n h&agrave;ng Nh&agrave; nước với mục đ&iacute;ch tăng cường bảo mật t&agrave;i ch&iacute;nh v&agrave; x&acirc;y dựng một nền tảng thanh to&aacute;n giảm thiểu gian lận, trộm cắp danh t&iacute;nh, hoạt động &ldquo;rửa tiền&rdquo; v&agrave; c&aacute;c h&agrave;nh động phạm ph&aacute;p kh&aacute;c.</p>\n' +
				     '<p>GooPay khuyến kh&iacute;ch người d&ugrave;ng x&aacute;c thực t&agrave;i khoản v&agrave; x&aacute;c thực khu&ocirc;n mặt. Điều n&agrave;y sẽ gi&uacute;p:</p>\n' +
				     '<p>☑️ Khẳng định người d&ugrave;ng l&agrave; chủ sở hữu của t&agrave;i khoản V&iacute; GooPay.</p>\n' +
				     '<p>☑️. Tăng cường bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n v&agrave; th&ocirc;ng tin giao dịch trong V&iacute;.</p>\n' +
				     '<p>☑️ Giảm thiểu gian lận, trộm cắp danh t&iacute;nh, hoạt động rửa tiền v&agrave; c&aacute;c hoạt động phạm ph&aacute;p kh&aacute;c.</p>\n' +
				     '<p>☑️. Nhận th&ecirc;m nhiều ưu đ&atilde;i từ GooPay.</p>\n' +
				     '<p>Bạn c&oacute; thể xem chi tiết nội dung Th&ocirc;ng tư 23 tại đ&acirc;y <a href="http://congbao.chinhphu.vn/noi-dung-van-ban-so-23-2019-tt-nhnn-30024">http://congbao.chinhphu.vn/noi-dung-van-ban-so-23-2019-tt-nhnn-30024</a></p>\n' +
				     '<p><strong>4. T&ocirc;i muốn thay đổi th&ocirc;ng tin x&aacute;c thực t&agrave;i khoản</strong></p>\n' +
				     '<p>Để được hỗ trợ thay đổi th&ocirc;ng tin t&agrave;i khoản V&iacute; đ&atilde; được x&aacute;c thực, như:</p>\n' +
				     '<p>Thay đổi loại giấy tờ t&ugrave;y th&acirc;n (CMND/CCCD/Hộ chiếu)</p>\n' +
				     '<p>Thay đổi th&ocirc;ng tin giấy tờ t&ugrave;y th&acirc;n đ&atilde; được x&aacute;c thực</p>\n' +
				     '<p>Bạn vui l&ograve;ng gửi y&ecirc;u cầu ở mục Trợ gi&uacute;p tr&ecirc;n v&iacute; GooPay k&egrave;m h&igrave;nh ảnh CMND/CCCD/Hộ chiếu (theo th&ocirc;ng tin mong muốn được cập nhật) để GooPay c&oacute; thể hỗ trợ bạn.</p>\n' +
				     '<p>Lưu &yacute;:</p>\n' +
				     '<p>- Nếu hệ thống đang chờ x&aacute;c thực bạn vui l&ograve;ng chờ đến khi hệ thống b&aacute;o đ&atilde; x&aacute;c thực xong.</p>\n' +
				     '<p>- Nếu hệ thống từ chối x&aacute;c thực bạn h&atilde;y l&agrave;m lại bước x&aacute;c thực với th&ocirc;ng tin ch&iacute;nh x&aacute;c.</p>\n' +
				     '<p><strong>5. Tại sao kh&ocirc;ng thể thực hiện giao dịch khi đ&atilde; li&ecirc;n kết ng&acirc;n h&agrave;ng th&agrave;nh c&ocirc;ng?</strong></p>\n' +
				     '<p>Để sử dụng được dịch vụ, b&ecirc;n cạnh việc li&ecirc;n kết ng&acirc;n h&agrave;ng, bạn cần phải thực hiện x&aacute;c thực t&agrave;i khoản theo quy định của Ng&acirc;n h&agrave;ng Nh&agrave; nước. Để thao t&aacute;c x&aacute;c thực t&agrave;i khoản, bạn h&atilde;y thực hiện theo c&aacute;c bước hướng dẫn sau:</p>\n' +
				     '<p>Đăng nhập v&agrave;o ứng dụng:</p>\n' +
				     '<p>B1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, bạn c&oacute; thể chọn 1 trong 3 c&aacute;ch sau để cập nhật th&ocirc;ng tin chủ V&iacute; như sau:</p>\n' +
				     '<p>C&aacute;ch 1: Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn biểu tượng h&igrave;nh đại diện ở g&oacute;c tr&ecirc;n b&ecirc;n phải để v&agrave;o ""Trang c&aacute; nh&acirc;n của t&ocirc;i"" &rarr; chọn ""Chụp CMND ngay!""</p>\n' +
				     '<p>C&aacute;ch 2: Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn &ldquo;V&iacute; của t&ocirc;i&rdquo; &rarr; chọn ""Chụp CMND ngay!""</p>\n' +
				     '<p>C&aacute;ch 3:Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn &ldquo;V&iacute; của t&ocirc;i&rdquo; &rarr; chọn mục &ldquo;Trung t&acirc;m bảo mật&rdquo; &rarr; chọn ""X&aacute;c thực ngay"" trong phần ""Th&ocirc;ng tin x&aacute;c thực t&agrave;i khoản""</p>\n' +
				     '<p>B2: Chụp ảnh CMND/CCCD mặt trước- mặt sau hoặc Hộ chiếu - trang 2 v&agrave; 3</p>\n' +
				     '<p>B3: Kiểm tra th&ocirc;ng tin.</p>\n' +
				     '<p>C&aacute;c th&ocirc;ng tin từ CMND/ CCCD/ Hộ chiếu sẽ được tự động điền v&agrave;o c&aacute;c &ocirc; th&ocirc;ng tin, bạn vui l&ograve;ng kiểm tra ch&iacute;nh x&aacute;c th&ocirc;ng tin v&agrave; nhấn ""X&aacute;c thực"" để tiếp tục.</p>\n' +
				     '<p>Lưu &yacute;: Theo Th&ocirc;ng tư 23/2019/TT-NHNN, th&ocirc;ng tin chủ V&iacute; GooPay bắt buộc tr&ugrave;ng khớp th&ocirc;ng tin li&ecirc;n kết ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>B4: Thực hiện x&aacute;c thực khu&ocirc;n mặt</p>\n' +
				     '<p>Giữ điện thoại cố định, đưa khu&ocirc;n mặt của bạn v&agrave;o khung h&igrave;nh tr&ograve;n v&agrave; sau đ&oacute; di chuyển khu&ocirc;n mặt từ từ theo hướng dẫn. Sau khi ho&agrave;n th&agrave;nh bạn h&atilde;y trở lại m&agrave;n h&igrave;nh ch&iacute;nh để chờ kết quả nh&eacute;.</p>\n' +
				     '<p>GooPay khuyến kh&iacute;ch bạn tiếp tục ho&agrave;n th&agrave;nh bước Bảo mật tối đa bằng 1 trong 2 c&aacute;ch sau:</p>\n' +
				     '<p>Tại mục ""Trung t&acirc;m bảo mật"" phần &ldquo;Thiết lập bảo mật tối đa&rdquo;, bạn c&oacute; thể chọn bổ sung th&ecirc;m h&igrave;nh thức tăng cường bảo mật cho V&iacute; như sau:</p>\n' +
				     '<p>Chọn &ldquo;T&agrave;i khoản tin tưởng&rdquo;hoặc Chọn &ldquo;C&acirc;u hỏi bảo mật&rdquo;</p>\n' +
				     '<p>Để ho&agrave;n tất thiết lập bạn nhấn tiếp mục ""Thiết lập ngay&rdquo;.</p>\n' +
				     '<p><strong>6. Hộ chiếu nước ngo&agrave;i c&oacute; được x&aacute;c thực kh&ocirc;ng?</strong></p>\n' +
				     '<p>GooPay hiện vẫn sử dụng hộ chiếu nước ngo&agrave;i để x&aacute;c thực t&agrave;i khoản cho V&iacute; tr&ecirc;n ứng dụng. Trong trường hợp nhận được th&ocirc;ng b&aacute;o kh&ocirc;ng th&agrave;nh c&ocirc;ng khi chụp hộ chiếu, bạn vui l&ograve;ng thực hiện lại th&ecirc;m hai hoặc ba lần nữa để x&aacute;c thực lại.</p>\n' +
				     '<p>Xin lưu &yacute;: H&igrave;nh chụp cần được căn chỉnh r&otilde; r&agrave;ng, đầy đủ th&ocirc;ng tin, kh&ocirc;ng mất g&oacute;c hoặc tr&aacute;nh kh&ocirc;ng bị ch&oacute;i s&aacute;ng mất n&eacute;t.</p>\n' +
				     '<p>Trong trường vẫn kh&ocirc;ng thể x&aacute;c thực được, bạn vui l&ograve;ng gửi y&ecirc;u cầu hỗ trợ hoặc li&ecirc;n hệ tổng đ&agrave;i 19006038 để GooPay c&oacute; thể hỗ trợ bạn kịp thời.</p>\n' +
				     '<p><strong>7. T&ocirc;i đ&atilde; v&agrave; đang sử dụng v&iacute; GooPay trước khi th&ocirc;ng tư được ban h&agrave;nh, vậy t&ocirc;i c&oacute; cần phải x&aacute;c thực t&agrave;i khoản?</strong></p>\n' +
				     '<p>Theo quy định của Ng&acirc;n h&agrave;ng nh&agrave; nước tại Th&ocirc;ng tư 23/2019/TT-NHNN, tất cả người d&ugrave;ng sử dụng V&iacute; điện tử bắt buộc phải x&aacute;c thực t&agrave;i khoản V&iacute; v&agrave; li&ecirc;n kết với T&agrave;i khoản ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>Để được tiếp tục sử dụng dịch vụ, rất mong bạn ho&agrave;n tất c&aacute;c thao t&aacute;c x&aacute;c thực như quy định.</p>\n' +
				     '<p><strong>8. Th&ocirc;ng tin t&ocirc;i cung cấp khi x&aacute;c thực được bảo vệ như thế n&agrave;o?</strong></p>\n' +
				     '<p>GooPay cam kết bảo mật tuyệt đối th&ocirc;ng tin v&agrave; h&igrave;nh ảnh m&agrave; bạn đ&atilde; cung cấp. Th&ocirc;ng tin chỉ được sử dụng để x&aacute;c thực t&agrave;i khoản theo quy định của Ng&acirc;n h&agrave;ng Nh&agrave; nước.</p>\n' +
				     '<p>Bạn c&oacute; thể tham khảo th&ecirc;m Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư của V&iacute; GooPay tại đ&acirc;y: /chinh-sach-quyen-rieng-tu</p>\n' +
				     '<p>(*) Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư của V&iacute; GooPay tu&acirc;n thủ đ&uacute;ng quy định của ph&aacute;p luật về bảo vệ dữ liệu. Ch&iacute;nh s&aacute;ch quyền ri&ecirc;ng tư của V&iacute; GooPay đảm bảo sử dụng th&ocirc;ng tin thu thập được để cung cấp dịch vụ, t&iacute;nh năng, chương tr&igrave;nh khuyến mại; tăng cường bảo mật; hỗ trợ khi chủ V&iacute; y&ecirc;u cầu v&agrave; phục vụ c&aacute;c mục đ&iacute;ch li&ecirc;n quan đến thủ tục ph&aacute;p l&yacute;.</p>\n' +
				     '<p><strong>9. Nếu t&ocirc;i kh&ocirc;ng muốn x&aacute;c thực t&agrave;i khoản V&iacute; GooPay của m&igrave;nh, tiền trong V&iacute; của t&ocirc;i c&oacute; mất kh&ocirc;ng?</strong></p>\n' +
				     '<p>Tiền của bạn vẫn được đảm bảo duy tr&igrave; trong trong t&agrave;i khoản V&iacute; GooPay.</p>\n' +
				     '<p>Tuy nhi&ecirc;n, bạn cần phải thực hiện x&aacute;c thực t&agrave;i khoản để tiếp tục sử dụng phần tiền n&agrave;y. Trường hợp bạn cần tư vấn th&ecirc;m th&ocirc;ng tin chi tiết hoặc hỗ trợ th&ecirc;m xin vui l&ograve;ng gửi ngay y&ecirc;u cầu đến GooPay nh&eacute;.</p>\n' +
				     '<p><strong>10. Nếu t&ocirc;i đ&atilde; x&aacute;c thực t&agrave;i khoản nhưng chưa li&ecirc;n kết Ng&acirc;n H&agrave;ng th&igrave; c&oacute; thể thực hiện giao dịch kh&ocirc;ng?</strong></p>\n' +
				     '<p>Nếu V&iacute; GooPay của bạn chỉ mới x&aacute;c thực t&agrave;i khoản nhưng chưa thực hiện việc li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng, th&igrave; rất tiếc bạn vẫn chưa đủ điều kiện sử dụng dịch vụ. Nhằm gi&uacute;p t&agrave;i khoản V&iacute; c&oacute; thể sử dụng được to&agrave;n bộ c&aacute;c t&iacute;nh năng tr&ecirc;n ứng dụng, bạn cần thực hiện theo hướng dẫn li&ecirc;n kết V&iacute; GooPay với t&agrave;i khoản ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>Tham khảo tại đ&acirc;y /huong-dan/lien-ket-ngan-hang-ctgr67</p>\n' +
				     '<p>Lưu &yacute;: Th&ocirc;ng tin chủ V&iacute; v&agrave; th&ocirc;ng tin li&ecirc;n kết t&agrave;i khoản ng&acirc;n h&agrave;ng/chủ thẻ bắt buộc phải tr&ugrave;ng khớp. Trường hợp bạn cần tư vấn th&ecirc;m th&ocirc;ng tin chi tiết hoặc đ&atilde; thực hiện li&ecirc;n kết nhưng vẫn chưa sử dụng được dịch vụ, h&atilde;y gửi ngay y&ecirc;u cầu đến GooPay nh&eacute;.</p>\n' +
				     '<p><strong>11. Thời gian để GooPay x&aacute;c thực t&agrave;i khoản l&agrave; bao l&acirc;u?</strong></p>\n' +
				     '<p>Kể từ l&uacute;c bạn ho&agrave;n th&agrave;nh việc x&aacute;c nhận c&aacute;c th&ocirc;ng tin tại bước X&aacute;c thực t&agrave;i khoản, V&iacute; GooPay của bạn sẽ được x&aacute;c thực sau chậm nhất 24h (Kh&ocirc;ng t&iacute;nh Thứ 7, Chủ nhật v&agrave; ng&agrave;y Lễ).</p>\n' +
				     '<p>Nếu qu&aacute; thời gian tr&ecirc;n m&agrave; V&iacute; của bạn chưa được x&aacute;c thực, vui l&ograve;ng gửi y&ecirc;u cầu hỗ trợ để b&aacute;o cho GooPay biết về t&igrave;nh huống n&agrave;y. GooPay rất tiếc về những bất tiện m&agrave; bạn gặp phải.</p>\n' +
				     '<p><strong>12. Những loại giấy tờ cần d&ugrave;ng để x&aacute;c thực t&agrave;i khoản l&agrave; g&igrave;?</strong></p>\n' +
				     '<p>Người d&ugrave;ng GooPay c&oacute; thể t&ugrave;y chọn một trong c&aacute;c loại giấy tờ sau để x&aacute;c thực t&agrave;i khoản V&iacute; điện tử:</p>\n' +
				     '<p>Chứng minh nh&acirc;n d&acirc;n</p>\n' +
				     '<p>Căn cước c&ocirc;ng d&acirc;n</p>\n' +
				     '<p>Hộ chiếu</p>\n' +
				     '<p>Lưu &yacute;:</p>\n' +
				     '<p>- Th&ocirc;ng tin tr&ecirc;n giấy tờ c&ograve;n hiệu lực sử dụng v&agrave; tr&ugrave;ng khớp với th&ocirc;ng tin bạn đ&atilde; đăng k&yacute; với GooPay v&agrave; ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>- Mỗi 1 CMND/CCCD/Hộ chiếu chỉ được x&aacute;c thực tối đa 3 V&iacute;.</p>\n' +
				     '<p>- Nếu bạn đ&atilde; gửi th&ocirc;ng tin h&igrave;nh ảnh CMND/CCCD/Hộ chiếu m&agrave; hệ thống b&aacute;o lỗi, bạn vui l&ograve;ng thực hiện lại.</p>\n' +
				     '<p>- Nếu th&ocirc;ng tin x&aacute;c thực bị từ chối bạn c&oacute; thể thực hiện lại việc x&aacute;c thực cho đến khi nhận được th&ocirc;ng b&aacute;o x&aacute;c thực th&agrave;nh c&ocirc;ng hoặc gửi y&ecirc;u cầu trợ gi&uacute;p để GooPay kịp thời hỗ trợ bạn nh&eacute;.</p>\n' +
				     '<p><strong>13. Điều g&igrave; sẽ xảy ra nếu t&ocirc;i kh&ocirc;ng x&aacute;c thực t&agrave;i khoản V&iacute; GooPay của m&igrave;nh?</strong></p>\n' +
				     '<p>Theo quy định của Ng&acirc;n h&agrave;ng nh&agrave; nước tại Th&ocirc;ng tư 23/2019/TT-NHNN, c&aacute; nh&acirc;n sử dụng V&iacute; điện tử bắt buộc phải x&aacute;c thực t&agrave;i khoản V&iacute; v&agrave; li&ecirc;n kết với T&agrave;i khoản ng&acirc;n h&agrave;ng. V&iacute; GooPay của Kh&aacute;ch h&agrave;ng cần thực hiện đ&uacute;ng theo quy định tr&ecirc;n để tiếp tục sử dụng dịch vụ.</p>\n' +
				     '<p>Nếu t&agrave;i khoản V&iacute; GooPay của bạn chưa được x&aacute;c thực, V&iacute; GooPay của bạn sẽ bị tạm kh&oacute;a dịch vụ cho đến khi việc x&aacute;c thực ho&agrave;n tất.</p>\n' +
				     '<p><strong>14.&nbsp;Một&nbsp;CMND/CCCD c&oacute; thể x&aacute;c thực được bao nhi&ecirc;u v&iacute;?</strong></p>\n' +
				     '<p>Bạn c&oacute; thể sử dụng mỗi CMND/CCCD/Hộ chiếu để x&aacute;c thực tối đa 3 V&iacute; GooPay.</p>\n' +
				     '<p>Trong trường hợp CMND/CCCD n&agrave;y đ&atilde; x&aacute;c thực tối đa 3 v&iacute; v&agrave; bạn muốn d&ugrave;ng CMND/ CCCD n&agrave;y để x&aacute;c thực một v&iacute; kh&aacute;c, bạn vui l&ograve;ng gửi y&ecirc;u cầu hủy x&aacute;c thực bằng số v&iacute; bạn muốn hủy để GooPay c&oacute; thể hỗ trợ bạn kịp thời</p>\n' +
				     '<p><strong>15. T&ocirc;i chụp CMND/CCCD để x&aacute;c thực t&agrave;i khoản nhưng bị lỗi h&igrave;nh ảnh</strong></p>\n' +
				     '<p>Trường hợp n&agrave;y c&oacute; thể do c&aacute;c lỗi sau:</p>\n' +
				     '<p>H&igrave;nh chụp bị mờ, ch&oacute;i s&aacute;ng hoặc qu&aacute; tối: Bạn vui l&ograve;ng thực hiện x&aacute;c thực lại. Bạn cần canh chỉnh h&igrave;nh chụp CMND/ CCCD/ Hộ chiếu r&otilde; r&agrave;ng v&agrave; đầy đủ th&ocirc;ng tin</p>\n' +
				     '<p>CMND/ CCCD/ Hộ chiếu bạn d&ugrave;ng kh&ocirc;ng phải giấy tờ gốc: Bạn vui l&ograve;ng thực hiện x&aacute;c thực lại v&agrave; kh&ocirc;ng d&ugrave;ng CMND/ CCCD/ Hộ chiếu bản photocopy/ photoshop/bản chụp qua c&aacute;c m&agrave;n h&igrave;nh thiết bị điện tử kh&aacute;c.</p>\n' +
				     '<p>CMND/ CCCD/ Hộ chiếu bị b&ocirc;i bẩn, nh&agrave;u n&aacute;t, gấp g&atilde;y.</p>\n' +
				     '<p>CMND/ CCCD/ Hộ chiếu của bạn đ&atilde; được x&aacute;c thực đủ 3 v&iacute;: Bạn vui l&ograve;ng gọi tới GooPay qua hotline 19006038 hoặc gửi y&ecirc;u cầu ở mục Trợ gi&uacute;p tr&ecirc;n v&iacute; GooPay để được hỗ trợ hủy x&aacute;c thực v&iacute; cũ v&agrave; tiến h&agrave;nh x&aacute;c thực lại tr&ecirc;n v&iacute; mới.</p>\n' +
				     '<p>Trong trường hợp đ&atilde; thử x&aacute;c thực lại vẫn kh&ocirc;ng được bạn h&atilde;y thử lại 1 lần nữa. GooPay ch&acirc;n th&agrave;nh xin lỗi mong bạn th&ocirc;ng cảm v&igrave; sự bất tiện n&agrave;y.</p>\n' +
				     '<p><strong>16. T&ocirc;i chụp CMND để x&aacute;c thực t&agrave;i khoản nhưng dịch vụ đang bảo tr&igrave;</strong></p>\n' +
				     '<p>Trường hợp n&agrave;y c&oacute; thể do một v&agrave;i vấn đề xảy ra trong qu&aacute; tr&igrave;nh xử l&yacute; h&igrave;nh ảnh. Bạn vui l&ograve;ng tho&aacute;t ra khỏi t&iacute;nh năng v&agrave; v&agrave;o thực hiện lại bước X&aacute;c thực t&agrave;i khoản sau 5 ph&uacute;t nữa.&nbsp;</p>\n' +
				     '<p>GooPay ch&acirc;n th&agrave;nh xin lỗi mong bạn th&ocirc;ng cảm v&igrave; sự bất tiện n&agrave;y.</p>\n' +
				     '<p><strong>17. T&ocirc;i cần thực hiện những bước n&agrave;o để x&aacute;c thực t&agrave;i khoản V&iacute; GooPay?</strong></p>\n' +
				     '<p>Để thao t&aacute;c x&aacute;c thực t&agrave;i khoản, bạn h&atilde;y thực hiện theo c&aacute;c bước hướng dẫn sau:</p>\n' +
				     '<p>Đăng nhập v&agrave;o ứng dụng</p>\n' +
				     '<p>B1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn biểu tượng h&igrave;nh đại diện &ldquo;T&ocirc;i&rdquo; ở g&oacute;c b&ecirc;n phải&nbsp;</p>\n' +
				     '<p>B2: Chọn Chụp CMND/CCCD ở phần tr&ecirc;n m&agrave;n h&igrave;nh</p>\n' +
				     '<p>B3: X&aacute;c thực chứng từ&nbsp;</p>\n' +
				     '<p>3.1 Chọn Bắt đầu chụp để chụp ảnh CMND/CCCD mặt trước v&agrave; mặt sau hoặc Hộ chiếu</p>\n' +
				     '<p>3.2 Kiểm tra th&ocirc;ng tin&nbsp;</p>\n' +
				     '<p>C&aacute;c th&ocirc;ng tin từ CMND/ CCCD/ Hộ chiếu sẽ được tự động điền v&agrave;o c&aacute;c &ocirc; th&ocirc;ng tin, bạn vui l&ograve;ng kiểm tra ch&iacute;nh x&aacute;c th&ocirc;ng tin v&agrave; nhấn ""X&aacute;c nhận"" để tiếp tục.</p>\n' +
				     '<p>Lưu &yacute;: Theo Th&ocirc;ng tư 23/2019/TT-NHNN, th&ocirc;ng tin chủ V&iacute; GooPay bắt buộc tr&ugrave;ng khớp th&ocirc;ng tin li&ecirc;n kết ng&acirc;n h&agrave;ng. Do đ&oacute;, nếu bạn chưa cung cấp th&ocirc;ng tin ng&acirc;n h&agrave;ng, h&atilde;y li&ecirc;n kết ng&acirc;n h&agrave;ng ngay để c&oacute; thể tiếp tục sử dụng c&aacute;c dịch vụ của V&iacute; GooPay nh&eacute;! Tham khảo th&ecirc;m tại đ&acirc;y: /hoi-dap/nap-rut-tien-ctgr3/lien-ket-tai-khoan-ngan-hang</p>\n' +
				     '<p>B4: X&aacute;c thực khu&ocirc;n mặt</p>\n' +
				     '<p>Giữ điện thoại cố định</p>\n' +
				     '<p>Đưa khu&ocirc;n mặt của bạn v&agrave;o khung h&igrave;nh tr&ograve;n&nbsp;</p>\n' +
				     '<p>Di chuyển khu&ocirc;n mặt từ từ theo hướng dẫn</p>\n' +
				     '<p>Sau khi ho&agrave;n th&agrave;nh bạn h&atilde;y trở lại m&agrave;n h&igrave;nh ch&iacute;nh để chờ kết quả nh&eacute;</p>\n' +
				     '<p><strong>18. X&aacute;c thực t&agrave;i khoản v&iacute; điện tử l&agrave; g&igrave;?</strong></p>\n' +
				     '<p>X&aacute;c thực th&ocirc;ng tin người d&ugrave;ng (KYC - Know Your Customer) V&iacute; điện tử l&agrave; quy định của Ng&acirc;n h&agrave;ng Nh&agrave; nước với mục đ&iacute;ch tăng cường bảo mật t&agrave;i ch&iacute;nh v&agrave; x&acirc;y dựng một nền tảng thanh to&aacute;n giảm thiểu gian lận, trộm cắp danh t&iacute;nh, hoạt động &ldquo;rửa tiền&rdquo; v&agrave; c&aacute;c h&agrave;nh động phạm ph&aacute;p kh&aacute;c. Xem chi tiết nội dung Th&ocirc;ng tư 23 <a href="http://bit.ly/TT23-NHNN">http://bit.ly/TT23-NHNN</a></p>\n' +
				     '<p><strong>19. Tại sao t&ocirc;i đ&atilde; li&ecirc;n kết V&iacute; GooPay với t&agrave;i khoản ng&acirc;n h&agrave;ng (đ&atilde; được x&aacute;c thực bằng CMND) m&agrave; vẫn phải cung cấp CMND để x&aacute;c thực t&agrave;i khoản tr&ecirc;n V&iacute; GooPay?</strong></p>\n' +
				     '<p>Theo Th&ocirc;ng tư 23/2019/TT-NHNN, th&ocirc;ng tin x&aacute;c thực v&iacute; cần đảm bảo:</p>\n' +
				     '<p>Chủ V&iacute; GooPay đ&atilde; cung cấp đầy đủ h&igrave;nh chụp CMND/ CCCD/ Hộ chiếu.</p>\n' +
				     '<p>Th&ocirc;ng tin chủ V&iacute; GooPay bắt buộc tr&ugrave;ng khớp th&ocirc;ng tin li&ecirc;n kết ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>Bạn c&oacute; thể tham khảo c&aacute;ch x&aacute;c thực t&agrave;i khoản tại đ&acirc;y:</p>\n' +
				     '<p>Trong trường hợp một trong c&aacute;c th&ocirc;ng tin tr&ecirc;n chưa ch&iacute;nh x&aacute;c, bạn c&oacute; thể nhận được y&ecirc;u cầu x&aacute;c thực lại. Xin vui l&ograve;ng thực hiện lại x&aacute;c thực, để đảm bảo đ&uacute;ng v&agrave; đủ c&aacute;c điều kiện tr&ecirc;n. GooPay xin lỗi v&igrave; sự bất tiện n&agrave;y.</p>'
		     }}
		/>
	</div>
);

export default FAQSafetySecure;
