// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {Link} from "react-router-dom";
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const PolicyUsage = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='Điều khoản và Chính sách
				Đăng Ký Sử Dụng Ví GooPay'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>Điều khoản và Chính sách
				Đăng Ký Sử Dụng Ví GooPay</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<p>Người Sử Dụng cần đọc v&agrave; đồng &yacute; với những Điều Khoản v&agrave; Điều Kiện n&agrave;y trước khi sử dụng Sản Phẩm/Dịch Vụ.</p>\n' +
				     '<p>C&Aacute;C ĐIỀU KHOẢN V&Agrave; ĐIỀU KIỆN VỀ DỊCH VỤ (sau đ&acirc;y gọi tắt l&agrave; &ldquo;Điều Khoản Chung&rdquo;) điều chỉnh c&aacute;c quyền v&agrave; nghĩa vụ của Người Sử Dụng, với tư c&aacute;ch l&agrave; kh&aacute;ch h&agrave;ng, khi sử dụng Sản Phẩm/Dịch Vụ do C&Ocirc;NG TY CỔ PHẦN THANH TO&Aacute;N TRỰC TUYẾN TO&Agrave;N CẦU cung cấp tr&ecirc;n V&iacute; Điện Tử GooPay.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">1. Định nghĩa</h1>\n' +
				     '<p>Trong Điều Khoản Chung n&agrave;y, c&aacute;c từ v&agrave; thuật ngữ sau đ&acirc;y sẽ c&oacute; nghĩa dưới đ&acirc;y trừ khi ngữ cảnh c&oacute; y&ecirc;u cầu kh&aacute;c:</p>\n' +
				     '<p>1.1 V&iacute; GooPay: l&agrave; C&ocirc;ng Ty Cổ Phần Thanh To&aacute;n Trực Tuyến To&agrave;n Cầu, một c&ocirc;ng ty được th&agrave;nh lập hợp lệ v&agrave; hoạt động theo ph&aacute;p luật của nước Cộng H&ograve;a X&atilde; Hội Chủ Nghĩa Việt Nam, c&oacute; Giấy Chứng Nhận Đăng K&yacute; Doanh Nghiệp Số 0107768570 cấp lần đầu ng&agrave;y 20/03/2017.</p>\n' +
				     '<p>1.2 Ứng Dụng GooPay: l&agrave; Ứng dụng tr&ecirc;n nền tảng di động do V&iacute; GooPay ph&aacute;t triển v&agrave; vận h&agrave;nh để cung cấp Dịch vụ V&iacute; điện tử v&agrave; c&aacute;c dịch vụ trung gian thanh to&aacute;n kh&aacute;c được Ng&acirc;n h&agrave;ng Nh&agrave; nước Việt Nam cấp ph&eacute;p triển khai dưới thương hiệu GooPay.</p>\n' +
				     '<p>1.3 T&agrave;i Khoản GooPay: l&agrave; t&agrave;i khoản điện tử tr&ecirc;n hệ thống c&ocirc;ng nghệ th&ocirc;ng tin của V&iacute; GooPay do Người Sử Dụng tạo lập v&agrave; quản l&yacute; th&ocirc;ng qua nhiều h&igrave;nh thức kh&aacute;c nhau, bao gồm nhưng kh&ocirc;ng giới hạn bởi ứng dụng tr&ecirc;n điện thoại di động, website, SIMCARD v&agrave; c&aacute;c h&igrave;nh thức kh&aacute;c để truy cập, sử dụng Sản Phẩm/Dịch Vụ, bao gồm cả Dịch vụ V&iacute; điện tử v&agrave; c&aacute;c dịch vụ trung gian thanh to&aacute;n kh&aacute;c do V&iacute; GooPay cung cấp.</p>\n' +
				     '<p>1.4 Người Sử Dụng: l&agrave; c&aacute;c kh&aacute;ch h&agrave;ng c&oacute; nhu cầu mở v&agrave; sử dụng Sản Phẩm/Dịch Vụ của V&iacute; GooPay.</p>\n' +
				     '<p>1.5 Sản Phẩm/Dịch Vụ: bao gồm dịch vụ được thực hiện Tại Điểm Giao Dịch v&agrave; c&aacute;c dịch vụ tr&ecirc;n Ứng Dụng GooPay.</p>\n' +
				     '<p>1.6 Giao Dịch: l&agrave; bất kỳ giao dịch n&agrave;o của Người Sử Dụng li&ecirc;n quan đến việc sử dụng Sản Phẩm/Dịch Vụ được cung cấp bởi V&iacute; GooPay.</p>\n' +
				     '<p>1.7 Điểm Giao Dịch: l&agrave; c&aacute;c địa điểm đối t&aacute;c được V&iacute; GooPay ủy quyền cung cấp c&aacute;c dịch vụ Nạp/R&uacute;t, thanh to&aacute;n tiền điện, nước, khoản vay để Người Sử Dụng sử dụng; Danh s&aacute;ch Điểm Giao Dịch được liệt k&ecirc; tại đ&acirc;y.</p>\n' +
				     '<p>1.8 C&aacute;c Giới Hạn Giao Dịch: nghĩa l&agrave; c&aacute;c giới hạn sau đ&acirc;y:</p>\n' +
				     '<p>a. Người Sử Dụng l&agrave; c&aacute; nh&acirc;n sử dụng Tài khoản GooPay bị giới hạn s&ocirc;́ dư t&ocirc;́i đa của T&agrave;i Khoản GooPay là 200.000.000 đ&ocirc;̀ng tại mọi thời điểm v&agrave; tổng hạn mức giao dịch (bao gồm giao dịch thanh to&aacute;n cho c&aacute;c h&agrave;ng h&oacute;a, dịch vụ hợp ph&aacute;p v&agrave; giao dịch chuyển tiền (giữa c&aacute;c T&agrave;i khoản GooPay với nhau)) kh&ocirc;ng qu&aacute; 100.000.000 đồng/th&aacute;ng. Hạn mức n&agrave;y kh&ocirc;ng &aacute;p dụng đối với Người Sử Dụng l&agrave; tổ chức hoặc người k&yacute; hợp đồng l&agrave;m Đơn vị chấp nhận thanh to&aacute;n của V&iacute; GooPay v&agrave; c&oacute; thể được điều chỉnh, thay đổi theo Quy Định Ph&aacute;p Luật v&agrave; ch&iacute;nh s&aacute;ch của V&iacute; GooPay tại từng thời điểm;</p>\n' +
				     '<p>b. Người Sử Dụng sử dụng Dịch Vụ chuyển tiền GooPay tr&ecirc;n di động bị giới hạn đối với c&aacute;c giao dịch l&ecirc;n tới kh&ocirc;ng qu&aacute; 20.000.000 đồng mỗi Người Sử Dụng một ng&agrave;y.</p>\n' +
				     '<p>1.9 Biện Ph&aacute;p X&aacute;c Thực: l&agrave; c&aacute;c yếu tố x&aacute;c thực m&agrave; V&iacute; GooPay sử dụng để x&aacute;c thực định danh Người Sử Dụng bao gồm nhưng kh&ocirc;ng giới hạn mật khẩu sử dụng một lần (One-Time Password), mật khẩu, đặc điểm sinh trắc học v&agrave; c&aacute;c biện ph&aacute;p x&aacute;c thực kh&aacute;c được ph&eacute;p thực hiện theo Quy Định Ph&aacute;p Luật.</p>\n' +
				     '<p>1.10 Dịch Vụ Kh&aacute;ch H&agrave;ng: nghĩa l&agrave; dịch vụ chăm s&oacute;c kh&aacute;ch h&agrave;ng của V&iacute; GooPay, được cung cấp theo số điện thoại 19006038.</p>\n' +
				     '<p>1.11 Hồ Sơ Mở T&agrave;i Khoản GooPay: l&agrave; c&aacute;c giấy tờ, th&ocirc;ng tin của c&aacute; nh&acirc;n phải cung cấp theo quy định của ph&aacute;p luật v&agrave; y&ecirc;u cầu của V&iacute; GooPay, bao gồm nhưng kh&ocirc;ng giới hạn:</p>\n' +
				     '<p>a. Đối với c&aacute; nh&acirc;n: họ v&agrave; t&ecirc;n; ng&agrave;y, th&aacute;ng, năm sinh; quốc tịch; số điện thoại; căn cước c&ocirc;ng d&acirc;n c&ograve;n thời hạn;</p>\n' +
				     '<p>b. Đối với tổ chức: t&ecirc;n giao dịch viết tắt v&agrave; đầy đủ; m&atilde; số doanh nghiệp; m&atilde; số thuế; trụ sở ch&iacute;nh; địa chỉ giao dịch; số điện thoại; người đại diện hợp ph&aacute;p;</p>\n' +
				     '<p>c. Hồ Sơ Mở T&agrave;i Khoản GooPay sẽ được V&iacute; GooPay cập nhật theo quy định của ph&aacute;p luật v&agrave; y&ecirc;u cầu ph&ugrave; hợp của V&iacute; GooPay theo từng thời điểm v&agrave; được đăng tải tr&ecirc;n Ứng Dụng GooPay.</p>\n' +
				     '<p>1.12 Quy Định Ph&aacute;p Luật: bao gồm to&agrave;n bộ c&aacute;c quy định ph&aacute;p luật của nước Cộng H&ograve;a X&atilde; Hội Chủ Nghĩa Việt Nam như Luật, Bộ luật, Ph&aacute;p lệnh, Nghị định, Th&ocirc;ng tư, quy chuẩn, quy tắc, quyết định h&agrave;nh ch&iacute;nh của cơ quan nh&agrave; nước c&oacute; thẩm quyền v&agrave; c&aacute;c quy định c&oacute; hiệu lực ph&aacute;p luật kh&aacute;c tại từng thời điểm.</p>\n' +
				     '<p>1.13 Ng&agrave;y L&agrave;m Việc: l&agrave; c&aacute;c ng&agrave;y từ Thứ Hai đến Thứ S&aacute;u, kh&ocirc;ng bao gồm ng&agrave;y nghỉ, lễ, Tết theo Quy Định Ph&aacute;p Luật.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">2. C&aacute;c Quy Tắc Chung</h1>\n' +
				     '<p>2.1 Bằng việc truy cập, tải về Ứng Dụng GooPay, Người Sử Dụng x&aacute;c nhận đ&atilde; hiểu r&otilde; c&aacute;c Điều Khoản Chung v&agrave; ho&agrave;n to&agrave;n đồng &yacute; với từng phần cũng như to&agrave;n bộ c&aacute;c điều khoản v&agrave; điều kiện được quy định tại đ&acirc;y, cũng như bất kỳ c&aacute;c điều chỉnh li&ecirc;n quan v&agrave; chấp nhận rằng việc sử dụng Sản Phẩm/Dịch Vụ sẽ chịu sự điều chỉnh của những Điều Khoản Chung n&agrave;y.</p>\n' +
				     '<p>2.2 Bằng việc truy cập, tải về v&agrave; sử dụng Ứng Dụng GooPay, Người Sử Dụng thừa nhận v&agrave; đồng &yacute; rằng đ&atilde; chấp thuận với c&aacute;c phương thức, y&ecirc;u cầu, v&agrave;/hoặc ch&iacute;nh s&aacute;ch được quy định trong Điều Khoản Chung n&agrave;y, v&agrave; rằng Người Sử Dụng theo đ&acirc;y đồng &yacute; cho V&iacute; GooPay thu thập, sử dụng, tiết lộ v&agrave;/hoặc xử l&yacute; dữ liệu c&aacute; nh&acirc;n của Người Sử Dụng như được m&ocirc; tả trong Điều Khoản Chung n&agrave;y.</p>\n' +
				     '<p>2.3 Người Sử Dụng sẽ được xem l&agrave; đương nhi&ecirc;n chấp nhận v&agrave; chịu sự r&agrave;ng buộc của những Điều Khoản Chung n&agrave;y v&agrave; việc Người Sử Dụng d&ugrave;ng một phần hoặc to&agrave;n bộ c&aacute;c Sản Phẩm/Dịch Vụ tr&ecirc;n Ứng Dụng GooPay được xem l&agrave; giữa Người Sử Dụng v&agrave; V&iacute; GooPay đ&atilde; k&yacute; kết v&agrave; thực hiện một Hợp đồng dịch vụ.</p>\n' +
				     '<p>2.4 V&iacute; GooPayc&oacute; quyền thay đổi những Điều Khoản Chung n&agrave;y hoặc bất kỳ t&iacute;nh năng n&agrave;o của Sản Phẩm/Dịch Vụ v&agrave;o bất kỳ thời điểm n&agrave;o. Thay đổi đ&oacute; sẽ c&oacute; hiệu lực ngay lập tức sau khi c&ocirc;ng bố thay đổi của c&aacute;c Điều Khoản Chung hoặc t&iacute;nh năng tại Ứng Dụng GooPay.</p>\n' +
				     '<p>2.5 Người Sử Dụng đồng &yacute; đ&aacute;nh gi&aacute; những Điều Khoản Chung n&agrave;y định kỳ để đảm bảo rằng Người Sử Dụng đ&atilde; được cập nhật đối với bất kỳ c&aacute;c thay đổi hoặc sửa đổi đối với những Điều Khoản Chung n&agrave;y. Việc Người Sử Dụng tiếp tục sử dụng Sản Phẩm/Dịch Vụ sẽ được xem l&agrave; Người Sử Dụng chấp nhận ho&agrave;n to&agrave;n c&aacute;c Điều Khoản Chung được thay đổi.</p>\n' +
				     '<p>2.6 Người Sử Dụng đảm bảo rằng Người Sử Dụng đ&atilde; hiểu r&otilde; c&aacute;c hướng dẫn v&agrave; quy tr&igrave;nh sử dụng Sản Phẩm/Dịch Vụ của V&iacute; GooPay v&agrave; những thay đổi, bổ sung (nếu c&oacute;) của V&iacute; GooPay.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">3. Đăng K&yacute;/Ngưng sử dụng Sản Phẩm/Dịch Vụ</h1>\n' +
				     '<p>3.1 Đăng K&yacute; v&agrave; sử dụng Sản Phẩm/Dịch Vụ</p>\n' +
				     '<p>a. Để sử dụng Sản Phẩm/Dịch Vụ, trước hết Người Sử Dụng cần tải Ứng Dụng GooPay, cung cấp Hồ Sơ Mở T&agrave;i Khoản GooPay v&agrave; l&agrave;m theo hướng dẫn.</p>\n' +
				     '<p>b. Người Sử Dụng đồng &yacute; cung cấp cho V&iacute; GooPay hoặc c&aacute;c Điểm Giao Dịch của V&iacute; GooPay c&aacute;c th&ocirc;ng tin đầy đủ, cập nhật v&agrave; ch&iacute;nh x&aacute;c li&ecirc;n quan đến Người Sử Dụng m&agrave; V&iacute; GooPay sẽ y&ecirc;u cầu v&agrave;o từng thời điểm để sử dụng Sản Phẩm/Dịch Vụ. Người Sử Dụng đồng &yacute; th&ocirc;ng b&aacute;o ngay cho V&iacute; GooPay hoặc c&aacute;c Điểm Giao Dịch của V&iacute; GooPay bất kỳ thay đổi n&agrave;o về Hồ Sơ Mở T&agrave;i Khoản GooPay v&agrave; c&aacute;c th&ocirc;ng tin đ&atilde; được cung cấp cho V&iacute; GooPay. Người Sử Dụng tuy&ecirc;n bố v&agrave; bảo đảm rằng c&aacute;c th&ocirc;ng tin của Người Sử Dụng v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c được cung cấp cho V&iacute; GooPay l&agrave; trung thực v&agrave; ch&iacute;nh x&aacute;c v&agrave; chịu tr&aacute;ch nhiệm đối với c&aacute;c th&ocirc;ng tin đ&atilde; cung cấp tr&ecirc;n Ứng Dụng GooPay.</p>\n' +
				     '<p>c. Theo y&ecirc;u cầu của V&iacute; GooPay, Người Sử Dụng sẽ cung cấp cho V&iacute; GooPay c&aacute;c th&ocirc;ng tin li&ecirc;n quan đến việc sử dụng Sản Phẩm/Dịch Vụ m&agrave; V&iacute; GooPay c&oacute; thể y&ecirc;u cầu một c&aacute;ch hợp l&yacute; cho c&aacute;c mục đ&iacute;ch sau đ&acirc;y:</p>\n' +
				     '<p>Trợ gi&uacute;p V&iacute; GooPay tu&acirc;n thủ c&aacute;c nghĩa vụ của m&igrave;nh theo Quy Định Ph&aacute;p Luật;</p>\n' +
				     '<p>B&aacute;o c&aacute;o c&aacute;c cơ quan hữu quan hoặc c&aacute;c cơ quan ch&iacute;nh phủ về việc tu&acirc;n thủ những nghĩa vụ đ&oacute;;</p>\n' +
				     '<p>Đ&aacute;nh gi&aacute; việc Người Sử Dụng đ&atilde; tu&acirc;n thủ, đang tu&acirc;n thủ v&agrave; c&oacute; thể tiếp tục tu&acirc;n thủ tất cả c&aacute;c nghĩa vụ của m&igrave;nh theo những Điều Khoản Chung n&agrave;y hay kh&ocirc;ng.</p>\n' +
				     '<p>d. Trừ khi ph&aacute;p luật c&oacute; quy định kh&aacute;c, Người Sử Dụng buộc phải ho&agrave;n th&agrave;nh việc li&ecirc;n kết T&agrave;i Khoản GooPay với t&agrave;i khoản thanh to&aacute;n hoặc thẻ ghi nợ của Người Sử Dụng mở tại ng&acirc;n h&agrave;ng li&ecirc;n kết để được k&iacute;ch hoạt t&iacute;nh năng V&iacute; điện tử GooPay. Người Sử Dụng được li&ecirc;n kết T&agrave;i Khoản GooPay với một hoặc nhiều t&agrave;i khoản thanh to&aacute;n hoặc thẻ ghi nợ của Người Sử Dụng l&agrave; chủ T&agrave;i Khoản GooPay mở tại một hoặc một số ng&acirc;n h&agrave;ng li&ecirc;n kết.</p>\n' +
				     '<p>e. Việc nạp tiền v&agrave;o T&agrave;i Khoản GooPay của Người Sử Dụng phải thực hiện từ:</p>\n' +
				     '<p>T&agrave;i khoản thanh to&aacute;n hoặc thẻ ghi nợ của Người Sử Dụng l&agrave; chủ T&agrave;i Khoản GooPay tại ng&acirc;n h&agrave;ng; hoặc/v&agrave;</p>\n' +
				     '<p>Nhận tiền từ T&agrave;i Khoản GooPay của Người Sử Dụng kh&aacute;c c&ugrave;ng được mở bởi V&iacute; GooPay.</p>\n' +
				     '<p>f. Người Sử Dụng được sử dụng T&agrave;i Khoản GooPay của m&igrave;nh để:</p>\n' +
				     '<p>Thanh to&aacute;n cho c&aacute;c h&agrave;ng h&oacute;a, dịch vụ hợp ph&aacute;p; hoặc/v&agrave;</p>\n' +
				     '<p>Chuyển tiền cho T&agrave;i Khoản GooPay của Người Sử Dụng kh&aacute;c do V&iacute; GooPay mở; hoặc/v&agrave;</p>\n' +
				     '<p>R&uacute;t tiền ra khỏi T&agrave;i Khoản GooPay của Người Sử Dụng về t&agrave;i khoản thanh to&aacute;n hoặc thẻ ghi nợ của Người Sử Dụng (l&agrave; chủ T&agrave;i Khoản GooPay) tại ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>g. V&iacute; GooPay sẽ c&oacute; quyền &aacute;p dụng ph&iacute; dịch vụ v&agrave;/hoặc lệ ph&iacute; đối với Sản Phẩm/Dịch Vụ; chi tiết biểu ph&iacute; xem tại đ&acirc;y. Người Sử Dụng đồng &yacute; sẽ chịu tr&aacute;ch nhiệm thanh to&aacute;n đầy đủ v&agrave; đ&uacute;ng hạn mọi khoản ph&iacute; dịch vụ v&agrave; c&aacute;c lệ ph&iacute; kh&aacute;c đến hạn thanh to&aacute;n li&ecirc;n quan đến bất kỳ Giao Dịch n&agrave;o hoặc việc sử dụng Sản Phẩm/Dịch Vụ m&agrave; V&iacute; GooPay t&iacute;nh ph&iacute;, v&agrave; đồng &yacute; cho ph&eacute;p V&iacute; GooPay khấu trừ bất kỳ khoản ph&iacute;, lệ ph&iacute; hay khoản tiền kh&aacute;c m&agrave; Ngưởi Sử Dụng phải trả cho V&iacute; GooPay v&agrave;o số dư T&agrave;i Khoản GooPay của Người Sử Dụng. V&iacute; GooPay c&oacute; quyền điều chỉnh, thay đổi biểu ph&iacute; t&ugrave;y từng thời điểm theo quyết định ri&ecirc;ng của m&igrave;nh.</p>\n' +
				     '<p>h. Người Sử Dụng cam kết kh&ocirc;ng sử dụng Sản Phẩm/Dịch Vụ cho bất kỳ mục đ&iacute;ch hoặc li&ecirc;n quan đến bất kỳ h&agrave;nh động vi phạm c&aacute;c Quy Định Ph&aacute;p Luật, bao gồm, nhưng kh&ocirc;ng giới hạn, c&aacute;c luật v&agrave; quy định li&ecirc;n quan đến ph&ograve;ng, chống rửa tiền, chống t&agrave;i trợ khủng bố.</p>\n' +
				     '<p>i. Người Sử Dụng x&aacute;c nhận v&agrave; c&ocirc;ng nhận rằng Người Sử Dụng c&oacute; đầy đủ năng lực h&agrave;nh vi, quyền hạn hoặc thẩm quyền để sử dụng Sản Phẩm/Dịch Vụ.</p>\n' +
				     '<p>j. Người Sử Dụng sẽ chịu tr&aacute;ch nhiệm quản l&yacute; t&agrave;i khoản, mật khẩu t&agrave;i khoản, c&aacute;c th&ocirc;ng tin li&ecirc;n quan đến t&agrave;i khoản, Biện Ph&aacute;p X&aacute;c Thực, th&ocirc;ng tin thiết bị&hellip; của m&igrave;nh. Nếu th&ocirc;ng tin c&aacute;c th&ocirc;ng tin tr&ecirc;n của Người Sử Dụng bị mất hoặc bị lấy cắp hoặc bị tiết lộ một c&aacute;ch bất hợp ph&aacute;p, th&igrave; Người Sử Dụng phải thay đổi th&ocirc;ng tin t&agrave;i khoản bằng c&aacute;ch sử dụng c&aacute;c c&ocirc;ng cụ được c&agrave;i đặt sẵn trong Ứng Dụng GooPay hoặc th&ocirc;ng b&aacute;o ngay cho V&iacute; GooPay th&ocirc;ng qua Dịch Vụ Kh&aacute;ch H&agrave;ng để tạm ngừng T&agrave;i Khoản GooPay. Người Sử Dụng sẽ ho&agrave;n to&agrave;n chịu tr&aacute;ch nhiệm về bất kỳ v&agrave; tất cả y&ecirc;u cầu Giao Dịch đ&atilde; xảy ra trước khi V&iacute; GooPay nhận được th&ocirc;ng b&aacute;o đ&oacute;. Người Sử Dụng lưu &yacute; rằng T&agrave;i Khoản GooPay sẽ chỉ tạm thời ngừng khi Người Sử Dụng đ&atilde; cung cấp mọi th&ocirc;ng tin được y&ecirc;u cầu cho Dịch Vụ Kh&aacute;ch H&agrave;ng m&agrave; Dịch Vụ Kh&aacute;ch H&agrave;ng c&oacute; thể y&ecirc;u cầu một c&aacute;ch hợp l&yacute;.</p>\n' +
				     '<p>k. Khi sử dụng Sản Phẩm/Dịch Vụ, Người Sử Dụng chịu tr&aacute;ch nhiệm về bất kỳ v&agrave; tất cả h&agrave;nh động cũng như sai s&oacute;t của m&igrave;nh trong việc vận h&agrave;nh Ứng Dụng GooPay v&agrave;/hoặc thực hiện Giao Dịch. Nếu bất kỳ một sai s&oacute;t hay sự cố n&agrave;o xảy ra, Người Sử Dụng phải li&ecirc;n hệ ngay với Dịch Vụ Kh&aacute;ch H&agrave;ng để được hướng dẫn. V&iacute; GooPay sẽ nỗ lực hết sức để tư vấn v&agrave; trợ gi&uacute;p Người Sử Dụng.</p>\n' +
				     '<p>l. Trong trường hợp c&oacute; sự cố về Sản Phẩm/Dịch Vụ hoặc nếu một Giao Dịch kh&ocirc;ng được thực hiện theo y&ecirc;u cầu của Người Sử Dụng, Người Sử Dụng sẽ th&ocirc;ng b&aacute;o ngay cho V&iacute; GooPay về sự cố đ&oacute; v&agrave; V&iacute; GooPay sẽ nỗ lực hết sức để tư vấn v&agrave; trợ gi&uacute;p Người Sử Dụng.</p>\n' +
				     '<p>m. V&iacute; GooPay đồng &yacute;, tr&ecirc;n cơ sở to&agrave;n quyền quyết định bồi ho&agrave;n cho bất kỳ Giao Dịch n&agrave;o đ&atilde; được thực hiện sai do lỗi của V&iacute; GooPay.</p>\n' +
				     '<p>3.2 Ngưng sử dụng Sản Phẩm/Dịch Vụ</p>\n' +
				     '<p>a. V&iacute; GooPay ngừng, chấm dứt v&agrave; hủy bỏ Sản Phẩm/Dịch Vụ:</p>\n' +
				     '<p>Người Sử Dụng đồng &yacute;, x&aacute;c nhận v&agrave; chấp thuận rằng Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ) c&oacute; thể được V&iacute; GooPay hủy bỏ v&igrave; bất kỳ l&yacute; do n&agrave;o m&agrave; V&iacute; GooPay thấy l&agrave; ph&ugrave; hợp v&agrave; cần thiết v&agrave;o bất kỳ thời điểm n&agrave;o m&agrave; kh&ocirc;ng cần th&ocirc;ng b&aacute;o trước cho Người Sử Dụng. Người Sử Dụng cũng đồng &yacute; rằng bất kỳ l&yacute; do hủy bỏ n&agrave;o m&agrave; V&iacute; GooPay đưa ra sẽ được Người Sử Dụng xem l&agrave; l&yacute; do hợp l&yacute;. Sau khi hủy bỏ, Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ) c&oacute; thể được cung cấp lại bởi V&iacute; GooPay tr&ecirc;n cơ sở to&agrave;n quyền quyết định thấy l&agrave; ph&ugrave; hợp.</p>\n' +
				     '<p>Người Sử Dụng đồng &yacute;, x&aacute;c nhận v&agrave; chấp thuận rằng v&agrave;o mọi thời điểm V&iacute; GooPay c&oacute; quyền ngừng hoặc chấm dứt T&agrave;i Khoản của Người Sử Dụng hoặc khả năng tiếp cận v&agrave; sử dụng Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ) của Người Sử Dụng v&igrave; bất kỳ l&yacute; do n&agrave;o m&agrave; V&iacute; GooPay thấy l&agrave; ph&ugrave; hợp v&agrave; cần thiết, bao gồm, nhưng kh&ocirc;ng giới hạn trường hợp Người Sử Dụng vi phạm bất kỳ quy định n&agrave;o của những Điều Khoản Chung n&agrave;y hoặc l&agrave;m tr&aacute;i hoặc vi phạm bất kỳ quy định, luật hiện h&agrave;nh li&ecirc;n quan đến việc sử dụng Sản Phẩm/Dịch Vụ. Người Sử Dụng cũng đồng &yacute; rằng bất kỳ l&yacute; do hủy bỏ n&agrave;o do V&iacute; GooPay đưa ra sẽ được Người Sử Dụng xem l&agrave; hợp l&yacute;. Việc tạm ngừng cung cấp Sản Phẩm/Dịch Vụ c&oacute; thể được thực hiện trong bất kỳ thời điểm n&agrave;o v&agrave; theo bất kỳ điều kiện n&agrave;o m&agrave; V&iacute; GooPay tr&ecirc;n cơ sở to&agrave;n quyền quyết định thấy l&agrave; ph&ugrave; hợp.</p>\n' +
				     '<p>"Nếu Người Sử Dụng vi phạm bất kỳ quy định n&agrave;o của Điều Khoản Chung n&agrave;y hoặc l&agrave;m tr&aacute;i hoặc vi phạm bất kỳ quy định", Quy Định Ph&aacute;p Luật li&ecirc;n quan đến việc sử dụng Sản Phẩm/Dịch Vụ, V&iacute; GooPay c&oacute; quyền ngừng Sản Phẩm/Dịch Vụ th&ocirc;ng b&aacute;o cho cơ quan nh&agrave; nước c&oacute; thẩm quyền v&agrave;/hoặc c&aacute;c c&aacute; nh&acirc;n, tổ chức li&ecirc;n quan về việc l&agrave;m tr&aacute;i hoặc vi phạm theo c&aacute;ch thức ph&ugrave; hợp. Sau khi hủy bỏ hoặc chấm dứt Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ):</p>\n' +
				     '<p>Tất cả c&aacute;c quyền đ&atilde; được trao cho Người Sử Dụng theo những Điều Khoản Chung n&agrave;y sẽ chấm dứt ngay lập tức;</p>\n' +
				     '<p>Người Sử Dụng phải thanh to&aacute;n ngay cho V&iacute; GooPay mọi khoản ph&iacute; v&agrave; lệ ph&iacute; chưa trả đến hạn v&agrave; c&ograve;n nợ V&iacute; GooPay (nếu c&oacute;);</p>\n' +
				     '<p>Người Sử Dụng tại đ&acirc;y ủy quyền kh&ocirc;ng hủy ngang v&agrave; v&ocirc; điều kiện cho V&iacute; GooPay ho&agrave;n trả số dư c&oacute; trong T&agrave;i Khoản GooPay (nếu c&oacute;) cho Người Sử Dụng, sau khi khấu trừ mọi khoản tiền (bao gồm, nhưng kh&ocirc;ng giới hạn c&aacute;c khoản ph&iacute; v&agrave; lệ ph&iacute;) đến hạn thanh to&aacute;n v&agrave; c&ograve;n nợ V&iacute; GooPay (nếu c&oacute;) bởi Người Sử Dụng.</p>\n' +
				     '<p>b. Người Sử Dụng chấm dứt sử dụng Sản Phẩm/Dịch Vụ:</p>\n' +
				     '<p>Người Sử Dụng c&oacute; thể chấm dứt việc sử dụng Sản Phẩm/Dịch Vụ của m&igrave;nh căn cứ theo những Điều Khoản Chung n&agrave;y v&agrave;o bất kỳ thời điểm n&agrave;o bằng c&aacute;ch đến c&aacute;c Điểm Giao Dịch của V&iacute; GooPay hoặc li&ecirc;n hệ với Dịch Vụ Kh&aacute;ch H&agrave;ng để được hướng dẫn.</p>\n' +
				     '<p>Sau khi hủy bỏ hoặc chấm dứt sử dụng Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ):</p>\n' +
				     '<p>Tất cả c&aacute;c quyền đ&atilde; được trao cho Người Sử Dụng theo những Điều Khoản Chung n&agrave;y li&ecirc;n quan đến Sản Phẩm/Dịch Vụ đ&atilde; chấm dứt sẽ chấm dứt ngay lập tức;</p>\n' +
				     '<p>Người Sử Dụng phải thanh to&aacute;n ngay cho V&iacute; GooPay mọi khoản ph&iacute; v&agrave; lệ ph&iacute; chưa trả đến hạn v&agrave; c&ograve;n nợ V&iacute; GooPay li&ecirc;n quan đến Sản Phẩm/Dịch Vụ đ&atilde; chấm dứt (nếu c&oacute;);</p>\n' +
				     '<p>Trong trường hợp tất cả c&aacute;c Sản Phẩm/Dịch Vụ đều đ&atilde; được chấm dứt, Người Sử Dụng tại đ&acirc;y ủy quyền kh&ocirc;ng hủy ngang v&agrave; v&ocirc; điều kiện cho V&iacute; GooPay ho&agrave;n lại số dư c&oacute; trong T&agrave;i Khoản GooPay (nếu c&oacute;) cho Người Sử Dụng, sau khi khấu trừ mọi khoản tiền (bao gồm, nhưng kh&ocirc;ng giới hạn c&aacute;c khoản ph&iacute; v&agrave; lệ ph&iacute;) đến hạn v&agrave; c&ograve;n nợ V&iacute; GooPay (nếu c&oacute;) bởi Người Sử Dụng.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">4. Quyền sở hữu tr&iacute; tuệ</h1>\n' +
				     '<p>4.1. Tất cả c&aacute;c nh&atilde;n hiệu h&agrave;ng h&oacute;a, logo, nh&atilde;n hiệu dịch vụ v&agrave; tất cả c&aacute;c quyền sở hữu tr&iacute; tuệ kh&aacute;c thuộc bất kỳ loại n&agrave;o (cho d&ugrave; đ&atilde; được đăng k&yacute; hay chưa), v&agrave; tất cả c&aacute;c nội dung th&ocirc;ng tin, thiết kế, t&agrave;i liệu, đồ họa, phần mềm, h&igrave;nh ảnh, video, &acirc;m nhạc, &acirc;m thanh, phức hợp phần mềm, m&atilde; nguồn v&agrave; phần mềm cơ bản li&ecirc;n quan đến V&iacute; GooPay (gọi chung l&agrave; &ldquo;Quyền Sở Hữu Tr&iacute; Tuệ&rdquo;) l&agrave; t&agrave;i sản v&agrave; lu&ocirc;n lu&ocirc;n l&agrave; t&agrave;i sản của V&iacute; GooPay v&agrave; c&aacute;c tổ chức/đại l&yacute; kh&aacute;c được ủy quyền bởi V&iacute; GooPay (nếu c&oacute;). Tất cả c&aacute;c Quyền Sở Hữu Tr&iacute; Tuệ được bảo hộ bởi Quy Định Ph&aacute;p Luật về bản quyền v&agrave; c&aacute;c c&ocirc;ng ước quốc tế. Tất cả c&aacute;c quyền đều được bảo lưu.</p>\n' +
				     '<p>4.2. Ngoại trừ được cho ph&eacute;p r&otilde; r&agrave;ng trong những Điều Khoản Chung n&agrave;y, Người Sử Dụng kh&ocirc;ng được sử dụng, bi&ecirc;n tập, c&ocirc;ng bố, m&ocirc; phỏng, dịch, thực hiện c&aacute;c sản phẩm ph&aacute;i sinh từ, ph&acirc;n ph&aacute;t hoặc bằng c&aacute;ch kh&aacute;c sử dụng, t&aacute;i sử dụng, sao ch&eacute;p, sửa đổi, hoặc c&ocirc;ng bố Quyền Sở Hữu Tr&iacute; Tuệ theo bất kỳ c&aacute;ch thức n&agrave;o m&agrave; kh&ocirc;ng c&oacute; sự chấp thuận trước bằng văn bản của GooPay. Người Sử Dụng kh&ocirc;ng được trợ gi&uacute;p hoặc tạo điều kiện cho bất kỳ b&ecirc;n thứ ba n&agrave;o sử dụng Quyền Sở Hữu Tr&iacute; Tuệ theo bất kỳ c&aacute;ch thức n&agrave;o m&agrave; cấu th&agrave;nh một vi phạm về sở hữu tr&iacute; tuệ v&agrave;/hoặc đối với c&aacute;c quyền li&ecirc;n quan kh&aacute;c của V&iacute; GooPay.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">5. Bồi ho&agrave;n</h1>\n' +
				     '<p>Người Sử Dụng đồng &yacute; bồi ho&agrave;n cho V&iacute; GooPay v&agrave; c&aacute;c b&ecirc;n li&ecirc;n quan của V&iacute; GooPay v&agrave; đảm bảo cho họ kh&ocirc;ng bị thiệt hại bởi mọi tổn thất, khiếu nại, y&ecirc;u cầu, khiếu kiện, thủ tục tố tụng, chi ph&iacute; (bao gồm, nhưng kh&ocirc;ng giới hạn, c&aacute;c chi ph&iacute; ph&aacute;p l&yacute;) v&agrave; c&aacute;c tr&aacute;ch nhiệm c&oacute; thể phải g&aacute;nh chịu hoặc đưa ra đối với V&iacute; GooPay v&agrave;/hoặc c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ&hellip; của V&iacute; GooPay ph&aacute;t sinh từ hoặc li&ecirc;n quan đến:</p>\n' +
				     '<p>* Việc Người Sử Dụng sử dụng Sản Phẩm/Dịch Vụ (hoặc bất kỳ phần n&agrave;o của Sản Phẩm/Dịch Vụ);</p>\n' +
				     '<p>* Việc Người Sử Dụng vi phạm những Điều Khoản Chung n&agrave;y.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">6. Tiết Lộ Th&ocirc;ng Tin</h1>\n' +
				     '<p>6.1 Người Sử Dụng đồng &yacute; rằng V&iacute; GooPay c&oacute; thể thu thập, lưu trữ, sử dụng v&agrave; xử l&yacute; c&aacute;c th&ocirc;ng tin về Hồ Sơ Mở T&agrave;i Khoản GooPay cũng như c&aacute;c th&ocirc;ng tin kh&aacute;c từ Người Sử Dụng hoặc c&aacute;c b&ecirc;n thứ ba để phục vụ cho mục đ&iacute;ch nhận biết kh&aacute;ch h&agrave;ng v&agrave; x&aacute;c thực theo Quy Định Ph&aacute;p Luật. V&iacute; GooPay cũng c&oacute; thể thu thập, lưu trữ, sử dụng v&agrave; xử l&yacute; th&ocirc;ng tin c&aacute; nh&acirc;n của Người Sử Dụng cho mục đ&iacute;ch nghi&ecirc;n cứu v&agrave; ph&acirc;n t&iacute;ch hoạt động v&agrave; cải tiến Sản Phẩm/Dịch Vụ.</p>\n' +
				     '<p>6.2 Khi Người Sử Dụng đăng k&yacute; một T&agrave;i Khoản GooPay để sử dụng Sản Phẩm/Dịch Vụ, Người Sử Dụng hiểu v&agrave; đồng &yacute; cấp quyền cho V&iacute; GooPay thu thập, lưu giữ, sử dụng v&agrave; xử l&yacute; th&ocirc;ng tin th&ocirc;ng qua việc truy cập v&agrave;o c&aacute;c ứng dụng sau tr&ecirc;n thiết bị di động theo những Điều Khoản Chung n&agrave;y.</p>\n' +
				     '<p>Vị tr&iacute;: lấy th&ocirc;ng tin về vị tr&iacute; để hiển thị danh s&aacute;ch c&aacute;c điểm giao dịch gần nhất.</p>\n' +
				     '<p>Trạng th&aacute;i kết nối: để đảm bảo c&aacute;c t&iacute;nh năng trực tuyến của Ứng Dụng GooPay hoạt động đ&uacute;ng.</p>\n' +
				     '<p>M&aacute;y chụp h&igrave;nh: cho ph&eacute;p Người Sử Dụng d&ugrave;ng để qu&eacute;t m&atilde; QR.</p>\n' +
				     '<p>Trạng th&aacute;i kết nối: để đảm bảo c&aacute;c t&iacute;nh năng trực tuyến của Ứng Dụng GooPay hoạt động đ&uacute;ng.</p>\n' +
				     '<p>6.3 Người Sử Dụng chấp thuận, ủy quyền kh&ocirc;ng hủy ngang v&agrave; v&ocirc; điều kiện cho V&iacute; GooPay tiết lộ hoặc c&ocirc;ng bố c&aacute;c th&ocirc;ng tin li&ecirc;n quan đến Người Sử Dụng hoặc c&aacute;c Giao Dịch của Người Sử Dụng với c&aacute;c c&aacute; nh&acirc;n hoặc tổ chức m&agrave; V&iacute; GooPay c&oacute; thể được y&ecirc;u cầu tiết lộ theo bất kỳ Quy Định Ph&aacute;p Luật hoặc quy định n&agrave;o &aacute;p dụng đối với V&iacute; GooPay hoặc căn cứ theo bất kỳ y&ecirc;u cầu hoặc lệnh n&agrave;o của bất kỳ cơ quan nh&agrave; nước c&oacute; thẩm quyền n&agrave;o hoặc lệnh của t&ograve;a &aacute;n.</p>\n' +
				     '<p>6.4 Người Sử Dụng đồng &yacute; với Ch&iacute;nh S&aacute;ch Quyền Ri&ecirc;ng Tư của V&iacute; GooPay được quy định tại đ&acirc;y.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">7. Giới Hạn Tr&aacute;ch Nhiệm</h1>\n' +
				     '<p>7.1 Trong mọi trường hợp V&iacute; GooPay (bao gồm cả c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay) sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm đối với Người Sử Dụng về bất kỳ tổn thất, thiệt hại, tr&aacute;ch nhiệm v&agrave; chi ph&iacute; n&agrave;o theo bất kỳ nguy&ecirc;n nh&acirc;n h&agrave;nh động n&agrave;o g&acirc;y ra bởi việc sử dụng, hoặc kh&ocirc;ng c&oacute; khả năng sử dụng Sản Phẩm/Dịch Vụ trừ khi V&iacute; GooPay (bao gồm cả c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay) c&oacute; lỗi trong việc để xảy ra tổn thất, thiệt hai.</p>\n' +
				     '<p>7.2 Tuy nhi&ecirc;n, trong trường hợp V&iacute; GooPay (bao gồm cả c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay) phải chịu tr&aacute;ch nhiệm về c&aacute;c tổn thất hoặc thiệt hại theo quy định n&ecirc;u tr&ecirc;n đ&acirc;y, th&igrave; Người Sử Dụng đồng &yacute; rằng to&agrave;n bộ tr&aacute;ch nhiệm của V&iacute; GooPay (bao gồm cả c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay) sẽ được giới hạn ở số tiền thực tế của c&aacute;c thiệt hại trực tiếp phải g&aacute;nh chịu bởi Người Sử Dụng v&agrave; trong bất kỳ trường hợp n&agrave;o sẽ kh&ocirc;ng vượt qu&aacute; tổng số tiền được chuyển v&agrave;o v&agrave; chuyển ra từ T&agrave;i Khoản GooPay của Người Sử Dụng.</p>\n' +
				     '<p>7.3 Trong mọi trường hợp V&iacute; GooPay sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm về bất kỳ thiệt hại gi&aacute;n tiếp, đặc biệt, do hệ quả hoặc sự kiện ngẫu nhi&ecirc;n n&agrave;o ph&aacute;t sinh hoặc bắt nguồn từ việc sử dụng, hoặc kh&ocirc;ng c&oacute; khả năng sử dụng Sản Phẩm/Dịch Vụ.</p>\n' +
				     '<p>7.4 Trong phạm vi m&agrave; Quy Định Ph&aacute;p Luật cho ph&eacute;p, Người Sử Dụng đồng &yacute; rằng V&iacute; GooPay (bao gồm cả c&aacute;c nh&acirc;n vi&ecirc;n, c&aacute;n bộ hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay) sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm về bất kỳ tổn thất, thiệt hại, tr&aacute;ch nhiệm v&agrave;/hoặc chi ph&iacute; n&agrave;o m&agrave; Người Sử Dụng phải g&aacute;nh chịu do việc Người Sử Dụng hoặc một b&ecirc;n thứ ba kh&aacute;c truy cập tr&aacute;i ph&eacute;p v&agrave;o m&aacute;y chủ, giao diện của V&iacute; GooPay, trang Web của V&iacute; GooPay, thiết bị v&agrave;/hoặc dữ liệu của Người Sử Dụng d&ugrave; l&agrave; v&ocirc; t&igrave;nh hoặc bằng c&aacute;ch thức kh&ocirc;ng hợp ph&aacute;p hoặc kh&ocirc;ng được ph&eacute;p như x&acirc;m nhập tr&aacute;i ph&eacute;p hoặc c&aacute;c l&yacute; do kh&aacute;c nằm ngo&agrave;i tầm kiểm so&aacute;t của V&iacute; GooPay (v&iacute; dụ như vi r&uacute;t m&aacute;y t&iacute;nh).</p>\n' +
				     '<p>7.5 V&iacute; GooPay sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm về việc kh&ocirc;ng thực hiện hoặc chậm thực hiện c&aacute;c nghĩa vụ của m&igrave;nh theo những Điều Khoản Chung n&agrave;y do c&aacute;c sự kiện bất khả kh&aacute;ng nằm ngo&agrave;i tầm kiểm so&aacute;t hợp l&yacute; của V&iacute; GooPay, bao gồm, nhưng kh&ocirc;ng giới hạn, thi&ecirc;n tai, b&atilde;o tố, mưa d&ocirc;ng, b&ugrave;ng nổ vi r&uacute;t, c&aacute;c hạn chế của ch&iacute;nh phủ, đ&igrave;nh c&ocirc;ng, chiến tranh, hỏng mạng hoặc hỏng mạng viễn th&ocirc;ng hoặc c&aacute;c sự kiện bất khả kh&aacute;ng kh&aacute;c theo quy định của ph&aacute;p luật hoặc được c&ocirc;ng nhận bởi hai b&ecirc;n.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">8. An Ninh</h1>\n' +
				     '<p>8.1 Người Sử Dụng cam kết sẽ chỉ sử dụng Ứng Dụng GooPay v&agrave; T&agrave;i Khoản GooPay cho c&aacute;c hoạt động hợp ph&aacute;p, kh&ocirc;ng thực hiện c&aacute;c giao dịch thanh to&aacute;n, chuyển tiền bất hợp ph&aacute;p, bao gồm nhưng kh&ocirc;ng giới hạn c&aacute;c h&agrave;nh vi đ&aacute;nh bạc,thanh to&aacute;n c&aacute;c h&agrave;ng h&oacute;a, dịch vụ bất hợp ph&aacute;p hay rửa tiền, v&agrave; sẽ th&ocirc;ng b&aacute;o ngay lập tức cho V&iacute; GooPay về (c&aacute;c) giao dịch c&oacute; dấu hiệu sử dụng tr&aacute;i ph&eacute;p T&agrave;i Khoản GooPay của Người Sử Dụng v&agrave;/hoặc Sản Phẩm/Dịch Vụ đ&atilde; biết hoặc nghi vấn, hoặc bất kỳ vi phạm an ninh n&agrave;o đ&atilde; biết hoặc nghi vấn, kể cả việc mất th&ocirc;ng tin, lấy cắp th&ocirc;ng tin hoặc tiết lộ kh&ocirc;ng được ph&eacute;p về th&ocirc;ng tin c&aacute; nh&acirc;n hoặc T&agrave;i Khoản của Người Sử Dụng.</p>\n' +
				     '<p>8.2 Người Sử Dụng cam kết kh&ocirc;ng sử dụng, v&agrave; kh&ocirc;ng cho bất kỳ b&ecirc;n thứ ba n&agrave;o kh&aacute;c sử dụng, Ứng Dụng GooPay v&agrave; T&agrave;i Khoản GooPay để thực hiện c&aacute;c h&agrave;nh vi x&acirc;m nhập tr&aacute;i ph&eacute;p, tấn c&ocirc;ng hệ thống, ph&aacute;t t&aacute;n virus v&agrave; phần mềm độc hại v&agrave; c&aacute;c h&agrave;nh vi vi phạm Quy Định Ph&aacute;p Luật hoặc g&acirc;y gi&aacute;n đoạn, cản trở hoạt động b&igrave;nh thường đối với hệ thống của V&iacute; GooPay v&agrave; c&aacute;c đối t&aacute;c của V&iacute; GooPay, hoặc đối với việc sử dụng Ứng Dụng GooPay, T&agrave;i Khoản GooPay của người kh&aacute;c.</p>\n' +
				     '<p>8.3 Người Sử Dụng chịu tr&aacute;ch nhiệm v&agrave; miễn trừ to&agrave;n bộ tr&aacute;ch nhiệm cho V&iacute; GooPay về việc sử dụng hoặc hoạt động tr&ecirc;n T&agrave;i Khoản GooPay của Người Sử Dụng trước ph&aacute;p luật. Bất kỳ hoạt động gian lận, lừa gạt hoặc hoạt động bất hợp ph&aacute;p kh&aacute;c c&oacute; thể l&agrave; căn cứ cho ph&eacute;p V&iacute; GooPay tạm ngừng T&agrave;i Khoản GooPay v&agrave;/hoặc chấm dứt Sản Phẩm/Dịch Vụ được cung cấp cho Người Sử Dụng, theo to&agrave;n quyền quyết định của V&iacute; GooPay, v&agrave; V&iacute; GooPay c&oacute; thể tr&igrave;nh b&aacute;o về h&agrave;nh vi của Người Sử Dụng với c&aacute;c cơ quan nh&agrave; nước c&oacute; thẩm quyền để xem x&eacute;t xử l&yacute;.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">9. Th&ocirc;ng Tin Li&ecirc;n Lạc v&agrave; Th&ocirc;ng B&aacute;o</h1>\n' +
				     '<p>9.1 Người Sử Dụng đồng &yacute; rằng V&iacute; GooPay hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay c&oacute; thể gửi tin nhắn hoặc gọi điện thoại cho Người Sử Dụng th&ocirc;ng qua số điện thoại hoặc th&ocirc;ng b&aacute;o qua Ứng Dụng GooPay về hoặc li&ecirc;n quan đến c&aacute;c th&ocirc;ng tin cập nhật về Sản Phẩm/Dịch Vụ v&agrave; c&aacute;c sự kiện được đưa ra hoặc cung cấp bởi V&iacute; GooPay hoặc c&aacute;c b&ecirc;n li&ecirc;n kết của V&iacute; GooPay. Người Sử Dụng đồng &yacute; rằng c&aacute;c th&ocirc;ng b&aacute;o được gửi qua h&igrave;nh thức tin nhắn hoặc cuộc gọi hoặc qua Ứng Dụng GooPay n&ecirc;u tại điều n&agrave;y sẽ kh&ocirc;ng bị giới hạn về số lượng v&agrave; thời gian v&agrave; c&oacute; gi&aacute; trị đầy đủ như một th&ocirc;ng b&aacute;o ch&iacute;nh thức của V&iacute; GooPay đến Người Sử Dụng với điều kiện đảm bảo tu&acirc;n thủ đầy đủ c&aacute;c quy định của ph&aacute;p luật.</p>\n' +
				     '<p>9.2 Người Sử Dụng đồng &yacute; rằng V&iacute; GooPay kh&ocirc;ng c&oacute; bất kỳ nghĩa vụ n&agrave;o trong việc đưa ra th&ocirc;ng b&aacute;o định kỳ cho Người Sử Dụng về chi tiết c&aacute;c Giao Dịch được tiến h&agrave;nh bởi Người Sử Dụng.</p>\n' +
				     '<p>9.3 Mọi th&ocirc;ng b&aacute;o v&agrave; t&agrave;i liệu (nếu c&oacute;) cần phải được đưa ra bởi Người Sử Dụng theo những Điều Khoản Chung n&agrave;y cho V&iacute; GooPay sẽ được gửi cho V&iacute; GooPay đến địa chỉ trụ sở hoặc th&ocirc;ng qua Dịch Vụ Kh&aacute;ch h&agrave;ng của V&iacute; GooPay.</p>\n' +
				     '<p>9.4 Tất cả c&aacute;c th&ocirc;ng b&aacute;o v&agrave; t&agrave;i liệu (nếu c&oacute;) cần gửi cho Người Sử Dụng bởi V&iacute; GooPay theo những Điều Khoản Chung n&agrave;y sẽ được gửi bằng một trong những phương thức sau đ&acirc;y:</p>\n' +
				     '<p>Gửi thư thường hoặc thư bảo đảm đến địa chỉ mới nhất của Người Sử Dụng theo Hồ Sơ Mở T&agrave;i Khoản GooPay của Người Sử Dụng tại V&iacute; GooPay;</p>\n' +
				     '<p>Gửi thư điện tử đến địa chỉ thư điện tử mới nhất của Người Sử Dụng theo ghi ch&eacute;p của V&iacute; GooPay;</p>\n' +
				     '<p>C&ocirc;ng bố th&ocirc;ng b&aacute;o hoặc th&ocirc;ng tin li&ecirc;n lạc tr&ecirc;n trang web GooPay.vn.</p>\n' +
				     '<p>Gửi tin nhắn (SMS) hoặc gọi điện đến số điện thoại mới nhất của Người Sử Dụng theo ghi ch&eacute;p của V&iacute; GooPay.</p>\n' +
				     '<p>9.5 Bất kỳ th&ocirc;ng b&aacute;o hoặc t&agrave;i liệu hoặc thư từ li&ecirc;n lạc n&agrave;o được xem l&agrave; đ&atilde; được gửi v&agrave; nhận:</p>\n' +
				     '<p>Nếu được gửi bằng thư thường hoặc thư bảo đảm, trong v&ograve;ng ba (3) Ng&agrave;y L&agrave;m Việc kể từ ng&agrave;y gửi;</p>\n' +
				     '<p>Nếu được gửi bằng c&aacute;c h&igrave;nh thức kh&aacute;c được quy định tại điều n&agrave;y, Ng&agrave;y L&agrave;m Việc sau ng&agrave;y gửi th&ocirc;ng b&aacute;o hoặc t&agrave;i liệu đ&oacute;.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">10. Xử l&yacute; c&aacute;c giao dịch c&oacute; nhầm lẫn, sự cố kỹ thuật hoặc dấu hiệu vi phạm ph&aacute;p luật</h1>\n' +
				     '<p>10.1 Trong mọi trường hợp gi&aacute; trị tiền được nạp v&agrave;o T&agrave;i Khoản GooPay của Người Sử Dụng ch&ecirc;nh lệch với gi&aacute; trị thực tế m&agrave; Người Sử Dụng y&ecirc;u cầu v&agrave; đ&atilde; thực hiện nạp, Người Sử Dụng c&oacute; nghĩa vụ phải ho&agrave;n trả lại cho V&iacute; GooPay hoặc người gửi tiền theo c&aacute;ch thức v&agrave; trong thời hạn được y&ecirc;u cầu.</p>\n' +
				     '<p>10.2 Trong trường hợp V&iacute; GooPay c&oacute; cơ sở x&aacute;c định một giao dịch được thực hiện do nhầm lẫn, do sự cố kỹ thuật hoặc c&oacute; dấu hiệu vi phạm ph&aacute;p luật, V&iacute; GooPay t&ugrave;y theo quyết định của ri&ecirc;ng m&igrave;nh c&oacute; quyền thực hiện c&aacute;c biện ph&aacute;p ph&ograve;ng ngừa, ngăn chặn nhằm giảm thiểu c&aacute;c thiệt hại c&oacute; thể ph&aacute;t sinh, bao gồm nhưng kh&ocirc;ng giới hạn c&aacute;c biện ph&aacute;p sau:</p>\n' +
				     '<p>Khoanh giữ, đ&oacute;ng băng khoản tiền ph&aacute;t sinh từ hoặc li&ecirc;n quan đến giao dịch trong T&agrave;i Khoản GooPay của Người Sử Dụng; hoặc/v&agrave;</p>\n' +
				     '<p>Tạm ngừng hoạt động T&agrave;i Khoản GooPay của Người Sử Dụng; hoặc/v&agrave;</p>\n' +
				     '<p>Khấu trừ, thu hồi từ T&agrave;i Khoản GooPay của Người Sử Dụng khoản tiền ph&aacute;t sinh do nhầm lẫn v&agrave;/hoặc sự cố kỹ thuật; hoặc/v&agrave;</p>\n' +
				     '<p>B&aacute;o c&aacute;o cơ quan nh&agrave; nươc c&oacute; thẩm quyền để được hướng dẫn xử l&yacute; đối với giao dịch v&agrave;/hoặc khoản tiền ph&aacute;t sinh từ hoặc c&oacute; li&ecirc;n quan đến nhầm lẫn, sự cố kỹ thuật hoặc vi phạm ph&aacute;p luật.</p>\n' +
				     '<p>10.3 Người Sử Dụng đồng &yacute; rằng V&iacute; GooPay c&oacute; quyền thực hiện c&aacute;c biện ph&aacute;p quy định tại Điều 10.2 n&ecirc;u tr&ecirc;n v&agrave; sẽ cam kết hợp t&aacute;c đầy đủ với V&iacute; GooPay trong việc x&aacute;c minh, giải quyết c&aacute;c vấn đề li&ecirc;n quan đến giao dịch c&oacute; nhầm lẫn, sự cố kỹ thuật hoặc dấu hiệu vi phạm ph&aacute;p luật. V&iacute; GooPay c&oacute; quyền tạm kh&oacute;a, phong tỏa T&agrave;i Khoản GooPay v&agrave;/hoặc khoanh giữ, đ&oacute;ng băng khoản tiền li&ecirc;n quan đến giao dịch để x&aacute;c minh, l&agrave;m r&otilde; v&agrave; ph&ograve;ng ngừa, ngăn chặn thiệt hại c&oacute; thể xảy ra.</p>\n' +
				     '<p>10.4 Trong trường hợp Người Sử Dụng kh&ocirc;ng hợp t&aacute;c với V&iacute; GooPay hoặc c&oacute; biểu hiện chiếm đoạt, tẩu t&aacute;n khoản tiền ph&aacute;t sinh từ hoặc li&ecirc;n quan đến nhầm lẫn, sự cố kỹ thuật hoặc vi phạm ph&aacute;p luật, V&iacute; GooPay c&oacute; quyền duy tr&igrave; c&aacute;c biện ph&aacute;p ph&ograve;ng ngừa, ngăn chặn n&ecirc;u tại Điều 10.2 v&agrave; y&ecirc;u cầu cơ quan c&oacute; thẩm quyền xử l&yacute;, bao gồm cả xử l&yacute; h&igrave;nh sự trong trường hợp c&oacute; dấu hiệu tội phạm. Trong mọi trường hợp, V&iacute; GooPay sẽ thực hiện theo kết luận, quyết định hoặc ph&aacute;n quyết cuối c&ugrave;ng của cơ quan nh&agrave; nước c&oacute; thẩm quyền.</p>\n' +
				     '<p>10.5 Trong trường hợp V&iacute; GooPay c&oacute; cơ sở x&aacute;c định Người Sử Dụng c&oacute; h&agrave;nh vi lạm dụng c&aacute;c chương tr&igrave;nh khuyến mại, ch&iacute;nh s&aacute;ch ưu đ&atilde;i, hỗ trợ người d&ugrave;ng của V&iacute; GooPay, V&iacute; GooPay c&oacute; quyền tạm ngừng hoặc chấm dứt c&aacute;c chương tr&igrave;nh, ch&iacute;nh s&aacute;ch ưu đ&atilde;i, hỗ trợ đ&oacute; với một, hoặc một số hoặc to&agrave;n bộ Người Sử Dụng c&oacute; li&ecirc;n quan v&agrave; thực hiện c&aacute;c biện ph&aacute;p cần thiết để thu hồi c&aacute;c ưu đ&atilde;i, hỗ trợ v&agrave; lợi &iacute;ch kinh tế m&agrave; Người Sử Dụng li&ecirc;n quan đ&atilde; nhận được. Trong trường hợp h&agrave;nh vi lạm dụng c&oacute; yếu tố vi phạm Quy Định Ph&aacute;p Luật, V&iacute; GooPay sẽ th&ocirc;ng b&aacute;o với cơ quan nh&agrave; nước c&oacute; thẩm quyền để xem x&eacute;t xử l&yacute;.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">11. C&aacute;c quy định kh&aacute;c</h1>\n' +
				     '<p>11.1 Việc V&iacute; GooPay kh&ocirc;ng thực hiện hoặc &aacute;p dụng bất kỳ quyền hoặc biện ph&aacute;p n&agrave;o m&agrave; V&iacute; GooPay c&oacute; theo quy định tại Điều Khoản Chung n&agrave;y hoặc theo Quy Định Ph&aacute;p Luật kh&ocirc;ng bị xem l&agrave; V&iacute; GooPay từ bỏ hoặc hạn chế quyền hoặc biện ph&aacute;p đ&oacute;, v&agrave; V&iacute; GooPay bảo lưu quyền thực hiện quyền hoặc biện ph&aacute;p đ&oacute; v&agrave;o bất kỳ thời điểm n&agrave;o V&iacute; GooPay nhận thấy th&iacute;ch hợp.</p>\n' +
				     '<p>11.2 Trong trường hợp bất kỳ quy định n&agrave;o của những Điều Khoản Chung n&agrave;y được x&aacute;c định l&agrave; bất hợp ph&aacute;p hoặc kh&ocirc;ng thể thực thi bằng c&aacute;ch kh&aacute;c th&igrave; V&iacute; GooPay sẽ sửa đổi quy định đ&oacute;, hoặc (theo to&agrave;n quyền quyết định của m&igrave;nh) bỏ quy định đ&oacute; ra khỏi những Điều Khoản Chung n&agrave;y. Nếu bất kỳ quy định n&agrave;o của những Điều Khoản Chung n&agrave;y được x&aacute;c định l&agrave; bất hợp ph&aacute;p hoặc kh&ocirc;ng thể thực thi, việc x&aacute;c định như vậy sẽ kh&ocirc;ng ảnh hưởng đến c&aacute;c quy định c&ograve;n lại của những Điều Khoản Chung n&agrave;y, v&agrave; những Điều Khoản Chung n&agrave;y sẽ vẫn c&oacute; đầy đủ hiệu lực.</p>\n' +
				     '<p>11.3 Người Sử Dụng x&aacute;c nhận rằng V&iacute; GooPay, theo c&aacute;c luật v&agrave; quy định hiện h&agrave;nh hoặc sau khi nhận được chỉ thị của c&aacute;c cơ quan hữu quan ch&iacute;nh phủ, c&oacute; thể được y&ecirc;u cầu thực hiện c&aacute;c h&agrave;nh động m&agrave; c&oacute; thể vi phạm c&aacute;c quy định của những Điều Khoản Chung n&agrave;y. Về vấn đề n&agrave;y, Người Sử Dụng đồng &yacute; kh&ocirc;ng buộc V&iacute; GooPay phải chịu tr&aacute;ch nhiệm.</p>\n' +
				     '<p>11.4 Người Sử Dụng kh&ocirc;ng được chuyển nhượng c&aacute;c quyền của m&igrave;nh theo những Điều Khoản Chung n&agrave;y nếu kh&ocirc;ng c&oacute; sự chấp thuận trước bằng văn bản của V&iacute; GooPay. V&iacute; GooPay c&oacute; thể chuyển nhượng c&aacute;c quyền của m&igrave;nh theo những Điều Khoản Chung n&agrave;y m&agrave; kh&ocirc;ng cần c&oacute; sự chấp thuận trước bằng văn bản của Người Sử Dụng.</p>\n' +
				     '<p>11.5 Những Điều Khoản Chung n&agrave;y sẽ c&oacute; gi&aacute; trị r&agrave;ng buộc đối với những người thừa kế, c&aacute;c đại diện c&aacute; nh&acirc;n v&agrave; đại diện theo ph&aacute;p luật, c&aacute;c b&ecirc;n kế nhiệm quyền sở hữu v&agrave; c&aacute;c b&ecirc;n nhận chuyển nhượng được ph&eacute;p về t&agrave;i sản (nếu c&oacute;) của Người Sử Dụng.</p>\n' +
				     '<p>11.6 Bất kỳ tranh chấp hoặc bất đồng n&agrave;o theo những Điều Khoản Chung n&agrave;y trước hết sẽ được giải quyết th&ocirc;ng qua thương lượng h&ograve;a giải. Nếu kh&ocirc;ng đạt được thỏa thuận th&ocirc;ng qua thương lượng h&ograve;a giải như vậy, c&aacute;c b&ecirc;n tại đ&acirc;y đồng &yacute; sẽ đưa tranh chấp hoặc bất đồng l&ecirc;n t&ograve;a &aacute;n c&oacute; thẩm quyền tại Th&agrave;nh Phố Hồ Ch&iacute; Minh để giải quyết.</p>\n' +
				     '<p>11.7 V&iacute; GooPay kh&ocirc;ng thực hiện hoặc &aacute;p dụng bất kỳ quyền hoặc biện ph&aacute;p n&agrave;o m&agrave; V&iacute; GooPay c&oacute; theo quy định tại Điều Khoản Chung n&agrave;y hoặc theo Quy Định Ph&aacute;p Luật kh&ocirc;ng bị xem l&agrave; V&iacute; GooPay từ bỏ hoặc hạn chế quyền hoặc biện ph&aacute;p đ&oacute; v&agrave; V&iacute; GooPay bảo lưu việc thực hiện quyền hoặc biện ph&aacute;p đ&oacute; v&agrave;o bất kỳ thời điểm n&agrave;o m&agrave; V&iacute; GooPay nhận thấy ph&ugrave; hợp.</p>\n' +
				     '<p>11.8 Tranh chấp giữa Người Sử Dụng v&agrave; b&ecirc;n thứ ba: V&iacute; GooPay kh&ocirc;ng c&oacute; bất cứ tr&aacute;ch nhiệm li&ecirc;n quan n&agrave;o m&agrave; chỉ đ&oacute;ng vai tr&ograve; hỗ trợ Người Sử Dụng, cung cấp th&ocirc;ng tin cần thiết để Người Sử Dụng v&agrave; b&ecirc;n thứ ba li&ecirc;n quan để c&ugrave;ng giải quyết. Người Sử Dụng v&agrave; b&ecirc;n thứ ba phải trực tiếp giải quyết mọi vấn đề li&ecirc;n quan đến giao dịch của Người Sử Dụng v&agrave; b&ecirc;n thứ ba. Trong trường hợp c&oacute; khiếu nại, tranh chấp, y&ecirc;u cầu ho&agrave;n tiền&hellip; V&iacute; GooPay c&oacute; to&agrave;n quyền tạm giữ/đ&oacute;ng băng c&aacute;c khoản tiền trong t&agrave;i khoản c&oacute; li&ecirc;n quan cho đến khi vấn đề được giải quyết hoặc c&oacute; quyết định cuối c&ugrave;ng của cơ quan nh&agrave; nước c&oacute; thẩm quyền.</p>\n' +
				     '<p>11.9 Việc V&iacute; GooPay kh&ocirc;ng thực hiện hoặc &aacute;p dụng bất kỳ quyền hoặc biện ph&aacute;p n&agrave;o m&agrave; V&iacute; GooPay c&oacute; theo quy định tại Điều Khoản Chung nay hoặc theo Quy Định Ph&aacute;p Luật kh&ocirc;ng được xem l&agrave; V&iacute; GooPay từ bỏ hoặc hạn chế quyền hoặc biện ph&aacute;p đ&oacute;, v&agrave; V&iacute; GooPay bảo lưu quyền thực hiện quyền hoặc biện ph&aacute;p đ&oacute; v&agrave;o bất kỳ thời điểm n&agrave;o V&iacute; GooPay nhận thấy th&iacute;ch hợp.</p>'
		     }}
		/>
	</div>
);

export default PolicyUsage;