import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NewLink = ({ to, children, ...props }) => {
	const location = useLocation();
	// Append current search params to the "to" path
	const fullPath = `${to}${location.search}`;
	
	return (
		<Link to={fullPath} {...props}>
			{children}
		</Link>
	);
};

export default NewLink;