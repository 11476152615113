import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Banner from './index';


const ServiceBanner = ({image, imageMb, children}) => {
	const banner = {
		id: 'main-banner-1',
		image: '/img/services/banner.png',
		imageMb: '/img/services/banner-mobile.png',
		title: 'Giải pháp tài chính số dành cho hệ sinh thái trong ngành dịch vụ vận tải, vận chuyển hành khách',
		descriptions: 'GooPay cung cấp các phương thức thanh toán không tiền mặt đơn giản, an toàn, tiện lợi qua Ví điện tử, Tài khoản ngân hàng liên kết, đáp ứng nhu cầu thanh toán hàng ngày cho ngành dịch vụ vận tải và vận chuyển hành khách',
	};
	return (
		<Banner {...banner}>
			<div className="absolute px-8 top-[-90px] w-full h-full flex flex-col justify-center items-center">
				<div className='font-bold text-center text-base md:text-3xl md:w-[845px]'>{banner.title}</div>
				<div className='text-sm md:w-[845px] text-center'>{banner.descriptions}</div>
			</div>
		</Banner>
	);
};

export default ServiceBanner;