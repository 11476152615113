import React, { useState } from 'react';
import RegisterLoginContent from './RegisterLoginContent';
import LinkedBankContent from './LinkedBankContent';
import TopUpContent from './TopUpContent';
import PhoneSwiper from '../Common/PhoneSwiper';
import './style.css';

const GuildTabs = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [step, setStep] = useState(0);
	
	const tabs = [
		{ label: 'Đăng ký/ Đăng nhập', content: <RegisterLoginContent setStep={setStep} /> },
		{ label: 'Liên kết ngân hàng', content: <LinkedBankContent setStep={setStep} isLinkedBank/>},
		{ label: 'Nạp tiền vào Ví', content: <TopUpContent setStep={setStep}/> },
	];
	
	const onClickTab = (index) => {
		setActiveTab(index);
	}
	
	return (
		<>
			<div className="mx-auto mt-8 md:w-[990px] relative pb-8 gap-20">
				<div className="md:w-[656px] max-w-4xl">
					<h2 className="text-2xl font-bold text-center mb-6 uppercase">Hướng dẫn sử dụng</h2>
					<div
						className="flex flex-col sm:flex-row justify-center items-center gap-2.5 bg-[#F1F9FF] rounded-lg pt-2.5 shadow-md shadow-bottom">
						{tabs.map((tab, index) => (
							<button
								key={index}
								onClick={() => onClickTab(index)}
								className={`text-base relative flex items-center justify-center py-3 px-6 font-semibold transition-colors duration-300 ${
									activeTab === index
										? 'gradient-box text-white'
										: 'text-gray-700'
								} ${index === 1 ? '' : ''} ${index === 0 ? 'sm:rounded-t-lg' : ''} ${
									index === tabs.length - 1 ? 'rounded-t-lg mb-2 md:mb-0' : ''
								} ${index !== tabs.length - 1 ? 'rounded-t-lg' : ''}`}
								aria-selected={activeTab === index}
								role="tab"
								aria-controls={`tabpanel-${index}`}
								tabIndex={activeTab === index ? 0 : -1}
							>
								{activeTab === index && <i className="absolute w-1 h-5 bg-[#01B54F] left-0 rounded-r-md" />}
								{activeTab === index && <i className="absolute w-1 h-5 bg-[#F05A24] right-0 rounded-l-md" />}
								{tab.label}
							</button>
						))}
					</div>
					<div
						className="md:block bg-transparent px-[30px] pt-4 rounded-b-lg b"
						role="tabpanel"
						id={`tabpanel-${activeTab}`}
						aria-labelledby={`tab-${activeTab}`}
					>
						<div className="md:absolute flex justify-center top-0 right-0">
							<PhoneSwiper activeTab={activeTab} step={step}/>
						</div>
						{tabs[activeTab].content}
					</div>
				</div>
			</div>
			<img className="z-[-1] mt-[-100px] md:mt-[-200px]" src="/img/bg-city.png" alt="bg-city" />
		</>
);
};

export default GuildTabs;