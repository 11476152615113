import React, {useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import './style.css';
import {AnimatePresence, motion} from 'framer-motion';
import {PATH} from "../../../constants";
import NewLink from "../../NewLink/NewLink";

const FeatureRichList = ({data, setStep, isLinkedBank, customClass, subClass}) => {
    const [selectedItem, setSelectedItem] = useState(1);
    const listRef = useRef(null);

    const activeTab = useMemo(() => {
        return data.find((tab) => tab.id === selectedItem)
    }, [selectedItem, data]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                e.preventDefault();
                const currentIndex = selectedItem ? data.findIndex(item => item.id === selectedItem) : -1;
                const newIndex = e.key === 'ArrowDown'
                    ? (currentIndex + 1) % data.length
                    : (currentIndex - 1 + data.length) % data.length;
                setSelectedItem(data[newIndex].id);
            }
        };

        listRef.current.addEventListener('keydown', handleKeyDown);
        return () => {
            //listRef.current.removeEventListener("keydown", handleKeyDown);
        };
    }, [data, selectedItem]);

    const handleItemClick = (id) => {
        if (id === selectedItem) return;
        setSelectedItem(id);
        setStep && setStep(id);
    };

    return (
        <div className="bg-transparent rounded-lg">
            <div className="md:hidden rounded-lg w-full flex flex-col justify-center items-center">
                <AnimatePresence mode="wait">
                    <motion.div
                        key={selectedItem}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -20}}
                        transition={{duration: 0.3}}
                        className="pb-4"
                    >
                        <div className="text-gray-700">

                            <div className="text-sm font-medium text-gray-800">
                                {activeTab.title}
                                {
                                    activeTab?.subtitle && (
                                        activeTab?.subtitle.map((sub, index) => (
                                            <p key={index} className={subClass || "dot-before ml-1"}
                                               dangerouslySetInnerHTML={{__html: sub}}/>
                                        ))
                                    )
                                }
                            </div>
                            {data.find((tab) => tab.id === selectedItem)?.content}
                        </div>
                    </motion.div>
                </AnimatePresence>
                <div className="flex flex-row flex-wrap justify-center items-center gap-5 pb-2">
                    {data.map((tab) => (
                        <button
                            key={tab.id}
                            onClick={() => handleItemClick(tab.id)}
                            className={cn('relative', selectedItem === tab.id ? 'w-[54px] h-[54px]' : 'w-[40px] h-[40px] bg-gradient-custom rounded-full')}
                            aria-label={`Tab ${tab.id}`}
                            aria-selected={selectedItem === tab.id}
                            role="tab"
                        >
                            {selectedItem === tab.id &&
                                <img className="absolute inset-0 z-0" src="/img/step-num-active.png"
                                     alt="step-num-active"/>}
                            <span
                                className={cn('absolute inset-0 flex items-center justify-center text-sm font-semibold z-10', selectedItem === tab.id && 'text-white')}>{tab.id}</span>
                        </button>
                    ))}
                </div>
                {isLinkedBank &&
                    <div
                        className='flex items-center space-x-2 text-xs font-normal text-[#0073DD] cursor-pointer py-2 whitespace-nowrap'>
                        <NewLink to={PATH.LINK_GUIDE}>
                            <div>Xem thêm hướng dẫn liên kết ngân hàng SHB</div>
                        </NewLink>
                        <img src="/img/icon/expand_more.svg" alt="expand_more"/>
                    </div>
                }
            </div>

            {/*for desktop*/}

            <ul ref={listRef} className={cn(customClass, "hidden md:block space-y-8")} aria-label="Feature-Rich List">
                {data.map((item) => (
                    <div
                        key={item.id}
                        onClick={() => handleItemClick(item.id)}
                        className={`flex items-center rounded-lg cursor-pointer${
                            selectedItem === item.id
                                ? ''
                                : ''
                        }`}
                        role="option"
                        aria-selected={selectedItem === item.id}
                        tabIndex="0"
                    >
                        <p
                            className={cn('relative mr-4', selectedItem === item.id ? 'w-[54px] h-[54px] ml-[-7px]' : 'w-[40px] h-[40px] bg-gradient-custom rounded-full')}>
                            {selectedItem === item.id &&
                                <img className="absolute" src="/img/step-num-active.png" alt="step-num-active"/>}
                            <span
                                className={cn('text-sm font-bold absolute inset-0 flex items-center justify-center', selectedItem === item.id && 'text-white')}>
							  {item.id}
							</span>
                        </p>
                        <div className="w-[90%] text-sm font-medium text-gray-800">
                            {item.title}
                            {
                                item?.subtitle && (
                                    item?.subtitle.map((sub, index) => (
                                        <p key={index} className={cn(subClass || "dot-before ml-1")}
                                           dangerouslySetInnerHTML={{__html: sub}}/>
                                    ))
                                )
                            }
                        </div>
                    </div>
                ))}
                {isLinkedBank &&
                    <div className='flex items-center space-x-2 text-sm font-normal text-[#0073DD] pl-2 cursor-pointer'>
                        <NewLink to={PATH.LINK_GUIDE}>
                            <div>Xem thêm hướng dẫn liên kết ngân hàng SHB</div>
                        </NewLink>
                        <img src="/img/icon/expand_more.svg" alt="expand_more"/>
                    </div>
                }
            </ul>
        </div>
    );
};

export default FeatureRichList;