// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQTopUp = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='Nạp - rút tiền'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>Nạp - rút tiền</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<p id="isPasted" style="text-align: center; color: #338FE3;"><strong>Nạp, r&uacute;t tiền qua t&agrave;i khoản ng&acirc;n h&agrave;ng</strong></p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '<li>\n' +
				     '<strong>Số dư tối thiểu trong t&agrave;i khoản ng&acirc;n h&agrave;ng</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Mỗi ng&acirc;n h&agrave;ng c&oacute; quy định về số dư tối thiểu trong t&agrave;i khoản ng&acirc;n h&agrave;ng, th&ocirc;ng thường l&agrave; 50.000đ. Vui l&ograve;ng li&ecirc;n hệ với ng&acirc;n h&agrave;ng của bạn để biết chắc số dư tối thiểu của t&agrave;i khoản của bạn.</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Khi thực hiện giao dịch tr&ecirc;n GooPay với nguồn tiền từ t&agrave;i khoản ng&acirc;n h&agrave;ng li&ecirc;n kết, bạn lưu &yacute; l&agrave; số tiền c&ograve;n lại trong t&agrave;i khoản ng&acirc;n h&agrave;ng sau khi thực hiện giao dịch kh&ocirc;ng được nhỏ hơn số dư tối thiểu. Nếu nhỏ hơn, giao dịch sẽ kh&ocirc;ng th&agrave;nh c&ocirc;ng.</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;" start="2">\n' +
				     '<li>\n' +
				     '<strong>C&aacute;ch r&uacute;t tiền từ GooPay về ng&acirc;n h&agrave;ng đ&atilde; li&ecirc;n kết</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn "R&uacute;t tiền"</p>\n' +
				     '<p>Bước 2: Chọn h&igrave;nh thức "Về ng&acirc;n h&agrave;ng li&ecirc;n kết"</p>\n' +
				     '<p>Bước 3: Nhập số tiền cần r&uacute;t v&agrave; chọn "R&uacute;t tiền"</p>\n' +
				     '<p>Bước 4: Chọn "X&aacute;c nhận" để ho&agrave;n tất giao dịch</p>\n' +
				     '<p>Lưu &yacute;: Hiện GooPay đang MIỄN PH&Iacute; r&uacute;t tiền 10 lần/th&aacute;ng, tối đa 30.000.000đ/th&aacute;ng từ V&iacute; về t&agrave;i khoản ng&acirc;n h&agrave;ng li&ecirc;n kết.</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;" start="3">\n' +
				     '<li>\n' +
				     '<strong>Hạn mức nạp, r&uacute;t tiền qua t&agrave;i khoản ng&acirc;n h&agrave;ng</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Hạn mức nạp, r&uacute;t tiền qua t&agrave;i khoản ng&acirc;n h&agrave;ng t&ugrave;y thuộc v&agrave;o quy định của từng ng&acirc;n h&agrave;ng v&agrave; t&igrave;nh trạng t&agrave;i khoản GooPay đ&atilde; x&aacute;c thực hay chưa x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng. Vui l&ograve;ng xem th&ecirc;m tại đ&acirc;y</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Lưu &yacute;: Đối với t&agrave;i khoản GooPay sử dụng nguồn tiền ng&acirc;n h&agrave;ng li&ecirc;n kết TPBank, hạn mức tối đa cho 1 giao dịch l&agrave; 1.000.000đ, v&agrave; tối đa 1 ng&agrave;y l&agrave; 5.000.000đ</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;" start="4">\n' +
				     '<li>\n' +
				     '<strong>C&aacute;ch nạp tiền v&agrave;o GooPay từ t&agrave;i khoản ng&acirc;n h&agrave;ng đ&atilde; li&ecirc;n kết</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Sau khi đ&atilde; li&ecirc;n kết GooPay với t&agrave;i khoản ng&acirc;n h&agrave;ng, bạn c&oacute; thể thực hiện nạp tiền tr&ecirc;n GooPay như sau:</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn "Nạp tiền v&agrave;o v&iacute;"</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bước 2: Chọn nguồn tiền l&agrave; t&agrave;i khoản ng&acirc;n h&agrave;ng đ&atilde; li&ecirc;n kết</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bước 3: Nhập số tiền cần nạp.</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bước 4: Kiểm tra th&ocirc;ng tin giao dịch v&agrave; x&aacute;c nhận để ho&agrave;n tất.</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Lưu &yacute;:</p>\n' +
				     '<p>- Nạp tiền từ t&agrave;i khoản ng&acirc;n h&agrave;ng v&agrave;o GooPay ho&agrave;n to&agrave;n miễn ph&iacute;</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p style="text-align: center; color: #338FE3;"><strong>Nạp tiền từ thẻ ATM/ Napas</strong></p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;">\n' +
				     '<li>\n' +
				     '<strong>T&ocirc;i c&oacute; thể nạp tiền từ thẻ ATM của ng&acirc;n h&agrave;ng n&agrave;o?</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Bạn c&oacute; thể nạp tiền từ thẻ ATM của c&aacute;c ng&acirc;n h&agrave;ng sau: Agribank, Techcombank, GP Bank, ABBank, Ng&acirc;n h&agrave;ng Qu&acirc;n đội (MB), Maritime Bank, HDBank, Ng&acirc;n h&agrave;ng Quốc d&acirc;n (NCB), VIB, Viet A Bank, Ocean Bank, Bac A Bank, Lien Viet Post Bank, SeABank, Bao Viet Bank, SHB, Kien Long Bank, SCB, Nam A Bank, Public Bank, PVcom Bank, PG Bank.</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bạn c&oacute; thể kiểm tra danh s&aacute;ch cập nhật c&aacute;c ng&acirc;n h&agrave;ng ph&aacute;t h&agrave;nh thẻ ATM được chấp nhận ở GooPay bằng c&aacute;ch chọn "Nạp tiền v&agrave;o v&iacute;" từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng -&gt; chọn "Nguồn tiền kh&aacute;c" -&gt; "Thẻ ATM".</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;" start="2">\n' +
				     '<li>\n' +
				     '<strong>C&aacute;ch nạp tiền từ thẻ ATM</strong>\n' +
				     '</li>\n' +
				     '</ol>\n' +
				     '<p>Để thực hiện nạp tiền, bạn cần đăng k&yacute; sử dụng dịch vụ Internet Banking với ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn "Nạp tiền v&agrave;o V&iacute;" -&gt; &ldquo;NG&Acirc;N H&Agrave;NG CHỈ HỖ TRỢ NẠP TIỀN&rdquo; =&gt;&gt; chọn logo</p>\n' +
				     '<p>Bước 2: Nhập số tiền cần nạp</p>\n' +
				     '<p>Bước 3: Kiểm tra th&ocirc;ng tin nạp tiền v&agrave; chọn "X&aacute;c nhận"</p>\n' +
				     '<p>Bước 4: Nhập th&ocirc;ng tin thẻ ATM</p>\n' +
				     '<p>Bước 5: Nhập m&atilde; x&aacute;c nhận OTP để ho&agrave;n tất</p>\n' +
				     '<p>&nbsp;</p>\n' +
				     '<p>Lưu &yacute;: Nạp tiền từ thẻ ATM v&agrave;o GooPay ho&agrave;n to&agrave;n miễn ph&iacute;.</p>\n' +
				     '<ol style="padding-left:0pt;list-style-type: decimal; line-height: 2.2;font-weight:bold;" start="3">\n'
		     }}
		/>
	</div>
);

export default FAQTopUp;
