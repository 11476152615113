import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import './style.css';


const Banner = ({image, imageMb, children}) => {
	
	return (
		<div className="relative">
			<picture>
				<source srcSet={imageMb} media="(max-width: 768px)" />
				<img src={image} alt={'banner'} className="w-full" />
			</picture>
			{children}
		</div>
	);
};

export default Banner;