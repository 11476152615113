import React from "react";
import {FaFacebook, FaGithub, FaInstagram, FaLinkedin, FaTwitter, FaYoutube} from "react-icons/fa";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.css";

const ImageList = () => {
	const logos = [
		{name: "FUTA", icon: FaGithub, url: "/img/logo/futa.png"},
		{name: "BIDV", icon: FaTwitter, url: "/img/logo/bidv.png"},
		{name: "SHB", icon: FaLinkedin, url: "/img/logo/shb.png"},
		{name: "NAPAS", icon: FaFacebook, url: "/img/logo/napas.png"},
		{name: "FUTA_EXPRESS", icon: FaFacebook, url: "/img/logo/futa-express.png"},
		{name: "VNPT_EPAY", icon: FaFacebook, url: "/img/logo/vnpt-epay.png"},
		{name: "CMC", icon: FaInstagram, url: "/img/logo/cmc.png"},
		{name: "VCB", icon: FaYoutube, url: "/img/logo/vcb.png"},
		{name: "FUTA_ADS", icon: FaYoutube, url: "/img/logo/futa-ads.png"},
		{name: "FUTA_BUS_LINE", icon: FaYoutube, url: "/img/logo/futa-bus-lines.png"},
		{name: "FUTA_CITY_BUS", icon: FaYoutube, url: "/img/logo/futa-citybus.png"},
		{name: "PHUC_LOC", icon: FaYoutube, url: "/img/logo/phuc-loc.png"},
	];
	return (
		<div className="px-8 md:px-[120px] py-8">
			<h2 className="text-2xl font-semibold text-center mb-12 uppercase">Đối tác</h2>
			<div className="hidden md:block">
				<ul className="grid grid-cols-5 gap-2.5 items-center">
					{logos.map((logo, index) => (
						<div key={index} className="flex-shrink-0">
							
							<img src={logo.url} alt={logo.name}/>
						</div>
					))}
				</ul>
			</div>
			<div className="md:hidden">
				<Swiper
					spaceBetween={30}
					loop
					slidesPerView={2}
					pagination={{clickable: true}}
					className="mySwiper"
				>
					{logos.map((logo, index) => (
						<SwiperSlide key={index}>
							<div key={index} className="flex-shrink-0">
								
								<img src={logo.url} alt={logo.name}/>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default ImageList;
