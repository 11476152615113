// src/components/Popup/index.jsx
import React, {useEffect, useRef} from 'react';

const Popup = ({isOpen, onClose, children}) => {
	const popupRef = useRef();
	
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (popupRef.current && !popupRef.current.contains(event.target)) {
				onClose();
			}
		};
		
		const handleScroll = (event) => {
			if (isOpen) {
				event.preventDefault();
			}
		};
		
		if (isOpen) {
			document.body.classList.add('overflow-hidden');
			document.addEventListener('mousedown', handleClickOutside);
			document.addEventListener('scroll', handleScroll, {passive: false});
		} else {
			document.body.classList.remove('overflow-hidden');
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('scroll', handleScroll);
		}
		
		return () => {
			document.body.classList.remove('overflow-hidden');
			document.removeEventListener('mousedown', handleClickOutside);
			document.removeEventListener('scroll', handleScroll);
		};
	}, [isOpen, onClose]);
	
	if (!isOpen) return null;
	
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
			<div ref={popupRef} className="relative bg-white p-6 rounded-md shadow-lg w-[95%] md:w-fit">
				<button onClick={onClose}
				        className="absolute text-2xl font-bold top-1 right-2 text-gray-500 hover:text-gray-700">
					&times;
				</button>
				{children}
			</div>
		</div>
	);
};

export default Popup;