// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {Link} from "react-router-dom";
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQTransfer = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='Chuyển- nhận tiền'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>Chuyển- nhận tiền</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<h2 style="text-align: center; color: #338FE3;"><strong>Chuyển, nhận tiền&nbsp;</strong></h2>\n' +
				     '<br/>' +
				     '<ol><p><strong>1.&nbsp;Hạn mức chuyển/nhận tiền mỗi ng&agrave;y l&agrave; bao nhi&ecirc;u?</strong></p>\n' +
				     '<p>Hạn mức chuyển tiền của t&agrave;i khoản GooPay t&ugrave;y thuộc v&agrave;o t&igrave;nh trạng t&agrave;i khoản đ&atilde; được x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng hay chưa. Cụ thể:</p>\n' +
				     '<ol>\n' +
				     '<li>T&agrave;i khoản GooPay c&oacute; x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng:</li>\n' +
				     '</ol>\n' +
				     '<ul>\n' +
				     '<li>Hạn mức chuyển tiền: tối đa 30.000.000đ/ng&agrave;y</li>\n' +
				     '<li>Hạn mức nhận tiền: tối đa 100.000.000đ/ng&agrave;y&nbsp;</li>\n' +
				     '</ul>\n' +
				     '<ol start="2">\n' +
				     '<li>T&agrave;i khoản GooPay chưa x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng: T&agrave;i khoản n&agrave;y kh&ocirc;ng thể sử dụng nguồn tiền GooPay trong v&iacute;.</li>\n' +
				     '</ol>\n' +
				     '<p>Để biết bạn b&egrave; n&agrave;o của m&igrave;nh đang d&ugrave;ng GooPay hay kh&ocirc;ng, bạn c&oacute; thể kiểm tra nhanh với 2 bước dưới đ&acirc;y:</p>\n' +
				     '<p>Bước 1: Tại m&agrave;n h&igrave;nh ch&iacute;nh, chọn ""Chuyển tiền GooPay"".</p>\n' +
				     '<p>Bước 2: Chọn ""Đến V&iacute; GooPay"" &gt;&gt; Ở phần ""DANH BẠ"" sẽ hiển thị to&agrave;n bộ bạn b&egrave; của bạn đang sử dụng V&iacute; GooPay v&agrave; bạn c&oacute; thể chuyển tiền ngay đến họ.</p></ol>\n' +
				     '<ol start="3">\n' +
				     '<p><strong>&nbsp;2.&nbsp;L&agrave;m sao để biết bạn b&egrave; t&ocirc;i c&oacute; GooPay hay kh&ocirc;ng để chuyển tiền?</strong></p>\n' +
				     '<p>Khi r&uacute;t tiền từ GooPay về t&agrave;i khoản Ng&acirc;n h&agrave;ng li&ecirc;n kết, bạn sẽ được MIỄN PH&Iacute; r&uacute;t tiền cho 10 GIAO DỊCH R&Uacute;T TIỀN ĐẦU TI&Ecirc;N TRONG TH&Aacute;NG, tối đa đến 50 triệu đồng/th&aacute;ng.</p>\n' +
				     '<p>Với giao dịch R&uacute;t tiền từ lần thứ 11 (hoặc hết hạn mức) sẽ t&iacute;nh mức ph&iacute; 3.300Đ + 0,65% x Gi&aacute; trị giao dịch.</p>\n' +
				     '<p><strong>3.&nbsp;T&ocirc;i c&oacute; lấy lại tiền cho c&aacute;c giao dịch chuyển tiền th&agrave;nh c&ocirc;ng được kh&ocirc;ng?</strong>&nbsp;</p>\n' +
				     '<p>Trường hợp bạn chuyển tiền đến V&iacute; GooPay, bạn c&oacute; thể trao đổi trực tiếp với Người nhận để chuyển lại tiền, bằng c&aacute;ch nhấn ""Tr&ograve; chuyện"" ở m&agrave;n h&igrave;nh Chi tiết giao dịch tr&ecirc;n ứng dụng GooPay.</p>\n' +
				     '<p>Với vai tr&ograve; l&agrave; Trung gian thanh to&aacute;n, GooPay cần đảm bảo an to&agrave;n v&agrave; bảo mật th&ocirc;ng tin cho Kh&aacute;ch h&agrave;ng, v&igrave; vậy kh&ocirc;ng thể t&aacute;c động hủy hoặc thay đổi nội dung giao dịch thực hiện bởi Kh&aacute;ch h&agrave;ng.</p>\n' +
				     '<p><strong><strong>4. Kh&ocirc;ng c&oacute; nguồn tiền trong GooPay, t&ocirc;i c&oacute; thể chuyển tiền được kh&ocirc;ng?</strong></strong></p>\n' +
				     '<p>Kh&ocirc;ng c&oacute; nguồn tiền trong GooPay, bạn vẫn c&oacute; thể thực hiện chuyển tiền qua điện thoại trực tiếp từ c&aacute;c nguồn tiền kh&aacute;c:</p>\n' +
				     '<ol>\n' +
				     '<li style="font-weight: normal">Nguồn tiền từ t&agrave;i khoản ng&acirc;n h&agrave;ng đang li&ecirc;n kết</li>\n' +
				     '<li style="font-weight: normal">Nguồn tiền thẻ ATM Napas (Kh&ocirc;ng giới hạn số thẻ li&ecirc;n kết)</li>\n' +
				     '</ol>\n' +
				     '<p><strong>5. Nếu kh&ocirc;ng c&oacute; nhu cầu sử dụng tiền trong GooPay, t&ocirc;i c&oacute; thể r&uacute;t ra kh&ocirc;ng?</strong></p>\n' +
				     '<p>C&aacute;c h&igrave;nh thức chuyển tiền tr&ecirc;n GooPay:</p>\n' +
				     '<ol>\n' +
				     '<li style="font-weight: normal">Chuyển tiền bằng số điện thoại (từ V&iacute; GooPay đến V&iacute; GooPay)</li>\n' +
				     '<li style="font-weight: normal">Chuyển tiền từ V&iacute; GooPay đến số t&agrave;i khoản/thẻ ng&acirc;n h&agrave;ng</li>\n' +
				     '<li style="font-weight: normal">Gửi y&ecirc;u cầu chuyển tiền</li>\n' +
				     '<li style="font-weight: normal">Gửi y&ecirc;u cầu chia tiền nh&oacute;m</li>\n' +
				     '<li style="font-weight: normal">Qu&eacute;t m&atilde; chuyển tiền</li>\n' +
				     '<li style="font-weight: normal">Gởi link y&ecirc;u cầu Chuyển tiền&nbsp;</li>\n' +
				     '</ol>\n' +
				     '<p><strong><strong>6. GooPay hiện đang c&oacute; c&aacute;c h&igrave;nh thức chuyển tiền n&agrave;o?</strong></strong></p>\n' +
				     '<p>Bạn vẫn c&oacute; thể chuyển tiền qua điện thoại cho người chưa c&oacute; t&agrave;i khoản GooPay.</p>\n' +
				     '<p>L&uacute;c n&agrave;y, người nhận tiền sẽ nhận được th&ocirc;ng b&aacute;o qua tin nhắn SMS k&egrave;m đường dẫn tải ứng dụng GooPay. Trong v&ograve;ng 48 giờ, người nhận chỉ cần tải v&agrave; đăng k&yacute; t&agrave;i khoản GooPay l&agrave; c&oacute; thể nhận tiền.</p>\n' +
				     '<p>Trường hợp qu&aacute; 48 giờ, người được nhận tiền chưa kịp đăng k&yacute; t&agrave;i khoản GooPay, số tiền đ&atilde; chuyển sẽ tự động được ho&agrave;n trả v&agrave;o t&agrave;i khoản GooPay của bạn.</p>\n' +
				     '<p><strong><strong>7.T&ocirc;i c&oacute; thể chuyển tiền cho người chưa c&oacute; t&agrave;i khoản GooPay kh&ocirc;ng?</strong></strong></p>\n' +
				     '<p>Ngay sau khi bạn ho&agrave;n tất giao dịch chuyển tiền qua điện thoại, người nhận sẽ nhận được tiền trong t&agrave;i khoản GooPay của m&igrave;nh k&egrave;m với tin nhắn th&ocirc;ng b&aacute;o trong ứng dụng. Bạn h&atilde;y nhắc người nhận mở ứng dụng GooPay để kiểm tra số dư t&agrave;i khoản hoặc tin nhắn trong quả chu&ocirc;ng ở g&oacute;c phải b&ecirc;n tr&ecirc;n m&agrave;n h&igrave;nh ch&iacute;nh.</p>\n' +
				     '<p>Trường hợp người nhận chưa c&oacute; t&agrave;i khoản GooPay, bạn h&atilde;y nhắc người nhận kiểm tra tin nhắn SMS. Trong v&ograve;ng 48 giờ, nếu người nhận kh&ocirc;ng đăng k&yacute; t&agrave;i khoản GooPay, bạn sẽ nhận được tin nhắn SMS th&ocirc;ng b&aacute;o l&agrave; số tiền đ&atilde; được ho&agrave;n trả v&agrave;o t&agrave;i khoản của bạn.</p>\n' +
				     '<p><strong><strong>8. Sau bao l&acirc;u người nhận sẽ nhận được tiền?</strong></strong></p>\n' +
				     '<p>Ngay sau khi bạn hoàn tất giao dịch chuyển tiền qua điện thoại, người nhận sẽ nhận được tiền trong tài khoản GooPay của mình kèm với tin nhắn thông báo trong ứng dụng. Bạn hãy nhắc người nhận mở ứng dụng GooPay để kiểm tra số dư tài khoản hoặc tin nhắn trong quả chuông ở góc phải bên trên màn hình chính.</p>\n' +
				     '<p>Trường hợp người nhận chưa có tài khoản GooPay, bạn hãy nhắc người nhận kiểm tra tin nhắn SMS. Trong vòng 48 giờ, nếu người nhận không đăng ký tài khoản GooPay, bạn sẽ nhận được tin nhắn SMS thông báo là số tiền đã được hoàn trả vào tài khoản của bạn.</p>\n' +
				     '<p><strong><strong>9. Sử dụng GooPay chuyển tiền c&oacute; an to&agrave;n kh&ocirc;ng?</strong></strong></p>\n' +
				     '<p>Công nghệ bảo mật của GooPay đáp ứng các tiêu chuẩn quốc tế trong ngành tài chính ngân hàng. Vì vậy, bạn có thể chuyển tiền an toàn đến bạn bè, người thân xung quanh.</p>\n' +
				     '<p>GooPay khuyến cáo bạn không truy cập vào các đường link lạ và TUYỆT ĐỐI không cung cấp mật khẩu, OTP (mã xác thực) cho bất kỳ ai.</p>\n' +
				     '<p><strong><strong>10. Số tiền tối thiểu c&oacute; thể chuyển qua GooPay l&agrave; bao nhi&ecirc;u?</strong></strong></p>\n' +
				     '<p>Bạn c&oacute; thể chuyển được số tiền nhỏ nhất, từ 100 đồng.&nbsp;</p>\n' +
				     '<p>Đặc biệt, bạn c&oacute; thể chuyển từng đồng tiền lẻ v&iacute; dụ: 888 đồng, 999 đồng hoặc 123 đồng.&nbsp;</p>\n' +
				     '<p><strong><strong>11. T&ocirc;i cần l&agrave;m g&igrave; nếu chuyển nhầm đến V&iacute; GooPay kh&ocirc;ng c&ograve;n sử dụng?</strong></strong></p>\n' +
				     '<p>Bạn c&oacute; thể trao đổi trực tiếp với Người nhận để tạo V&iacute; GooPay mới, bằng c&aacute;ch nhấn "Tr&ograve; chuyện" ở m&agrave;n h&igrave;nh Chi tiết giao dịch tr&ecirc;n ứng dụng GooPay.</p>\n' +
				     '<p>Bạn c&oacute; thể hướng dẫn Người nhận để tạo V&iacute; mới, sau đ&oacute; li&ecirc;n hệ lại GooPay để GooPay hỗ trợ người nhận điều chuyển số tiền từ V&iacute; cũ sang V&iacute; mới bạn nh&eacute;.&nbsp;</p>\n' +
				     '<p>Với vai tr&ograve; l&agrave; Trung gian thanh to&aacute;n, GooPay cần đảm bảo an to&agrave;n v&agrave; bảo mật th&ocirc;ng tin cho Kh&aacute;ch h&agrave;ng, v&igrave; vậy kh&ocirc;ng thể t&aacute;c động hủy hoặc thay đổi nội dung giao dịch thực hiện bởi Kh&aacute;ch h&agrave;ng.</p>\n' +
				     '<p><strong><strong>12. T&ocirc;i c&oacute; thể chuyển tiền qua GooPay v&agrave;o thời gian n&agrave;o?</strong></strong></p>\n' +
				     '<p>Bạn c&oacute; thể sử dụng t&iacute;nh năng chuyển tiền qua điện thoại tr&ecirc;n GooPay v&agrave;o bất cứ l&uacute;c n&agrave;o (tất cả c&aacute;c ng&agrave;y trong tuần, kể cả ng&agrave;y cuối tuần, lễ, Tết). Thời gian chuyển tiền 24/7.</p>\n' +
				     '<p><strong><strong>13. Nếu chưa x&aacute;c thực t&agrave;i khoản GooPay v&agrave; chưa li&ecirc;n kết ng&acirc;n h&agrave;ng th&igrave; t&ocirc;i c&oacute; thể thực hiện giao dịch chuyển tiền kh&ocirc;ng?</strong></strong></p>\n' +
				     '<p>Trường hợp này bạn không thể thực hiện giao dịch được bằng nguồn tiền trên Ví GooPay. Tuy nhiên, bạn vẫn có thể giao dịch thanh toán trực tiếp bằng cách chọn nguồn tiền thanh toán thẻ ATM nội địa qua cổng thanh toán Napas.</p>\n' +
				     '<p>Các hình thức trên chỉ hỗ trợ một số dịch vụ nhất định, không áp dụng cho giao dịch chuyển tiền nên bạn hãy nhanh chóng xác thực tài khoản GooPay và liên kết tài khoản ngân hàng để sử dụng tất cả các dịch vụ trên GooPay.</p>\n' +
				     '<p><strong><strong>14. T&ocirc;i c&oacute; bị giới hạn số tiền được nhận từ kh&aacute;ch h&agrave;ng/bạn b&egrave; về V&iacute; GooPay của m&igrave;nh mỗi ng&agrave;y kh&ocirc;ng?</strong></strong></p>\n' +
				     '<p>Có. Theo quy định, hạn mức nhận tiền tối đa là 50.000.000đ/ngày. Hạn mức này áp dụng cho tất cả tài khoản Ví GooPay gồm: tài khoản đã xác thực, tài khoản chưa xác thực.</p>\n' +
				     '<p><strong><strong>15. Khi chuyển tiền, nếu gặp sự cố v&agrave; người nhận kh&ocirc;ng nhận được t&ocirc;i phải l&agrave;m sao?</strong></strong></p>\n' +
				     '<p>Nếu giao dịch b&aacute;o lỗi v&agrave; người nhận kh&ocirc;ng nhận được khi chuyển tiền qua điện thoại, bạn c&oacute; thể gửi hỗ trợ GooPay theo 2 c&aacute;ch dưới đ&acirc;y:&nbsp;</p>\n' +
				     '<p>C&aacute;ch 1:&nbsp;</p>\n' +
				     '<p>Tại m&agrave;n h&igrave;nh ch&iacute;nh chọn "Lịch sử giao dịch" &gt;&gt; Chọn giao dịch bạn cần hỗ trợ&nbsp;</p>\n' +
				     '<p>Tại m&agrave;n h&igrave;nh Chi tiết giao dịch chọn "Li&ecirc;n hệ hỗ trợ" &gt;&gt; L&agrave;m theo hướng dẫn để gửi y&ecirc;u cầu trợ gi&uacute;p về cho GooPay&nbsp;</p>\n' +
				     '<p>C&aacute;ch 2: Gửi email đến địa chỉ <u style="color: #0073DD">cskh@goopay.vn</u> hoặc gọi đến CSKH của GooPay theo số hotline: 19006038 (1.000đ/ph&uacute;t)</p>\n' +
				     '<p><strong><strong>16. V&igrave; sao t&agrave;i khoản đ&atilde; bị trừ tiền nhưng t&agrave;i khoản ng&acirc;n h&agrave;ng người nhận chưa nhận được?</strong></strong></p>\n' +
				     '<p>Chuyển tiền đến Ng&acirc;n h&agrave;ng l&agrave; g&igrave;?</p>\n' +
				     '<p>Chuyển tiền đến Ng&acirc;n h&agrave;ng l&agrave; giao dịch bạn sử dụng ứng dụng GooPay để thực hiện chuyển tiền đến thẻ/t&agrave;i khoản Ng&acirc;n h&agrave;ng của bạn, người th&acirc;n hoặc bạn b&egrave; một c&aacute;ch dễ d&agrave;ng bằng 1 trong c&aacute;c h&igrave;nh thức:</p>\n' +
				     '<p>1️⃣ Nhập số t&agrave;i khoản/số thẻ.</p>\n' +
				     '<p>2️⃣ Chọn t&agrave;i khoản/thẻ đ&atilde; được lưu sẵn.&nbsp;</p>\n' +
				     '<p>3️⃣ Qu&eacute;t m&atilde; QR ng&acirc;n h&agrave;ng.</p>\n' +
				     '<p>Lợi &iacute;ch chuyển tiền đến Ng&acirc;n h&agrave;ng l&agrave; g&igrave;?&nbsp;</p>\n' +
				     '<p>✔️ Hệ thống chuyển tiền nhanh Napas 24/7, cho ph&eacute;p chuyển tiền trong suốt 24h v&agrave; 7 ng&agrave;y trong tuần.</p>\n' +
				     '<p>✔️ Tiền chuyển đến ngay, kh&ocirc;ng cần chờ đợi.</p>\n' +
				     '<p>✔️ Lưu th&ocirc;ng tin giao dịch, th&ocirc;ng tin t&agrave;i khoản/số thẻ cho lần giao dịch sau.</p>\n' +
				     '<p>✔️ Mọi giao dịch chuyển tiền tr&ecirc;n GooPay đều đảm bảo an to&agrave;n, bảo mật chuẩn quốc tế.</p>\n' +
				     '<p>Chuyển đến Ng&acirc;n h&agrave;ng nhưng t&agrave;i khoản thụ hưởng chưa nhận được?</p>\n' +
				     '<p>1️. Nếu trạng th&aacute;i giao dịch &ldquo;Đang chờ xử l&yacute;&rdquo;&nbsp;</p>\n' +
				     '<p>Nguy&ecirc;n nh&acirc;n xảy ra do dữ liệu giữa GooPay v&agrave; Ng&acirc;n h&agrave;ng chưa đồng bộ kịp thời. V&igrave; thế, GooPay sẽ cần tiến h&agrave;nh thực hiện đối so&aacute;t giao dịch với hệ thống Ng&acirc;n h&agrave;ng.&nbsp;</p>\n' +
				     '<p>Thời gian dự kiến hệ thống cập nhật trạng th&aacute;i trong v&ograve;ng 1-2 ng&agrave;y l&agrave;m việc (kh&ocirc;ng t&iacute;nh ng&agrave;y thứ Bảy, Chủ Nhật v&agrave; ng&agrave;y Lễ). Thời gian c&oacute; thể k&eacute;o d&agrave;i hơn dự kiến do quy tr&igrave;nh l&agrave;m việc của từng Ng&acirc;n h&agrave;ng, ngay khi c&oacute; kết quả xử l&yacute; GooPay sẽ phản hồi bạn trong thời gian sớm nhất.</p>\n' +
				     '<p>Sau khi hệ thống cập nhật trạng th&aacute;i:</p>\n' +
				     '<p>✔️ Nếu trạng th&aacute;i "Th&agrave;nh c&ocirc;ng": T&agrave;i khoản người nhận sẽ nhận được tiền.</p>\n' +
				     '<p>✔️ Nếu trạng th&aacute;i "Thất bại": Hệ thống sẽ ho&agrave;n tiền về t&agrave;i khoản V&iacute; GooPay hoặc ho&agrave;n tiền về ng&acirc;n h&agrave;ng nếu bạn sử dụng nguồn tiền ng&acirc;n h&agrave;ng li&ecirc;n kết để chuyển tiền.&nbsp;</p>\n' +
				     '<p>Bạn c&oacute; thể theo d&otilde;i tiến độ giao dịch theo 2 c&aacute;ch sau:</p>\n' +
				     '<p>✔️ Tại m&agrave;n h&igrave;nh lịch sử giao dịch. Mục "Lịch sử giao dịch"</p>\n' +
				     '<p>✔️ GooPay sẽ th&ocirc;ng b&aacute;o kết quả cuối c&ugrave;ng th&agrave;nh c&ocirc;ng hay thất bại (h&igrave;nh quả chu&ocirc;ng kế b&ecirc;n thanh t&igrave;m kiếm tr&ecirc;n ứng dụng) chuyển tiền ở mục "Quan trọng". Bạn h&atilde;y cho ph&eacute;p GooPay gửi th&ocirc;ng b&aacute;o đến bạn để được cập nhật kết quả sớm nhất. Nếu qu&aacute; thời gian tr&ecirc;n vẫn chưa nhận được kết quả bạn vui l&ograve;ng chọn &ldquo;Trợ gi&uacute;p&rdquo; v&agrave; gửi y&ecirc;u cầu để được hỗ trợ.</p>\n' +
				     '<ol start="2">\n' +
				     '<li style="font-weight: normal">Nếu trạng th&aacute;i giao dịch&ldquo;Th&agrave;nh c&ocirc;ng&rdquo;</li>\n' +
				     '</ol>\n' +
				     '<p>Th&ocirc;ng thường, chuyển tiền trạng th&aacute;i "Th&agrave;nh c&ocirc;ng" ng&acirc;n h&agrave;ng sẽ cập nhật tiền ngay v&agrave;o t&agrave;i khoản thụ hưởng. Tuy nhi&ecirc;n, trong một v&agrave;i trường hợp t&agrave;i khoản thụ hưởng c&oacute; thể được cập nhật tiền chậm hơn hoặc người nhận kh&ocirc;ng nhận được th&ocirc;ng b&aacute;o nhận tiền như:</p>\n' +
				     '<p>✔️ Nếu th&ocirc;ng tin chuyển tiền ch&iacute;nh x&aacute;c c&oacute; thể ph&aacute;t sinh:</p>\n' +
				     '<p>1️⃣ Lỗi đường truyền hoặc bảo tr&igrave; hệ thống giữa c&aacute;c Ng&acirc;n h&agrave;ng li&ecirc;n kết.</p>\n' +
				     '<p>C&aacute;c ng&acirc;n h&agrave;ng thường xuy&ecirc;n tiến h&agrave;nh bảo tr&igrave; hệ thống nhằm bảo vệ th&ocirc;ng tin kh&aacute;ch h&agrave;ng khỏi sự tấn c&ocirc;ng của hacker. Qu&aacute; tr&igrave;nh n&agrave;y c&oacute; thể ảnh hưởng đến tiến độ gửi tin nhắn th&ocirc;ng b&aacute;o hoặc cập nhật sao k&ecirc; giao dịch chuyển tiền.</p>\n' +
				     '<p>2️⃣ Chuyển tiền v&agrave;o cuối tuần, ng&agrave;y lễ tết hoặc giờ cao điểm.</p>\n' +
				     '<p>Thời điểm giao dịch v&agrave;o ng&agrave;y lễ hoặc cuối tuần c&oacute; thể ảnh hưởng đến tiến tr&igrave;nh giao dịch. Đ&oacute; l&agrave; bởi hệ thống ng&acirc;n h&agrave;ng điện tử của một số ng&acirc;n h&agrave;ng thường hoạt động chậm v&agrave;o những dịp n&agrave;y. V&igrave; vậy, c&aacute;c giao dịch vẫn sẽ được thực hiện nhưng với tốc độ chậm hơn.</p>\n' +
				     '<p>3️⃣ Số điện thoại nhận th&ocirc;ng b&aacute;o nhận tiền đ&atilde; bị thay đổi.</p>\n' +
				     '<p>Khi người nhận thay đổi số điện thoại, sẽ kh&ocirc;ng nhận được phản hồi từ ng&acirc;n h&agrave;ng khi giao dịch chuyển tiền th&agrave;nh c&ocirc;ng. V&igrave; vậy, khi đăng k&yacute; dịch vụ SMS Banking, người nhận n&ecirc;n sử dụng số điện thoại li&ecirc;n lạc ch&iacute;nh để nhận được tin nhắn biến động số dư.&nbsp;</p>\n' +
				     '<p>4️⃣ T&ugrave;y thuộc v&agrave;o quy định nhận tiền của ng&acirc;n h&agrave;ng thụ hưởng.</p>\n' +
				     '<p>Mỗi ng&acirc;n h&agrave;ng thụ hưởng sẽ c&oacute; quy tr&igrave;nh xử l&yacute; kh&aacute;c nhau n&ecirc;n thời gian cập nhật tiền cũng sẽ ch&ecirc;nh lệch.</p>\n' +
				     '<p>💡 Bạn y&ecirc;n t&acirc;m, số tiền sẽ được cập nhật khi ổn định v&agrave; c&oacute; thể chậm nhất trong v&ograve;ng 1- 3 ng&agrave;y l&agrave;m việc (kh&ocirc;ng t&iacute;nh ng&agrave;y thứ Bảy, Chủ Nhật v&agrave; ng&agrave;y Lễ) t&ugrave;y thuộc v&agrave;o hệ thống ng&acirc;n h&agrave;ng thụ hưởng của người nhận.</p>\n' +
				     '<p>Bạn c&oacute; thể chia sẻ bi&ecirc;n lai chuyển tiền v&agrave; th&ocirc;ng b&aacute;o người nhận kiểm tra lại sao k&ecirc; t&agrave;i khoản thụ hưởng trong thời gian n&agrave;y.&nbsp;</p>\n' +
				     '<p>Mỗi ng&acirc;n h&agrave;ng thụ hưởng sẽ c&oacute; quy tr&igrave;nh xử l&yacute; kh&aacute;c nhau n&ecirc;n thời gian cập nhật tiền cũng sẽ ch&ecirc;nh lệch.</p>\n' +
				     '<p>💡 Bạn y&ecirc;n t&acirc;m, số tiền sẽ được cập nhật khi ổn định v&agrave; c&oacute; thể chậm nhất trong v&ograve;ng 1- 3 ng&agrave;y l&agrave;m việc (kh&ocirc;ng t&iacute;nh ng&agrave;y thứ Bảy, Chủ Nhật v&agrave; ng&agrave;y Lễ) t&ugrave;y thuộc v&agrave;o hệ thống ng&acirc;n h&agrave;ng thụ hưởng của người nhận.</p>\n' +
				     '<p>Bạn c&oacute; thể chia sẻ bi&ecirc;n lai chuyển tiền v&agrave; th&ocirc;ng b&aacute;o người nhận kiểm tra lại sao k&ecirc; t&agrave;i khoản thụ hưởng trong thời gian n&agrave;y.&nbsp;</p>\n' +
				     '<p>Nếu qu&aacute; thời gian tr&ecirc;n vẫn chưa nhận được vui l&ograve;ng nhấn v&agrave;o chi tiết giao dịch v&agrave; gửi y&ecirc;u trợ gi&uacute;p.</p>\n' +
				     '<p>✔️ Nếu th&ocirc;ng tin chuyển tiền sai:&nbsp;</p>\n' +
				     '<p>Người gửi nhập sai c&aacute;c th&ocirc;ng tin như số thẻ/t&agrave;i khoản hoặc chọn nhầm Ng&acirc;n h&agrave;ng, tiền vẫn bị trừ nhưng người nhận sẽ kh&ocirc;ng nhận được bất kỳ phản hồi n&agrave;o. Trong trường hợp n&agrave;y, bạn vui l&ograve;ng kiểm tra lại th&ocirc;ng tin chi tiết chuyển tiền. Khi ph&aacute;t hiện chuyển tiền sai người nhận, bạn cần nhanh ch&oacute;ng gửi y&ecirc;u cầu trợ gi&uacute;p để hỗ trợ kịp thời.&nbsp;</p>\n' +
				     '<p>Lưu &yacute;:&nbsp;</p>\n' +
				     '<p>Kết quả số tiền chuyển nhầm c&oacute; khả năng r&uacute;t lại được hay kh&ocirc;ng t&ugrave;y thuộc v&agrave;o sự đồng &yacute; của người nhận nhầm.</p>\n' +
				     '<p>Thời gian xử l&yacute; phụ thuộc v&agrave;o quy tr&igrave;nh xử l&yacute; của ng&acirc;n h&agrave;ng thụ hưởng c&oacute; thể l&ecirc;n đến 60 ng&agrave;y l&agrave;m việc (kh&ocirc;ng t&iacute;nh thứ Bảy, Chủ Nhật v&agrave; ng&agrave;y Lễ).&nbsp;</p>\n' +
				     '<ol start="3">\n' +
				     '<li>Nếu trạng th&aacute;i giao dịch &ldquo;Thất bại&rdquo;</li>\n' +
				     '</ol>\n' +
				     '<p>Đối với giao dịch trạng th&aacute;i " Thất bại" t&agrave;i khoản của bạn chắc chắn sẽ kh&ocirc;ng bị trừ tiền. Số tiền giao dịch sẽ được cập nhật bằng một trong c&aacute;c h&igrave;nh thức khi bạn sử nguồn tiền để chuyển tiền như:</p>\n' +
				     '<p>✔️ Nguồn tiền V&iacute;: Tho&aacute;t ứng dụng, đăng nhập lại để kiểm tra số dư V&iacute;.</p>\n' +
				     '<p>✔️ Nguồn tiền Ng&acirc;n h&agrave;ng: Kiểm tra lại số dư t&agrave;i khoản ng&acirc;n h&agrave;ng hoặc số dư v&iacute; GooPay.&nbsp;</p>\n' +
				     '<p>Nếu c&oacute; ph&aacute;t sinh giao dịch ho&agrave;n tiền về Ng&acirc;n h&agrave;ng nhưng t&agrave;i khoản vẫn chưa nhận được bạn vui l&ograve;ng chờ th&ecirc;m trong v&ograve;ng 1 - 2 ng&agrave;y l&agrave;m việc (kh&ocirc;ng t&iacute;nh ng&agrave;y thứ Bảy, Chủ Nhật v&agrave; ng&agrave;y Lễ) để Ng&acirc;n h&agrave;ng thực hiện đối so&aacute;t v&agrave; cập nhật. Số tiền ho&agrave;n về c&oacute; thể được hiện trong b&aacute;o c&aacute;o sao k&ecirc; t&agrave;i khoản nếu bạn kh&ocirc;ng sử dụng t&iacute;nh năng th&ocirc;ng b&aacute;o số dư - SMS banking.</p>\n' +
				     '<br/>' +
				     '<h2 style="text-align: center; color: #338FE3;"><strong>Y&ecirc;u cầu chuyển tiền</strong></h2>\n' +
				     '<br/>' +
				     '<p><strong>1. M&atilde; QR c&aacute; nh&acirc;n kh&aacute;c với M&atilde; thanh to&aacute;n như thế n&agrave;o?</strong></p>\n' +
				     '<p>M&atilde; QR c&aacute; nh&acirc;n (M&atilde; QR Của T&ocirc;i) l&agrave; m&atilde; đại diện cho t&agrave;i khoản GooPay của bạn. Bạn c&oacute; thể gửi m&atilde; QR n&agrave;y cho bất kỳ người n&agrave;o. Người nhận được sử d&ugrave;ng t&iacute;nh năng qu&eacute;t m&atilde; QR sẽ hiển thị số t&agrave;i khoản GooPay của bạn, v&agrave; c&oacute; thể chuyển tiền cho bạn một c&aacute;ch nhanh ch&oacute;ng. Bạn c&oacute; thể v&agrave;o mục ""V&iacute; Của T&ocirc;i"", sau đ&oacute; nhấp v&agrave;o t&ecirc;n của bạn để mở m&atilde; QR Của T&ocirc;i.</p>\n' +
				     '<p>M&atilde; Thanh To&aacute;n l&agrave; m&atilde; QR sử dụng cho dịch vụ Thanh To&aacute;n Tại Quầy tại c&aacute;c si&ecirc;u thị, nh&agrave; h&agrave;ng, cafe... c&oacute; chấp nhận thanh to&aacute;n GooPay. Bạn c&oacute; thể nhấp v&agrave;o ""M&atilde; thanh to&aacute;n"" tại m&agrave;n h&igrave;nh ch&iacute;nh để mở m&atilde; QR n&agrave;y rồi cho thu ng&acirc;n để được thanh to&aacute;n.</p>\n' +
				     '<p><strong>2. C&aacute;ch y&ecirc;u cầu người kh&aacute;c chuyển tiền cho m&igrave;nh bằng GooPay</strong></p>\n' +
				     '<p>T&iacute;nh năng ""Y&ecirc;u cầu chuyển tiền"" của GooPay cho ph&eacute;p bạn gửi y&ecirc;u cầu cho người m&agrave; bạn muốn ""đ&ograve;i nợ"" hoặc mượn tiền, gi&uacute;p người đ&oacute; c&oacute; thể dễ d&agrave;ng chuyển tiền cho bạn. C&aacute;ch thực hiện:</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng, chọn t&iacute;nh năng ""Y&ecirc;u cầu chuyển tiền""</p>\n' +
				     '<p>Bước 2: Chọn t&ecirc;n người được y&ecirc;u cầu trong danh bạ hoặc nhập số điện thoại của người đ&oacute;</p>\n' +
				     '<p>Bước 3: Nhập số tiền được y&ecirc;u cầu (bắt buộc) v&agrave; lời nhắn đi k&egrave;m (kh&ocirc;ng bắt buộc)</p>\n' +
				     '<p>Bước 4: Kiểm tra th&ocirc;ng tin v&agrave; chọn ""X&aacute;c nhận"".</p>\n' +
				     '<p>Sau khi bạn ho&agrave;n tất, người được y&ecirc;u cầu chuyển tiền sẽ nhận được th&ocirc;ng b&aacute;o tr&ecirc;n ứng dụng GooPay của người đ&oacute;. Người đ&oacute; chỉ cần bấm x&aacute;c nhận chuyển tiền l&agrave; lệnh chuyển tiền lập tức được thực hiện.</p>\n' +
				     '<p><strong>3. C&aacute;ch gửi m&atilde; QR c&aacute; nh&acirc;n để người kh&aacute;c chuyển tiền cho m&igrave;nh</strong></p>\n' +
				     '<p>Việc gửi m&atilde; QR c&aacute; nh&acirc;n gi&uacute;p người kh&aacute;c c&oacute; thể dễ d&agrave;ng chuyển tiền cho bạn chỉ với 1 thao t&aacute;c qu&eacute;t m&atilde; QR tr&ecirc;n GooPay.</p>\n' +
				     '<p>Để gửi m&atilde; QR c&aacute; nh&acirc;n, bạn thực hiện c&aacute;c bước sau:</p>\n' +
				     '<p>Bước 1: Từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng chọn ""V&iacute; của t&ocirc;i""</p>\n' +
				     '<p>Bước 2: Nhấp v&agrave;o t&ecirc;n của bạn để v&agrave;o mục ""Th&ocirc;ng tin c&aacute; nh&acirc;n"" rồi chọn ""M&atilde; QR của t&ocirc;i""</p>\n' +
				     '<p>Bước 3: Chạm v&agrave;o m&atilde; QR để hiển thị c&aacute;c t&ugrave;y chọn:</p>\n' +
				     '<p>- Chia sẻ m&atilde; QR: gửi m&atilde; QR cho người kh&aacute;c</p>\n' +
				     '<p>- Lưu m&atilde; QR: lưu h&igrave;nh ảnh của m&atilde; QR trong điện thoại rồi gửi h&igrave;nh cho người kh&aacute;c</p>\n' +
				     '<p>Sau khi nhận được m&atilde; QR của bạn, người gửi chỉ cần bật t&iacute;nh năng qu&eacute;t m&atilde; từ m&agrave;n h&igrave;nh ch&iacute;nh của ứng dụng GooPay, qu&eacute;t m&atilde; v&agrave; x&aacute;c nhận lệnh chuyển tiền.</p>\n'
		     }}
		/>
	</div>
);

export default FAQTransfer;
