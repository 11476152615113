import React, {useEffect, useMemo, useRef, useState} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";


const tabData = (index) => ([
  {
    src: `/img/guild-step/tab${index}-step1.png`,
    alt: `tab${index}-step${index}`
  },
  {
    src: `/img/guild-step/tab${index}-step2.png`,
    alt: `tab${index}-step${index+1}`
  },
  {
    src: `/img/guild-step/tab${index}-step3.png`,
    alt: `tab${index}-step${index+2}`
  },
  {
    src: `/img/guild-step/tab${index}-step4.png`,
    alt: `tab${index}-step${index+3}`
  },
  {
    src: `/img/guild-step/tab${index}-step5.png`,
    alt: `tab${index}-step${index + 4}`
  }
])



const PhoneSwiper = ({activeTab, step, tabsData}) => {
  const [data, setData] = useState(null);
  const swiperRef = useRef(null);
  const dataTabSteps = useMemo(()=>{
    if (tabsData) return tabsData
    return tabData
  },[tabsData]);
  useEffect(() => {
    setData(dataTabSteps(activeTab+1));
    swiperRef.current.swiper.update();
    swiperRef.current.swiper.slideTo(0);
  }, [activeTab]);
  
  useEffect(() => {
    console.log(step);
    swiperRef.current.swiper.slideTo(step-1);
  }, [step]);

  return (
    <div className="relative flex items-center justify-center w-[255px] h-[526px] bg-cover bg-center">
      <img className='absolute' src="/img/phone.png" alt="phone" />
      <img className='absolute z-[2] top-3' src="/img/phone-island.png" alt="phone-island" />
      <div className="w-[242px] h-[516px] overflow-hidden rounded-[32px]">
        <Swiper
          ref={swiperRef}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow]}
          className="w-full h-full"
        >
          {(data||[]).map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.src}
                alt={image.alt}
                className="w-full h-full object-cover"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default PhoneSwiper;