import React from "react";
import cn from 'classnames';
import Button from '../Common/Button';
import {PATH, VARIANT} from '../../constants';
import NewLink from "../NewLink/NewLink";

const RecipeCard = ({image, imageMb, title, description, order}) => (
	<div
		className="relative w-[360px] md:w-[306px] h-[380px] md:h-[490px] rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105">
		<picture>
			<source srcSet={imageMb} media="(max-width: 768px)"/>
			<img src={image} alt={title} className="w-full"/>
		</picture>
		<div className="absolute inset-0 from-black/50 to-black/70">
			<div className={cn('px-6 py-4 absolute', order % 2 !== 0 ? 'md:bottom-0' : 'md:top-24 top-4')}>
				<p className="text-xl font-bold mb-2 text-center">{title}</p>
				<div>
					<p className="text-sm mb-4 text-center">{description}</p>
				</div>
			</div>
		</div>
	</div>
);

const RecipeCardList = () => {
	const recipes = [
		{
			image: "/img/frame1.png",
			imageMb: "/img/frame1-mb.png",
			title: "Đặt vé xe liên tỉnh",
			description: "Dễ dàng, tiện lợi hơn khi đặt vé xe liên tỉnh ngay trong ứng dụng, tích điểm mọi chuyến đi, mở bán vé xe tết/ lễ sớm",
		},
		{
			image: "/img/frame2.png",
			imageMb: "/img/frame2-mb.png",
			title: "Vận chuyển hàng hoá",
			description: "Thanh toán dịch vụ Giao hàng - Vận chuyển một cách nhanh chóng với giá rẻ bất ngờ",
		},
		{
			image: "/img/frame3.png",
			imageMb: "/img/frame3-mb.png",
			title: "Chuyển tiền - Nhận tiền",
			description: "Chuyển/ Nhận tiền nhanh miễn phí 24/7, an toàn, bảo mật",
		},
		{
			image: "/img/frame4.png",
			imageMb: "/img/frame4-mb.png",
			title: "Nạp/ Mua thẻ điện thoại",
			description: "Đa dạng nhà mạng, chiết khấu hấp dẫn",
		}
	];
	
	return (
		<div className="md:px-[120px] py-8 text-center">
			<h2 className="text-3xl font-bold text-center mb-8 uppercase">GOOPAY - VÍ ĐIỆN TỬ NGÀNH VẬN TẢI</h2>
			<div className="flex flex-wrap justify-center gap-[10px] mb-2">
				{recipes.map((recipe, index) => (
					<RecipeCard key={index} {...recipe} order={index}/>
				))}
			</div>
			<NewLink to={PATH.SERVICES}>
				<Button variant={VARIANT.PRIMARY}>
					Xem chi tiết các dịch vụ
				</Button>
			</NewLink>
		</div>
	);
};

export default RecipeCardList;