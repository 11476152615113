// src/components/Home.js
import React, {useEffect} from 'react';
import ServiceBanner from '../components/Banner/ServiceBanner';
import InfoImageBlock from '../components/Common/InfoImageBlock';
import {handleScrollToHash} from "../services/services";
import {useLocation} from "react-router-dom";

const Services = () => {
	const location = useLocation();
	const { hash} = location || {};
	
	useEffect(() => {
		handleScrollToHash({sectionId: hash.substring(1)});
	}, [hash]);
	
	return (
		<div className="mx-auto w-full animate-fadeIn">
			<ServiceBanner />
			<InfoImageBlock
				id='service-1'
				icon='/img/icon/bus.png'
				title='Đặt vé xe liên tỉnh'
				position='right'
				bgClass='bg-white'
				textClass='md:w-[500px]'
				description={
					`<p>Dễ dàng, tiện lợi hơn khi đặt vé xe liên tỉnh ngay trong ứng dụng:<p/>
				<p class="dot-before">Thao tác dễ dàng, thuận tiện hơn</p>
				<p class="dot-before">Tích điểm mọi chuyến đi</p>
				<p class="dot-before">Mở bán vé xe Tết/ Lễ sớm</p>`
				}
				image='/img/services/sv1.png'
			/>
			<InfoImageBlock
				id='service-2'
				icon='/img/icon/van.png'
				title='Vận chuyển hàng hoá'
				textClass='md:w-[500px]'
				description={
					`<p>Thanh toán dịch vụ Giao hàng - Vận chuyển một cách nhanh chóng với ưu đãi và đặc quyền đặc biệt.</p>
			<p>Vận chuyển đa dạng các loại hàng hóa: từ bưu phẩm, bưu kiện, thư tín, nông sản, thực phẩm, đến xe máy, xe  đạp,... Đặc biệt là dịch vụ vận chuyển hàng hóa theo hợp đồng.</p>`
				}
				image='/img/services/sv2.png'
			/>
			<InfoImageBlock
				id='service-3'
				position='right'
				bgClass='bg-white'
				icon='/img/icon/transfer-money.png'
				title='Chuyển tiền - Nhận tiền nhanh 24/7'
				textClass='md:w-[500px]'
				description={
					`<p>Thoải mái giao dịch, thanh toán, chuyển tiền mọi lúc mọi nơi.<p/>
				<p>An toàn, bảo mật theo tiêu chuẩn quốc tế về bảo mật thông tin<p/>`
				}
				image='/img/services/sv3.png'
			/>
			<InfoImageBlock
				id='service-4'
				icon='/img/icon/mobile-pay.png'
				title='Nạp/ Mua thẻ điện thoại'
				textClass='md:w-[500px]'
				description={
					`<p>Chiết khấu hấp dẫn từ các nhà mạng</p>
				<p class="dot-before">Mua mã thẻ di động
				<p class="dot-before">Nạp tiền điện thoại, mua thẻ data 3G, 4G, 5G
				<p class="dot-before">Phương thức thanh toán không dùng tiền mặt`}
				image='/img/services/sv4.png'
			/>
		</div>
	);
}

export default Services;