// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';

const PolicyFutaBusline = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='Điều khoản và Chính sách
			Mua Vé FUTA Bus Lines'/>
		<h2 className='text-center pt-6 font-bold text-xl uppercase text-primary w-[80%] mx-auto'>Điều khoản và Chính sách
			Mua Vé FUTA Bus Lines</h2>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<br/><h1 style="font-size:16px; font-weight: bold;">I. ĐIỀU KHOẢN V&Agrave; QUY ĐỊNH CHUNG</h1>\n' +
				     '<br/><h2 style="font-size:16px; font-weight: bold;">Điều 1. Giải th&iacute;ch từ ngữ v&agrave; từ viết tắt</h2>\n' +
				     '<ol>\n' +
				     '<li>&ldquo;Biểu ph&iacute;&rdquo;: l&agrave; c&aacute;c loại ph&iacute; thuế theo quy định của H&atilde;ng vận tải v&agrave; Nh&agrave; chức tr&aacute;ch;</li>\n' +
				     '<li>&ldquo;Bến xe &ocirc; t&ocirc; kh&aacute;ch&rdquo;: l&agrave; c&ocirc;ng tr&igrave;nh thuộc kết cấu hạ tầng giao th&ocirc;ng đường bộ thực hiện chức năng phục vụ xe &ocirc; t&ocirc; đ&oacute;n, trả h&agrave;nh kh&aacute;ch v&agrave; c&aacute;c dịch vụ hỗ trợ vận tải h&agrave;nh kh&aacute;ch;</li>\n' +
				     '<li>&ldquo;Ch&uacute;ng t&ocirc;i&rdquo;: l&agrave; C&ocirc;ng Ty Cổ Phần Xe Kh&aacute;ch Phương Trang FUTA Bus Lines;</li>\n' +
				     '<li>&ldquo;Điểm đ&oacute;n/trả&rdquo;: l&agrave; điểm khởi h&agrave;nh v&agrave; điểm đến theo lịch trong h&agrave;nh tr&igrave;nh của kh&aacute;ch h&agrave;ng;</li>\n' +
				     '<li>&ldquo;Điều kiện bất khả kh&aacute;ng&rdquo;: l&agrave; sự kiện xảy ra mang t&iacute;nh kh&aacute;ch quan v&agrave; nằm ngo&agrave;i tầm kiểm so&aacute;t của c&aacute;c b&ecirc;n bao gồm, nhưng kh&ocirc;ng giới hạn đến động đất, b&atilde;o, lũ lụt, lốc, s&oacute;ng thần, lở đất, hỏa hoạn, chiến tranh hoặc c&oacute; nguy cơ xảy ra chiến tranh, bạo động, nổi loạn, đ&igrave;nh c&ocirc;ng&hellip; v&agrave; c&aacute;c thảm họa kh&aacute;c chưa lường hết được, sự thay đổi ch&iacute;nh s&aacute;ch hoặc ngăn cấm của cơ quan c&oacute; thẩm quyền của Việt Nam;</li>\n' +
				     '<li>&ldquo;Điều kiện vận chuyển&rdquo;: l&agrave; c&aacute;c y&ecirc;u cầu, nội dung của FUTA th&ocirc;ng b&aacute;o đến h&agrave;nh kh&aacute;ch li&ecirc;n quan đến c&aacute;c dịch vụ vận chuyển, bao gồm c&aacute;c th&ocirc;ng tin được thể hiện tr&ecirc;n v&eacute;/phiếu x&aacute;c nhận h&agrave;nh tr&igrave;nh v&agrave;/hoặc tr&ecirc;n website, ứng dụng v&agrave;/hoặc trong điều khoản sử dụng đ&atilde; được ph&ecirc; duyệt v&agrave;/hoặc c&aacute;c h&igrave;nh thức c&ocirc;ng bố kh&aacute;c;</li>\n' +
				     '<li>&ldquo;H&agrave;nh kh&aacute;ch&rdquo;: l&agrave; bất kỳ c&aacute; nh&acirc;n n&agrave;o sử dụng dịch vụ của FUTA;</li>\n' +
				     '<li>&ldquo;H&agrave;nh l&yacute;&rdquo;: l&agrave; những vật phẩm, đồ d&ugrave;ng tư trang v&agrave; t&agrave;i sản c&aacute; nh&acirc;n của h&agrave;nh kh&aacute;ch mang theo, sử dụng trong chuyến đi của m&igrave;nh, trừ khi được quy định kh&aacute;c đi;</li>\n' +
				     '<li>&ldquo;Hợp đồng vận chuyển&rdquo;: l&agrave; c&aacute;c thỏa thuận của H&atilde;ng vận chuyển v&agrave; h&agrave;nh kh&aacute;ch trong việc cung cấp c&aacute;c dịch vụ theo nhu cầu của h&agrave;nh kh&aacute;ch được thể hiện bằng v&eacute; hoặc h&igrave;nh thức kh&aacute;c c&oacute; gi&aacute; trị tương đương với v&eacute;;</li>\n' +
				     '<li>&ldquo;H&oacute;a đơn điện tử&rdquo;: h&oacute;a đơn c&oacute; m&atilde; hoặc kh&ocirc;ng c&oacute; m&atilde; của cơ quan thuế được thể hiện ở dạng dữ liệu điện tử do FUTA cung cấp dịch vụ, lập bằng phương tiện điện tử để ghi nhận th&ocirc;ng tin, cung cấp dịch vụ theo quy định của ph&aacute;p luật về kế to&aacute;n, ph&aacute;p luật về thuế;</li>\n' +
				     '<li>&ldquo;M&atilde; tra cứu&rdquo;: l&agrave; m&atilde; số bao gồm 9 k&yacute; tự được hiển thị trong th&ocirc;ng tin mua v&eacute;, d&ugrave;ng cho việc tra cứu h&oacute;a đơn điện tử sau khi kh&aacute;ch h&agrave;ng thanh to&aacute;n th&agrave;nh c&ocirc;ng;</li>\n' +
				     '<li>&ldquo;M&atilde; đặt v&eacute;&rdquo;: l&agrave; c&aacute;c th&ocirc;ng tin chi tiết của H&agrave;nh kh&aacute;ch đ&atilde; được nhập v&agrave;o hệ thống đặt giữ chỗ của Ch&uacute;ng t&ocirc;i th&ocirc;ng qua website/hoặc ứng dụng FUTA, Ph&ograve;ng v&eacute;, Đại l&yacute;, Tổng đ&agrave;i;</li>\n' +
				     '<li>&ldquo;Ng&agrave;y&rdquo;: c&oacute; nghĩa l&agrave; c&aacute;c ng&agrave;y dương lịch, bao gồm bảy (07) ng&agrave;y trong tuần; với điều kiện l&agrave; khi d&ugrave;ng trong việc gửi th&ocirc;ng b&aacute;o th&igrave; ng&agrave;y gửi th&ocirc;ng b&aacute;o đi kh&ocirc;ng được t&iacute;nh v&agrave; khi d&ugrave;ng cho mục đ&iacute;ch x&aacute;c định;</li>\n' +
				     '<li>&ldquo;Th&ocirc;ng tin c&aacute; nh&acirc;n&rdquo;: H&agrave;nh kh&aacute;ch chấp nhận rằng th&ocirc;ng tin c&aacute; nh&acirc;n của h&agrave;nh kh&aacute;ch được chuyển cho FUTA v&igrave; mục đ&iacute;ch sử dụng dịch vụ do FUTA cung cấp. V&igrave; mục đ&iacute;ch tr&ecirc;n, h&agrave;nh kh&aacute;ch đồng &yacute; cho ph&eacute;p FUTA lưu trữ v&agrave; sử dụng c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n v&agrave; chuyển tải c&aacute;c th&ocirc;ng tin đ&oacute; tới h&atilde;ng vận chuyển, c&aacute;c đại diện ủy quyền ph&aacute;t h&agrave;nh v&eacute;, cơ quan nh&agrave; nước c&oacute; thẩm quyền, hoặc c&aacute;c nh&agrave; cung cấp dịch vụ li&ecirc;n quan kh&aacute;c;</li>\n' +
				     '<li>&ldquo;Thẻ l&ecirc;n xe (v&eacute;)&rdquo;: l&agrave; bằng chứng x&aacute;c nhận hợp đồng vận chuyển h&agrave;nh kh&aacute;ch v&agrave; FUTA Bus Lines. C&oacute; gi&aacute; trị đối với h&agrave;nh kh&aacute;ch c&oacute; t&ecirc;n v&agrave; h&agrave;nh tr&igrave;nh được ghi r&otilde; trong v&eacute;. V&eacute; c&oacute; thể được chuyển, hủy theo quy định của FUTA Bus Lines.</li>\n' +
				     '<li>&ldquo;ZNS&rdquo;: l&agrave; dịch vụ gửi th&ocirc;ng b&aacute;o chăm s&oacute;c kh&aacute;ch h&agrave;ng tr&ecirc;n Zalo.</li>\n' +
				     '<li>Đề mục hoặc ti&ecirc;u đề của mỗi điều của Điều lệ vận chuyển n&agrave;y chỉ được sử dụng cho mục đ&iacute;ch thuận tiện tra cứu v&agrave; kh&ocirc;ng được sử dụng để giải th&iacute;ch nội dung của điều khoản đ&oacute;.</li>\n' +
				     '</ol>\n' +
				     '<br/><h2 style="font-size:16px; font-weight: bold;">Điều 2. Quy định đặt v&eacute; trực tuyến</h2>\n' +
				     '<ol>\n' +
				     '<li>Phạm vi &aacute;p dụng: Chương tr&igrave;nh thanh to&aacute;n online được &aacute;p dụng cho c&aacute;c chuyến xe nhất định của FUTA Bus Lines. Th&agrave;nh vi&ecirc;n của FUTA Bus Lines cũng như kh&aacute;ch v&atilde;ng lai thực hiện được h&igrave;nh thức thanh to&aacute;n n&agrave;y. Việc đăng k&yacute; tham gia Th&agrave;nh vi&ecirc;n FUTA Bus Lines l&agrave; ho&agrave;n to&agrave;n miễn ph&iacute;.</li>\n' +
				     '<li>Đặt chỗ</li>\n' +
				     '<li>a) Qu&yacute; kh&aacute;ch kiểm tra cẩn thận c&aacute;c th&ocirc;ng tin v&eacute; trước khi tiến h&agrave;nh x&aacute;c nhận đặt v&eacute; v&agrave; thanh to&aacute;n. Bằng việc thanh to&aacute;n qua website n&agrave;y, Qu&yacute; kh&aacute;ch chấp nhận giờ khởi h&agrave;nh, vị tr&iacute; ghế ngồi v.v m&agrave; Qu&yacute; kh&aacute;ch đ&atilde; đặt. Qu&yacute; kh&aacute;ch đồng &yacute; rằng, trong những trường hợp c&oacute; sự thay đổi về chuyến đi hoặc bất khả kh&aacute;ng, ch&uacute;ng t&ocirc;i c&oacute; quyền ho&agrave;n trả lại bất kỳ v&eacute; n&agrave;o từ việc mua b&aacute;n qua website n&agrave;y hoặc thực hiện việc chuyển v&eacute; cho Qu&yacute; kh&aacute;ch qua chuyến kh&aacute;c theo y&ecirc;u cầu của Qu&yacute; kh&aacute;ch trong trường hợp ch&uacute;ng t&ocirc;i c&ograve;n chỗ;</li>\n' +
				     '<li>b) Đặt chỗ chỉ được x&aacute;c nhận sau khi việc thanh to&aacute;n tiền v&eacute; đ&atilde; ho&agrave;n tất đồng thời FUTA cung cấp cho H&agrave;nh kh&aacute;ch M&atilde; đặt v&eacute; x&aacute;c định t&ecirc;n H&agrave;nh kh&aacute;ch, h&agrave;nh tr&igrave;nh, giờ khởi h&agrave;nh, số ghế, gi&aacute; v&eacute;;</li>\n' +
				     '<li>c) Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm về bất kỳ tổn thất n&agrave;o m&agrave; H&agrave;nh kh&aacute;ch c&oacute; thể phải chịu từ việc đặt chỗ th&ocirc;ng qua bất kỳ tổ chức/c&aacute; nh&acirc;n n&agrave;o kh&ocirc;ng phải l&agrave; Ch&uacute;ng t&ocirc;i hoặc B&ecirc;n thứ ba được ủy quyền của Ch&uacute;ng t&ocirc;i.</li>\n' +
				     '<li>X&aacute;c nhận thanh to&aacute;n: Sau khi ho&agrave;n th&agrave;nh việc thanh to&aacute;n v&eacute; trực tuyến, Qu&yacute; kh&aacute;ch sẽ nhận được thư x&aacute;c nhận th&ocirc;ng tin chi tiết v&eacute; đ&atilde; đặt th&ocirc;ng qua địa chỉ thư điện tử (email) m&agrave; Qu&yacute; kh&aacute;ch đ&atilde; cung cấp. Đồng thời, ch&uacute;ng t&ocirc;i cũng sẽ gửi tin nhắn (SMS) hoặc ZNS qua Zalo th&ocirc;ng b&aacute;o m&atilde; giao dịch tới số điện thoại Qu&yacute; kh&aacute;ch đăng k&yacute;.</li>\n' +
				     '</ol>\n' +
				     '<p>Lưu &yacute;:</p>\n' +
				     '<ol>\n' +
				     '<li>a) Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm trong trường hợp Qu&yacute; kh&aacute;ch nhập sai địa chỉ email, số điện thoại v&agrave; th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;c dẫn đến kh&ocirc;ng nhận được thư x&aacute;c nhận. V&igrave; vậy Qu&yacute; kh&aacute;ch vui l&ograve;ng kiểm tra lại ch&iacute;nh x&aacute;c c&aacute;c th&ocirc;ng tin trước khi thực hiện thanh to&aacute;n. Với email, SMS v&agrave; ZNS chỉ c&oacute; t&iacute;nh chất x&aacute;c nhận th&ocirc;ng tin v&eacute; sau khi Qu&yacute; kh&aacute;ch đ&atilde; đặt v&eacute; th&agrave;nh c&ocirc;ng;</li>\n' +
				     '<li>b) Ch&uacute;ng t&ocirc;i đề nghị Qu&yacute; kh&aacute;ch đọc kỹ c&aacute;c th&ocirc;ng tin về chuyến đi, giờ khởi h&agrave;nh v&agrave; chỗ ngồi v.v. trước khi ho&agrave;n tất việc x&aacute;c nhận tất cả c&aacute;c th&ocirc;ng tin về v&eacute;;</li>\n' +
				     '<li>c) Email x&aacute;c nhận th&ocirc;ng tin đặt v&eacute; c&oacute; thể đi v&agrave;o hộp thư r&aacute;c (spam mail) của Qu&yacute; kh&aacute;ch, v&igrave; vậy h&atilde;y kiểm tra trước khi li&ecirc;n lạc với ch&uacute;ng t&ocirc;i;</li>\n' +
				     '<li>d) Sau 30 ph&uacute;t kể từ khi Qu&yacute; kh&aacute;ch thanh to&aacute;n th&agrave;nh c&ocirc;ng m&agrave; vẫn chưa nhận được bất kỳ x&aacute;c nhận n&agrave;o (qua email hoặc SMS/ ZNS), Qu&yacute; kh&aacute;ch vui l&ograve;ng li&ecirc;n hệ ch&uacute;ng t&ocirc;i qua tổng đ&agrave;i 1900 6067 để được hỗ trợ. Nếu Qu&yacute; kh&aacute;ch kh&ocirc;ng li&ecirc;n hệ lại coi như FUTA Bus Lines đ&atilde; ho&agrave;n th&agrave;nh nghĩa vụ với Qu&yacute; kh&aacute;ch.</li>\n' +
				     '<li>Bảo đảm an to&agrave;n giao dịch</li>\n' +
				     '<li>a) Quản l&yacute; th&ocirc;ng tin nh&agrave; cung cấp dịch vụ: FUTA Bus Lines (hoặc b&ecirc;n thứ ba - nh&agrave; cung cấp cổng thanh to&aacute;n điện tử, hoặc/v&agrave; c&aacute;c b&ecirc;n k&yacute; kết kh&aacute;c) sẽ sử dụng c&aacute;c c&ocirc;ng nghệ đặc biệt để nhận biết c&aacute;c hoạt động giả mạo tr&ecirc;n trang mạng như: sử dụng thẻ t&iacute;n dụng giả v.v. Sự chấp nhận hợp t&aacute;c của Qu&yacute; kh&aacute;ch c&ugrave;ng với nỗ lực của FUTA Bus Lines l&agrave; rất cần thiết. Qu&yacute; kh&aacute;ch chấp nhận rằng FUTA Bus Lines c&oacute; thể chấm dứt quyền truy cập v&agrave; sử dụng trang mạng của FUTA Bus Lines nếu Qu&yacute; kh&aacute;ch hoặc người kh&aacute;c h&agrave;nh động nh&acirc;n danh Qu&yacute; kh&aacute;ch v&igrave; l&yacute; do n&agrave;o đ&oacute; nằm trong diện nghi vấn c&oacute; gian lận hoặc vi phạm c&aacute;c điều khoản n&agrave;y;</li>\n' +
				     '<li>b) Kiểm so&aacute;t giao dịch v&agrave; th&ocirc;ng tin phản hồi kh&aacute;ch h&agrave;ng: FUTA Bus Lines sẽ hết sức cố gắng sử dụng mọi biện ph&aacute;p v&agrave; tu&acirc;n theo mọi c&aacute;ch thức c&oacute; thể để giữ an to&agrave;n cho tất cả c&aacute;c th&ocirc;ng tin c&aacute; nh&acirc;n của Qu&yacute; kh&aacute;ch, v&agrave; ch&uacute;ng t&ocirc;i cũng sẽ thường xuy&ecirc;n cập nhật những th&ocirc;ng tin ch&iacute;nh x&aacute;c nhất. Website n&agrave;y c&oacute; những c&ocirc;ng nghệ an ninh đảm bảo việc bảo vệ c&aacute;c th&ocirc;ng tin bị thất lạc, lạm dụng hoặc thay đổi. Tất cả c&aacute;c giao dịch v&agrave; th&ocirc;ng tin về thẻ được sử dụng đều được đảm bảo an to&agrave;n cho c&aacute;c giao dịch kinh tế ng&agrave;y nay. Mặc d&ugrave; vậy, kh&ocirc;ng phải tất cả c&aacute;c dữ liệu truyền qua Internet đều c&oacute; thể đảm bảo 100%, v&igrave; thế ch&uacute;ng t&ocirc;i kh&ocirc;ng thể đưa ra một sự đảm bảo tuyệt đối rằng mọi th&ocirc;ng tin Qu&yacute; kh&aacute;ch cung cấp đều được bảo vệ tất cả mọi l&uacute;c.</li>\n' +
				     '<li>Th&ocirc;ng tin c&aacute; nh&acirc;n</li>\n' +
				     '<li>a) Th&ocirc;ng tin c&aacute; nh&acirc;n của Qu&yacute; kh&aacute;ch m&agrave; ch&uacute;ng t&ocirc;i c&oacute; được trong qu&aacute; tr&igrave;nh giao dịch chỉ d&ugrave;ng v&agrave;o c&aacute;c mục đ&iacute;ch sau:</li>\n' +
				     '</ol>\n' +
				     '<p>- Hỗ trợ v&agrave; giải đ&aacute;p c&aacute;c thắc mắc của Qu&yacute; kh&aacute;ch;</p>\n' +
				     '<p>- Cập nhật c&aacute;c th&ocirc;ng tin mới nhất về c&aacute;c chương tr&igrave;nh, dịch vụ v.v. của FUTA Bus Lines đến Qu&yacute; kh&aacute;ch.</p>\n' +
				     '<ol>\n' +
				     '<li>b) Ch&uacute;ng t&ocirc;i thu thập v&agrave; sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n của Qu&yacute; kh&aacute;ch ph&ugrave; hợp với mục đ&iacute;ch đ&atilde; n&ecirc;u b&ecirc;n tr&ecirc;n v&agrave; ho&agrave;n to&agrave;n tu&acirc;n thủ nội dung của &ldquo;Ch&iacute;nh s&aacute;ch bảo mật&rdquo;. Ch&uacute;ng t&ocirc;i cam kết chỉ sử dụng cho mục đ&iacute;ch v&agrave; phạm vi đ&atilde; n&ecirc;u v&agrave; kh&ocirc;ng tiết lộ cho bất kỳ b&ecirc;n thứ ba n&agrave;o khi chưa c&oacute; sự đồng &yacute; bằng văn bản của Qu&yacute; kh&aacute;ch;</li>\n' +
				     '<li>c) Xin lưu &yacute; ch&uacute;ng t&ocirc;i được quyền cung cấp th&ocirc;ng tin c&aacute; nh&acirc;n của Qu&yacute; kh&aacute;ch trong trường hợp khi c&oacute; y&ecirc;u cầu từ c&aacute;c cơ quan Nh&agrave; nước c&oacute; thẩm quyền.</li>\n' +
				     '<li>Ch&iacute;nh s&aacute;ch ho&agrave;n/hủy/đổi v&eacute;</li>\n' +
				     '<li>a) Quy định ho&agrave;n trả tiền mua v&eacute; Online do lỗi giao dịch</li>\n' +
				     '</ol>\n' +
				     '<p>- C&aacute;c trường hợp ho&agrave;n trả tiền mua v&eacute; online cho kh&aacute;ch do lỗi giao dịch:</p>\n' +
				     '<p>- Kh&aacute;ch h&agrave;ng mua v&eacute; online giao dịch kh&ocirc;ng th&agrave;nh c&ocirc;ng (lỗi giao dịch) chưa c&oacute; M&atilde; đặt v&eacute; (code) nhưng đ&atilde; bị trừ tiền;</p>\n' +
				     '<p>- Hiện nay, c&oacute; một số Thẻ ATM của kh&aacute;ch h&agrave;ng (Thẻ ATM cũ được l&agrave;m từ 3-4 năm trước) chỉ thực hiện được h&igrave;nh thức chuyển khoản kh&ocirc;ng c&oacute; chức năng thanh to&aacute;n trực tuyến n&ecirc;n khi kh&aacute;ch h&agrave;ng thực hiện giao dịch chuyển khoản v&agrave;o cuối tuần hoặc v&agrave;o ng&agrave;y Lễ, Tết, hệ thống ng&acirc;n h&agrave;ng kh&ocirc;ng x&aacute;c nhận tiền trong t&agrave;i khoản của FUTA Bus Lines n&ecirc;n kh&aacute;ch h&agrave;ng phải thanh to&aacute;n trực tiếp tại quầy v&eacute; (Kh&aacute;ch h&agrave;ng vừa bị trừ tiền trong t&agrave;i khoản vừa phải ra quầy v&eacute; thanh to&aacute;n tiền mặt lấy v&eacute;).</p>\n' +
				     '<ol>\n' +
				     '<li>b) Thời gian ho&agrave;n trả tiền cho kh&aacute;ch h&agrave;ng</li>\n' +
				     '</ol>\n' +
				     '<p>- Đối với Bộ phận Tổng đ&agrave;i: Thời gian ho&agrave;n trả tiền tới t&agrave;i khoản kh&aacute;ch h&agrave;ng l&agrave; từ 03 ng&agrave;y đến 05 ng&agrave;y l&agrave;m việc kể từ khi Ban T&agrave;i ch&iacute;nh &ndash; Kế to&aacute;n nhận chứng từ thanh to&aacute;n;</p>\n' +
				     '<p>- Đối với c&aacute;c quầy v&eacute;: Giao dịch trực tiếp với kh&aacute;ch h&agrave;ng v&agrave; ho&agrave;n trả ngay thời điểm giao dịch.</p>\n' +
				     '<p>- Đối với ho&agrave;n trả tiền mua v&eacute; qua App: Thời gian theo ch&iacute;nh s&aacute;ch của từng nh&agrave; ph&aacute;t triển App</p>\n' +
				     '<ol>\n' +
				     '<li>c) Quy định thay đổi hoặc hủy v&eacute;</li>\n' +
				     '</ol>\n' +
				     '<p>- Chỉ được chuyển đổi v&eacute; 1 lần duy nhất</p>\n' +
				     '<p>- Chi ph&iacute; hủy v&eacute; từ 10% &ndash; 30% gi&aacute; v&eacute; t&ugrave;y thuộc thời gian hủy v&eacute; so với giờ khởi h&agrave;nh ghi tr&ecirc;n v&eacute; v&agrave; số lượng v&eacute; c&aacute; nh&acirc;n/tập thể &aacute;p dụng theo c&aacute;c quy định hiện h&agrave;nh.</p>\n' +
				     '<p>- Qu&yacute; kh&aacute;ch khi c&oacute; nhu cầu muốn thay đổi hoặc hủy v&eacute; đ&atilde; thanh to&aacute;n, cần li&ecirc;n hệ với Trung t&acirc;m tổng đ&agrave;i 1900 6067 hoặc quầy v&eacute; chậm nhất trước 24h so với giờ xe khởi h&agrave;nh được ghi tr&ecirc;n v&eacute;, tr&ecirc;n email hoặc tin nhắn để được hướng dẫn th&ecirc;m.</p>\n' +
				     '<ol start="7">\n' +
				     '<li>K&ecirc;nh b&aacute;n v&eacute;</li>\n' +
				     '<li>a) FUTA Bus Lines khuyến c&aacute;o Qu&yacute; kh&aacute;ch mua v&eacute; lựa chọn một trong c&aacute;c phương thức mua v&eacute; bao gồm mua trực tiếp tại website, app, ph&ograve;ng v&eacute; ch&iacute;nh thức hoặc mua v&eacute; qua Tổng đ&agrave;i 1900 6067 để đảm bảo kh&ocirc;ng mua phải v&eacute; giả, v&eacute; bị n&acirc;ng gi&aacute; v.v.;</li>\n' +
				     '<li>b) Nếu ph&aacute;t hiện ra Qu&yacute; kh&aacute;ch gian lận, vi phạm điều khoản sử dụng, c&oacute; h&agrave;nh vi đầu cơ, mua đi b&aacute;n lại, b&aacute;n v&eacute; chợ đen. FUTA Bus Lines c&oacute; quyền từ chối cung cấp dịch vụ v&agrave; kh&ocirc;ng giải quyết c&aacute;c vấn đề ph&aacute;t sinh nếu Qu&yacute; kh&aacute;ch mua v&eacute; từ c&aacute;c k&ecirc;nh kh&ocirc;ng thuộc hệ thống b&aacute;n v&eacute; của FUTA Bus Lines.</li>\n' +
				     '<li>Trung chuyển: Nếu qu&yacute; kh&aacute;ch c&oacute; nhu cầu trung chuyển, vui l&ograve;ng li&ecirc;n hệ số điện thoại 1900 6067 trước khi đặt v&eacute;. Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng đ&oacute;n/trung chuyển tại những địa điểm xe trung chuyển kh&ocirc;ng thể đến được.</li>\n' +
				     '</ol>\n' +
				     '<br/><h2 style="font-size:16px; font-weight: bold;">Điều 3: Quy định vận chuyển</h2>\n' +
				     '<ol>\n' +
				     '<li>Trẻ em dưới 6 tuổi v&agrave; phụ nữ c&oacute; thai</li>\n' +
				     '<li>a) Trẻ em dưới 6 tuổi, cao từ 1.3m trở xuống, c&acirc;n nặng dưới 30kg th&igrave; kh&ocirc;ng phải mua v&eacute;.</li>\n' +
				     '<li>b) Phụ nữ c&oacute; thai cần đảm bảo sức khoẻ trong suốt qu&aacute; tr&igrave;nh di chuyển.</li>\n' +
				     '<li>H&agrave;nh l&yacute;</li>\n' +
				     '<li>a) Tổng trọng lượng h&agrave;nh l&yacute; kh&ocirc;ng vượt qu&aacute; 20kg;</li>\n' +
				     '<li>b) Đối với h&agrave;nh l&yacute; qu&aacute; khổ, cồng kềnh vui l&ograve;ng li&ecirc;n hệ Tổng đ&agrave;i 1900 6067</li>\n' +
				     '<li>Y&ecirc;u cầu khi l&ecirc;n xe</li>\n' +
				     '<li>a) C&oacute; mặt trước giờ xe khởi h&agrave;nh 30 ph&uacute;t tại Bến đi (đối với ng&agrave;y lễ tết cần ra trước 60 ph&uacute;t)</li>\n' +
				     '<li>b) Xuất tr&igrave;nh th&ocirc;ng tin v&eacute; được gửi qua SMS/Email/Futa App hoặc li&ecirc;n hệ quầy v&eacute; để nhận th&ocirc;ng tin v&eacute; trước khi l&ecirc;n xe.</li>\n' +
				     '<li>c) Kh&ocirc;ng mang đồ ăn, thức ăn c&oacute; m&ugrave;i l&ecirc;n xe;</li>\n' +
				     '<li>d) Kh&ocirc;ng h&uacute;t thuốc, uống rượu, sử dụng chất k&iacute;ch th&iacute;ch tr&ecirc;n xe;</li>\n' +
				     '<li>e) Kh&ocirc;ng mang c&aacute;c vật dễ ch&aacute;y nổ l&ecirc;n xe;</li>\n' +
				     '<li>f) Kh&ocirc;ng vứt r&aacute;c tr&ecirc;n xe;</li>\n' +
				     '<li>g) Kh&ocirc;ng mang động vật l&ecirc;n xe.</li>\n' +
				     '</ol>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold;">II. CH&Iacute;NH S&Aacute;CH BẢO MẬT</h1>\n' +
				     '<br/><h2 style="font-size:16px; font-weight: bold;">Điều 1. Quy định chung</h2>\n' +
				     '<ol>\n' +
				     '<li>Nhằm mang lại trải nghiệm tốt nhất cho người d&ugrave;ng tr&ecirc;n website của FUTA Bus Lines, th&ocirc;ng tin nhận dạng c&aacute; nh&acirc;n hoặc dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng sẽ được thu thập, sử dụng, tiết lộ, xử l&yacute; trong khu&ocirc;n khổ bảo vệ người d&ugrave;ng;</li>\n' +
				     '<li>Sau khi đọc Ch&iacute;nh s&aacute;ch bảo mật n&agrave;y, qu&yacute; kh&aacute;ch h&agrave;ng tự quyết định việc chia sẻ dữ liệu c&aacute; nh&acirc;n với ch&uacute;ng t&ocirc;i. Dữ liệu c&aacute; nh&acirc;n ở đ&acirc;y đề cập đến mọi th&ocirc;ng tin li&ecirc;n quan đến một c&aacute; nh&acirc;n c&oacute; thể định danh/ nhận dạng được. Khi nhấp chọn "đồng &yacute;", qu&yacute; kh&aacute;ch h&agrave;ng thừa nhận rằng qu&yacute; kh&aacute;ch h&agrave;ng đ&atilde; đọc, đồng &yacute; v&agrave; chấp thuận cho ch&uacute;ng t&ocirc;i thu thập, sử dụng v&agrave; xử l&yacute; Dữ liệu c&aacute; nh&acirc;n theo Ch&iacute;nh s&aacute;ch bảo mật n&agrave;y v&agrave;/hoặc c&aacute;c Điều khoản sử dụng. Đồng thời, qu&yacute; kh&aacute;ch h&agrave;ng cũng thừa nhận rằng to&agrave;n bộ Dữ liệu c&aacute; nh&acirc;n m&agrave; qu&yacute; kh&aacute;ch h&agrave;ng đ&atilde; cung cấp hoặc sẽ cung cấp l&agrave; dữ liệu ch&iacute;nh chủ, đ&uacute;ng v&agrave; ch&iacute;nh x&aacute;c.</li>\n' +
				     '<li>T&ugrave;y từng thời điểm FUTA Bus Lines c&oacute; thể sửa đổi Ch&iacute;nh s&aacute;ch bảo mật n&agrave;y để phản &aacute;nh c&aacute;c thay đổi về luật ph&aacute;p v&agrave; quy định, th&ocirc;ng lệ sử dụng của FUTA Bus Lines, c&aacute;c t&iacute;nh năng Hệ thống v&agrave;/hoặc c&aacute;c tiến bộ c&ocirc;ng nghệ. Ch&uacute;ng t&ocirc;i khuyến kh&iacute;ch kh&aacute;ch h&agrave;ng thường xuy&ecirc;n xem lại Ch&iacute;nh s&aacute;ch Bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n tr&ecirc;n FUTA. Cập nhật th&ocirc;ng tin li&ecirc;n tục c&oacute; thể đảm bảo bạn biết v&agrave; thực hiện tốt quản l&yacute; c&aacute; nh&acirc;n.</li>\n' +
				     '</ol>\n' +
				     '<br/><h2 style="font-size:16px; font-weight: bold;">Điều 2. Ch&iacute;nh s&aacute;ch bảo mật</h2>\n' +
				     '<ol>\n' +
				     '<li>Th&ocirc;ng tin thu thập: Khi được sự đồng &yacute; của qu&yacute; kh&aacute;ch h&agrave;ng, ch&uacute;ng t&ocirc;i c&oacute; thể thu thập Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng để cung cấp dịch vụ của ch&uacute;ng t&ocirc;i cho qu&yacute; kh&aacute;ch h&agrave;ng khi sử dụng Hệ thống dữ liệu FUTA. Dữ liệu c&aacute; nh&acirc;n bao gồm những th&ocirc;ng tin được tr&igrave;nh b&agrave;y như sau:</li>\n' +
				     '<li>a) Th&ocirc;ng tin c&aacute; nh&acirc;n cơ bản: khi qu&yacute; kh&aacute;ch h&agrave;ng đang sử dụng Hệ thống của ch&uacute;ng t&ocirc;i, ch&uacute;ng t&ocirc;i c&oacute; thể y&ecirc;u cầu qu&yacute; kh&aacute;ch h&agrave;ng tạo một t&agrave;i khoản để tiến h&agrave;nh đặt chỗ. Dữ liệu c&aacute; nh&acirc;n được thu thập sẽ bao gồm, nhưng kh&ocirc;ng giới hạn t&ecirc;n của qu&yacute; kh&aacute;ch h&agrave;ng, th&ocirc;ng tin nhận dạng người d&ugrave;ng v&agrave; th&ocirc;ng tin đăng nhập của FUTA ID, Địa chỉ thư điện tử (email), số điện thoại, địa chỉ v&agrave; phương thức thanh to&aacute;n;</li>\n' +
				     '<li>b) Th&ocirc;ng tin c&aacute; nh&acirc;n cụ thể: ch&uacute;ng t&ocirc;i c&oacute; thể thu thập Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng dựa tr&ecirc;n qu&aacute; tr&igrave;nh qu&yacute; kh&aacute;ch h&agrave;ng sử dụng Hệ thống của ch&uacute;ng t&ocirc;i, v&iacute; dụ: chi tiết về quyền th&agrave;nh vi&ecirc;n thường xuy&ecirc;n cũng như những đ&aacute;nh gi&aacute; của qu&yacute; kh&aacute;ch h&agrave;ng. Ch&uacute;ng t&ocirc;i cũng c&oacute; thể thu thập một số th&ocirc;ng tin nhất định từ qu&yacute; kh&aacute;ch h&agrave;ng khi qu&yacute; kh&aacute;ch h&agrave;ng đang sử dụng Hệ thống của ch&uacute;ng t&ocirc;i, chẳng hạn như vị tr&iacute; địa l&yacute;, địa chỉ IP, t&ugrave;y chọn t&igrave;m kiếm cũng như c&aacute;c dữ liệu li&ecirc;n quan đến việc sử dụng Internet chung kh&aacute;c;</li>\n' +
				     '<li>c) Vị tr&iacute; địa l&yacute;: khi được sự đồng &yacute; của qu&yacute; kh&aacute;ch h&agrave;ng, ch&uacute;ng t&ocirc;i c&oacute; thể thu thập Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng về vị tr&iacute; thực tế của qu&yacute; kh&aacute;ch h&agrave;ng để cung cấp cho qu&yacute; kh&aacute;ch h&agrave;ng c&aacute;c ưu đ&atilde;i ở c&aacute;c vị tr&iacute; li&ecirc;n quan được cung cấp tr&ecirc;n Hệ thống của ch&uacute;ng t&ocirc;i. Ch&uacute;ng t&ocirc;i cũng c&oacute; thể lấy được vị tr&iacute; gần đ&uacute;ng của qu&yacute; kh&aacute;ch h&agrave;ng từ địa chỉ IP v&agrave; GPS của qu&yacute; kh&aacute;ch h&agrave;ng;</li>\n' +
				     '<li>d) Th&ocirc;ng tin nhật k&yacute;: khi qu&yacute; kh&aacute;ch h&agrave;ng đang sử dụng Hệ thống của ch&uacute;ng t&ocirc;i, ch&uacute;ng t&ocirc;i c&oacute; thể thu thập dữ liệu của qu&yacute; kh&aacute;ch h&agrave;ng m&agrave; ch&uacute;ng t&ocirc;i gọi l&agrave; "th&ocirc;ng tin nhật k&yacute;". Th&ocirc;ng tin nhật k&yacute; vẫn c&oacute; thể được thu thập ngay cả khi qu&yacute; kh&aacute;ch h&agrave;ng kh&ocirc;ng tạo bất kỳ t&agrave;i khoản n&agrave;o tr&ecirc;n Hệ thống của ch&uacute;ng t&ocirc;i. Th&ocirc;ng tin nhật k&yacute; n&agrave;y c&oacute; thể bao gồm địa chỉ IP, loại tr&igrave;nh duyệt, hệ điều h&agrave;nh, quốc tịch, vị tr&iacute; truy cập trang, nh&agrave; cung cấp dịch vụ di động, th&ocirc;ng tin thiết bị v&agrave; lịch sử t&igrave;m kiếm cũng như th&ocirc;ng tin li&ecirc;n quan đến việc sử dụng internet chung kh&aacute;c. Ch&uacute;ng t&ocirc;i sử dụng th&ocirc;ng tin nhật k&yacute; để cung cấp cho người d&ugrave;ng trải nghiệm tốt hơn khi sử dụng Hệ thống của ch&uacute;ng t&ocirc;i;</li>\n' +
				     '<li>e) Cookies v&agrave; c&aacute;c c&ocirc;ng nghệ tương tự: ch&uacute;ng t&ocirc;i c&oacute; thể sử dụng cookie v&agrave;/hoặc c&aacute;c c&ocirc;ng nghệ tương tự (như tập tin chỉ b&aacute;o-web beacons, thẻ-tags, tập lệnh-scripts). Cookies l&agrave; một phần nhỏ dữ liệu được lưu trữ trong m&aacute;y t&iacute;nh hoặc thiết bị di động của qu&yacute; kh&aacute;ch h&agrave;ng để gi&uacute;p ch&uacute;ng t&ocirc;i theo d&otilde;i qu&yacute; kh&aacute;ch h&agrave;ng. Giống như c&aacute;c trang web kh&aacute;c, ch&uacute;ng t&ocirc;i c&oacute; thể sử dụng cookies để cung cấp cho qu&yacute; kh&aacute;ch h&agrave;ng trải nghiệm tốt hơn, v&igrave; vậy m&aacute;y t&iacute;nh v&agrave;/hoặc thiết bị di động của qu&yacute; kh&aacute;ch h&agrave;ng sẽ nhận ra qu&yacute; kh&aacute;ch h&agrave;ng khi qu&yacute; kh&aacute;ch h&agrave;ng sử dụng Hệ thống của Ch&uacute;ng t&ocirc;i sau đ&oacute;. Vui l&ograve;ng hủy t&ugrave;y chọn cookies, nếu qu&yacute; kh&aacute;ch h&agrave;ng muốn ch&uacute;ng t&ocirc;i dừng t&iacute;nh năng cookies.</li>\n' +
				     '<li>Mục đ&iacute;ch sử dụng th&ocirc;ng tin: Ch&uacute;ng t&ocirc;i sẽ nhận th&ocirc;ng tin dữ liệu c&aacute; nh&acirc;n khi kh&aacute;ch h&agrave;ng c&agrave;i đặt v&agrave; sử dụng. Khi được sự đồng &yacute; của qu&yacute; kh&aacute;ch h&agrave;ng, Ch&uacute;ng t&ocirc;i c&oacute; thể sử dụng th&ocirc;ng tin của qu&yacute; kh&aacute;ch h&agrave;ng được thu thập th&ocirc;ng qua Hệ thống cho c&aacute;c mục đ&iacute;ch sau:</li>\n' +
				     '<li>a) Đăng k&yacute; sử dụng v&agrave;/hoặc truy cập hệ thống;</li>\n' +
				     '<li>b) Quản l&yacute;, vận h&agrave;nh, quản trị v&agrave;/hoặc truy cập hệ thống;</li>\n' +
				     '<li>c) Li&ecirc;n hệ với qu&yacute; kh&aacute;ch h&agrave;ng về c&aacute;c vấn đề li&ecirc;n quan đến việc qu&yacute; kh&aacute;ch h&agrave;ng sử dụng v&agrave;/hoặc truy cập v&agrave;o Hệ thống v&agrave; quản l&yacute; c&aacute;c truy vấn v&agrave;/hoặc y&ecirc;u cầu do qu&yacute; kh&aacute;ch h&agrave;ng gửi qua Hệ thống;</li>\n' +
				     '<li>d) T&ugrave;y chỉnh trải nghiệm của qu&yacute; kh&aacute;ch h&agrave;ng khi sử dụng hệ thống v&agrave; cải thiện trải nghiệm v&agrave; sự h&agrave;i l&ograve;ng của kh&aacute;ch h&agrave;ng;</li>\n' +
				     '<li>e) Thực thi c&aacute;c quy định trong c&aacute;c Điều khoản v&agrave; Điều kiện của ch&uacute;ng t&ocirc;i;</li>\n' +
				     '<li>f) Giải quyết tranh chấp, thu tiền thanh to&aacute;n c&ograve;n tồn đọng v&agrave; xử l&yacute; sự cố v&agrave;/hoặc cho c&aacute;c mục đ&iacute;ch về tiếp thị như:</li>\n' +
				     '</ol>\n' +
				     '<p>- Tiếp thị truyền thống trong đ&oacute;, bao gồm nhưng kh&ocirc;ng giới hạn, gửi email cho qu&yacute; kh&aacute;ch h&agrave;ng về c&aacute;c sản phẩm mới, khuyến mại đặc biệt v&agrave; c&aacute;c cuộc khảo s&aacute;t hoặc c&aacute;c th&ocirc;ng tin kh&aacute;c m&agrave; ch&uacute;ng t&ocirc;i nghĩ qu&yacute; kh&aacute;ch h&agrave;ng c&oacute; thể thấy th&uacute; vị;</p>\n' +
				     '<p>- Tiếp thị kỹ thuật số bao gồm, nhưng kh&ocirc;ng giới hạn truyền th&ocirc;ng x&atilde; hội, quảng c&aacute;o hiển thị, tối ưu h&oacute;a c&ocirc;ng cụ t&igrave;m kiếm ("SEO"), tiếp thị qua c&ocirc;ng cụ t&igrave;m kiếm ("SEM"), th&ocirc;ng b&aacute;o đẩy (Push Notification) bằng c&aacute;ch sử dụng c&aacute;c kỹ thuật đồ thị mở.</p>\n' +
				     '<ol start="3">\n' +
				     '<li>Chia sẻ Dữ liệu c&aacute; nh&acirc;n: T&ugrave;y thuộc v&agrave;o từng trường hợp cụ thể phải cung cấp th&ocirc;ng tin cho những người hoặc c&aacute;c tổ chức c&oacute; thể được tiếp cận, FUTA Bus Lines c&oacute; thể tiết lộ Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng với c&aacute;c điều kiện sau:</li>\n' +
				     '<li>a) Cung cấp th&ocirc;ng tin khi c&oacute; sự chấp thuận: Ch&uacute;ng t&ocirc;i chỉ chia sẻ Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng với B&ecirc;n thứ ba khi Ch&uacute;ng t&ocirc;i nhận được sự chấp thuận của qu&yacute; kh&aacute;ch h&agrave;ng cho ph&eacute;p Ch&uacute;ng t&ocirc;i l&agrave;m như vậy. Ch&uacute;ng t&ocirc;i sẽ cần sự chấp thuận của qu&yacute; kh&aacute;ch h&agrave;ng để chia sẻ bất kỳ Dữ liệu c&aacute; nh&acirc;n nhạy cảm n&agrave;o, theo y&ecirc;u cầu của luật ph&aacute;p v&agrave; quy định hiện h&agrave;nh. Khi nhấp chọn n&uacute;t "Đồng &yacute;", qu&yacute; kh&aacute;ch h&agrave;ng đ&atilde; thiết lập một h&agrave;nh động khẳng định r&otilde; r&agrave;ng v&agrave; một thỏa thuận tự nguyện, cụ thể, đ&atilde; hiểu r&otilde; v&agrave; kh&ocirc;ng mơ hồ về việc xử l&yacute; Dữ liệu c&aacute; nh&acirc;n. Điều n&agrave;y c&oacute; thể bao gồm cả việc chia sẻ Dữ liệu c&aacute; nh&acirc;n đ&atilde; thu thập cho B&ecirc;n thứ ba;</li>\n' +
				     '<li>b) Cung cấp th&ocirc;ng tin v&igrave; l&yacute; do ph&aacute;p l&yacute;: Ch&uacute;ng t&ocirc;i c&oacute; thể c&oacute; to&agrave;n quyền quyết định về việc chia sẻ Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng với B&ecirc;n thứ ba nếu ch&uacute;ng t&ocirc;i cho rằng việc chia sẻ dữ liệu l&agrave; cần thiết để:</li>\n' +
				     '</ol>\n' +
				     '<p>- Tu&acirc;n thủ luật ph&aacute;p v&agrave; quy định hiện h&agrave;nh;</p>\n' +
				     '<p>- Thực thi c&aacute;c Điều khoản v&agrave; Điều kiện của Ch&uacute;ng t&ocirc;i;</p>\n' +
				     '<p>- Điều tra bất kỳ h&agrave;nh vi gian lận hoặc h&agrave;nh vi bất hợp ph&aacute;p n&agrave;o;</p>\n' +
				     '<p>- Bảo vệ thương hiệu, uy t&iacute;n cũng như t&agrave;i sản của Ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<ol start="4">\n' +
				     '<li>Bảo mật dữ liệu c&aacute; nh&acirc;n</li>\n' +
				     '<li>a) Cam kết bảo mật:</li>\n' +
				     '</ol>\n' +
				     '<p>- Ch&uacute;ng t&ocirc;i nỗ lực đảm bảo cung cấp th&ocirc;ng tin c&oacute; tr&aacute;ch nhiệm v&agrave; hệ thống hoạt động ch&iacute;nh x&aacute;c;</p>\n' +
				     '<p>- FUTA Bus Lines mong muốn mang lại cảm gi&aacute;c an to&agrave;n cho kh&aacute;ch h&agrave;ng khi sử dụng dịch vụ mua v&eacute; xe online. Ch&uacute;ng t&ocirc;i cam kết bảo vệ tất cả th&ocirc;ng tin m&agrave; Ch&uacute;ng t&ocirc;i nhận được từ kh&aacute;ch h&agrave;ng. Để ngăn chặn truy cập tr&aacute;i ph&eacute;p, đảm bảo sử dụng đ&uacute;ng th&ocirc;ng tin, Ch&uacute;ng t&ocirc;i sẽ sử dụng c&aacute;c phương ph&aacute;p v&agrave; c&ocirc;ng nghệ bảo mật Internet hợp l&yacute;.</p>\n' +
				     '<ol>\n' +
				     '<li>b) An to&agrave;n dữ liệu: Ch&uacute;ng t&ocirc;i đ&atilde; v&agrave; đang thực hiện nhiều biện ph&aacute;p an to&agrave;n, bao gồm:</li>\n' +
				     '</ol>\n' +
				     '<p>- Ch&uacute;ng t&ocirc;i lưu trữ kh&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng trong m&ocirc;i trường vận h&agrave;nh an to&agrave;n. Chỉ c&oacute; nh&acirc;n vi&ecirc;n, đại diện v&agrave; nh&agrave; cung cấp mới c&oacute; thể truy cập khi cần phải biết;</p>\n' +
				     '<p>- Ch&uacute;ng t&ocirc;i tu&acirc;n theo c&aacute;c ti&ecirc;u chuẩn ng&agrave;nh, ph&aacute;p luật trong việc bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;ch h&agrave;ng.</p>\n' +
				     '<ol start="5">\n' +
				     '<li>Lưu trữ Dữ liệu c&aacute; nh&acirc;n</li>\n' +
				     '<li>a) Miễn l&agrave; th&ocirc;ng tin của qu&yacute; kh&aacute;ch h&agrave;ng vẫn c&ograve;n tồn tại, ch&uacute;ng t&ocirc;i sẽ lưu Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng để cung cấp c&aacute;c dịch vụ khi cần. Ch&uacute;ng t&ocirc;i sẽ ngừng lưu trữ Dữ liệu c&aacute; nh&acirc;n hoặc với nỗ lực hợp l&yacute; để x&oacute;a c&aacute;c phương tiện c&oacute; li&ecirc;n quan đến Dữ liệu c&aacute; nh&acirc;n của Qu&yacute; kh&aacute;ch h&agrave;ng, ngay khi:</li>\n' +
				     '</ol>\n' +
				     '<p>- Mục đ&iacute;ch thu thập Dữ liệu c&aacute; nh&acirc;n kh&ocirc;ng c&ograve;n ph&ugrave; hợp với việc lưu trữ dữ liệu;</p>\n' +
				     '<p>- Kh&aacute;ch h&agrave;ng y&ecirc;u cầu hủy bỏ.</p>\n' +
				     '<ol>\n' +
				     '<li>b) Thời gian lưu trữ dữ liệu bắt đầu từ khi FUTA BUSLINES nhận được y&ecirc;u cầu lưu trữ dữ liệu đến khi kết th&uacute;c y&ecirc;u cầu. Thời gian lưu trữ tối thiểu l&agrave; 24 th&aacute;ng.</li>\n' +
				     '<li>Quyền của kh&aacute;ch h&agrave;ng đối với Dữ liệu c&aacute; nh&acirc;n: Qu&yacute; kh&aacute;ch h&agrave;ng c&oacute; quyền cập nhật, thay đổi hoặc hủy bỏ Dữ liệu c&aacute; nh&acirc;n bất kỳ l&uacute;c n&agrave;o. Trong c&aacute;c t&igrave;nh huống cần lưu &yacute; dưới đ&acirc;y:</li>\n' +
				     '<li>a) Phương thức truy cập hoặc chỉnh sửa Dữ liệu c&aacute; nh&acirc;n: Khi qu&yacute; kh&aacute;ch h&agrave;ng cung cấp cho Ch&uacute;ng t&ocirc;i Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng, vui l&ograve;ng đảm bảo rằng những dữ liệu đ&oacute; l&agrave; ch&iacute;nh x&aacute;c v&agrave; đầy đủ. Nếu qu&yacute; kh&aacute;ch h&agrave;ng tin rằng bất kỳ th&ocirc;ng tin n&agrave;o m&agrave; Ch&uacute;ng t&ocirc;i đang nắm giữ c&oacute; sai s&oacute;t hoặc thiếu s&oacute;t, vui l&ograve;ng đăng nhập v&agrave;o t&agrave;i khoản của qu&yacute; kh&aacute;ch h&agrave;ng tr&ecirc;n Hệ thống v&agrave; sửa lại th&ocirc;ng tin. Ngo&agrave;i ra, qu&yacute; kh&aacute;ch h&agrave;ng n&ecirc;n nhanh ch&oacute;ng cập nhật Dữ liệu c&aacute; nh&acirc;n th&ocirc;ng qua t&agrave;i khoản nếu c&oacute; bất kỳ thay đổi n&agrave;o;</li>\n' +
				     '<li>b) R&uacute;t lại sự chấp thuận: Qu&yacute; kh&aacute;ch h&agrave;ng c&oacute; thể r&uacute;t lại sự chấp thuận đối với việc thu thập, sử dụng hoặc tiết lộ Dữ liệu c&aacute; nh&acirc;n của Ch&uacute;ng t&ocirc;i bằng c&aacute;ch gửi th&ocirc;ng b&aacute;o hợp l&yacute; cho Ch&uacute;ng t&ocirc;i theo th&ocirc;ng tin li&ecirc;n hệ tr&igrave;nh b&agrave;y b&ecirc;n dưới. Theo y&ecirc;u cầu của qu&yacute; kh&aacute;ch h&agrave;ng, Ch&uacute;ng t&ocirc;i sẽ ngừng thu thập, sử dụng hoặc tiết lộ Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng, trừ khi ph&aacute;p luật y&ecirc;u cầu hoặc nếu Ch&uacute;ng t&ocirc;i c&oacute; c&aacute;c mục đ&iacute;ch kinh doanh hoặc ph&aacute;p l&yacute; hợp ph&aacute;p để giữ lại dữ liệu đ&oacute;;</li>\n' +
				     '</ol>\n' +
				     '<p>Lưu &yacute;: khi Qu&yacute; kh&aacute;ch h&agrave;ng r&uacute;t lại sự đồng thuận đối với việc thu thập, sử dụng hoặc tiết lộ Dữ liệu c&aacute; nh&acirc;n sẽ khiến Ch&uacute;ng t&ocirc;i kh&ocirc;ng thể tiếp tục cung cấp cho qu&yacute; kh&aacute;ch h&agrave;ng c&aacute;c dịch vụ của Ch&uacute;ng t&ocirc;i v&agrave; qu&yacute; kh&aacute;ch h&agrave;ng đồng &yacute; rằng Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chịu tr&aacute;ch nhiệm với qu&yacute; kh&aacute;ch h&agrave;ng về bất kỳ tổn thất hoặc thiệt hại n&agrave;o ph&aacute;t sinh từ hoặc li&ecirc;n quan đến việc chấm dứt dịch vụ như vậy.</p>\n' +
				     '<ol>\n' +
				     '<li>c) X&oacute;a Dữ liệu c&aacute; nh&acirc;n: Qu&yacute; kh&aacute;ch h&agrave;ng c&oacute; thể y&ecirc;u cầu x&oacute;a Dữ liệu c&aacute; nh&acirc;n của qu&yacute; kh&aacute;ch h&agrave;ng do Ch&uacute;ng t&ocirc;i thu thập v&agrave; xử l&yacute;, bằng c&aacute;ch gửi cho Ch&uacute;ng t&ocirc;i một l&yacute; do hợp l&yacute; v&agrave; th&ocirc;ng b&aacute;o cho Ch&uacute;ng t&ocirc;i theo th&ocirc;ng tin li&ecirc;n hệ của Ch&uacute;ng t&ocirc;i được tr&igrave;nh b&agrave;y b&ecirc;n dưới.</li>\n' +
				     '<li>Đăng k&yacute; v&agrave; quyền th&agrave;nh vi&ecirc;n: Hệ thống n&agrave;y cho ph&eacute;p qu&yacute; kh&aacute;ch h&agrave;ng tạo t&agrave;i khoản người d&ugrave;ng dựa tr&ecirc;n dữ liệu qu&yacute; kh&aacute;ch h&agrave;ng cung cấp. Bằng c&aacute;ch cung cấp dữ liệu, đăng k&yacute; v&agrave; tạo t&agrave;i khoản của qu&yacute; kh&aacute;ch h&agrave;ng, qu&yacute; kh&aacute;ch h&agrave;ng đảm bảo rằng:</li>\n' +
				     '<li>a) Qu&yacute; kh&aacute;ch h&agrave;ng đ&atilde; đủ 18 tuổi;</li>\n' +
				     '<li>b) Th&ocirc;ng tin về qu&yacute; kh&aacute;ch h&agrave;ng l&agrave; đ&uacute;ng v&agrave; ch&iacute;nh x&aacute;c, ở thời điểm hiện tại v&agrave; đầy đủ theo y&ecirc;u cầu trong mẫu đăng k&yacute; tr&ecirc;n Hệ thống "Dữ liệu đăng k&yacute;" v&agrave; Qu&yacute; kh&aacute;ch h&agrave;ng sẽ cập nhật Dữ liệu đăng k&yacute; n&agrave;y để duy tr&igrave; t&iacute;nh ch&iacute;nh x&aacute;c v&agrave; đầy đủ.</li>\n' +
				     '</ol>\n' +
				     '<p>Th&ocirc;ng tin li&ecirc;n hệ của FUTA Bus Lines: Nếu qu&yacute; kh&aacute;ch h&agrave;ng c&oacute; bất kỳ c&acirc;u hỏi hoặc y&ecirc;u cầu n&agrave;o li&ecirc;n quan đến Ch&iacute;nh s&aacute;ch Bảo mật n&agrave;y, vui l&ograve;ng li&ecirc;n hệ với Ch&uacute;ng t&ocirc;i qua: hotro@futabus.vn hoặc gọi đến số điện thoại 1900 6067.</p>'
		     }}/>
	</div>
);

export default PolicyFutaBusline;