// src/components/Home.js
import React, {useEffect} from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {Link, useLocation} from "react-router-dom";
import {handleScrollToHash} from "../../services/services";
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const FAQInfo = () => {
	
	const location = useLocation();
	const {hash} = location || {};
	useEffect(() => {
		handleScrollToHash({sectionId: hash.substring(1)});
	}, [hash]);
	
	return (
		<div className="mx-auto w-full animate-fadeIn text-sm">
			<FAQBanner title='Thông tin cơ bản'/>
			<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
				<NewLink to={PATH.FAQ} className='hidden md:block'>
					<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
				</NewLink>
				<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>Thông tin cơ bản</h2>
			</div>
			<div className='px-8 md:px-[256px] py-8'>
				<ol>
					<li><strong>C&aacute;ch li&ecirc;n hệ với GooPay</strong></li>
					<p>Bạn c&oacute; thể li&ecirc;n lạc với bộ phận Chăm S&oacute;c Kh&aacute;ch H&agrave;ng (CSKH) qua một trong
						c&aacute;c k&ecirc;nh sau:</p>
					<ol>
						<li>Gửi y&ecirc;u cầu hỗ trợ tr&ecirc;n mục Trợ gi&uacute;p của ứng dụng GooPay (Đề xuất)</li>
						<li>Li&ecirc;n hệ theo Hotline: 19006038</li>
						<li>Gửi y&ecirc;u cầu đến email <u style={{color: '#0073DD'}}>cskh@goopay.vn</u></li>
					</ol>
				</ol>
				
				
				<ol start="2">
					<li><strong>Sử dụng GooPay c&oacute; mất ph&iacute; kh&ocirc;ng ?</strong></li>
				</ol>
				<p>Bạn y&ecirc;n t&acirc;m v&igrave; khi sử dụng V&iacute; GooPay bạn sẽ kh&ocirc;ng cần phải lo lắng về
					ph&iacute; duy tr&igrave; hay bất kỳ khoản ph&iacute; ẩn n&agrave;o. Tuy nhi&ecirc;n, c&oacute; một v&agrave;i
					trường hợp đặc biệt c&oacute; thể ph&aacute;t sinh ph&iacute;, cụ thể l&agrave;:</p>
				<p>Ph&iacute; r&uacute;t tiền về ng&acirc;n h&agrave;ng: Miễn ph&iacute; nếu tổng số tiền bạn r&uacute;t trong
					th&aacute;ng kh&ocirc;ng qu&aacute; 30 triệu đồng.</p>
				<p>Nếu vượt qu&aacute; 30 triệu đồng trong th&aacute;ng, ph&iacute; sẽ l&agrave; 0,5% nh&acirc;n với
					gi&aacute; trị giao dịch.</p>
				<p>Nếu vượt qu&aacute; 100 triệu đồng trong th&aacute;ng, ph&iacute; sẽ l&agrave; 1% nh&acirc;n với
					gi&aacute; trị giao dịch.</p>
				<p>Ngo&agrave;i ra, một số dịch vụ đặc biệt kh&aacute;c tr&ecirc;n V&iacute; GooPay cũng c&oacute; thể
					c&oacute; ph&iacute; ri&ecirc;ng.</p>
				<p>Bạn n&ecirc;n kiểm tra kỹ th&ocirc;ng tin trước khi sử dụng.</p>
				<p>Nếu c&oacute; bất kỳ thắc mắc n&agrave;o kh&aacute;c về ph&iacute; dịch vụ, đừng ngần ngại li&ecirc;n hệ với
					GooPay qua hotline hỗ trợ 19006038. GooPay lu&ocirc;n sẵn s&agrave;ng giải đ&aacute;p mọi c&acirc;u hỏi của
					bạn!</p>
				<ol start="3">
					<li><strong>Thanh to&aacute;n bằng Vi GooPay c&oacute; được chiết khấu kh&ocirc;ng ?</strong></li>
				</ol>
				<p>Nạp tiền điện thoại v&agrave; mua m&atilde; thẻ di động tr&ecirc;n GooPay lu&ocirc;n được chiết khấu
					tr&ecirc;n gi&aacute; trị giao dịch l&agrave; 3% với nh&agrave; mạng Viettel v&agrave; 5% với c&aacute;c
					nh&agrave; mạng Mobifone, Vinaphone, Vietnammobile, Gmobile.</p>
				<p>Thanh to&aacute;n c&aacute;c dịch vụ kh&aacute;c tr&ecirc;n GooPay c&oacute; thể c&oacute; hoặc kh&ocirc;ng
					c&oacute; chiết khấu t&ugrave;y v&agrave;o nh&agrave; cung cấp dịch vụ v&agrave; t&ugrave;y từng thời
					điểm.</p>
				<ol start="4">
					<li><strong>Hạn mức giao dịch mỗi ng&agrave;y ?</strong></li>
					<p>Hạn mức giao dịch của t&agrave;i khoản GooPay t&ugrave;y thuộc v&agrave;o t&igrave;nh trạng t&agrave;i
						khoản
						đ&atilde; được x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng hay chưa. Cụ thể:</p>
					<ol>
						<li>T&agrave;i khoản GooPay c&oacute; x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng:</li>
						
						<ul>
							<li>Số dư trong GooPay tối đa: 200.000.000đ</li>
							<li>	Nội dung khung tô đỏ chỉnh thành: Số tiền nạp vào GooPay tối đa 50.000.000vnđ/ngày bằng hình thức nạp tiền từ Ngân Hàng tối đa 100.000.000đ/ng&agrave;y bằng
								h&igrave;nh thức Chuyển tiền V&iacute; -V&iacute;</li>
							<li>Số tiền r&uacute;t ra khỏi GooPay hoặc thanh to&aacute;n tr&ecirc;n GooPay tối đa
								50.000.000đ/ng&agrave;y
							</li>
							<li>Kh&ocirc;ng giới hạn số lượt giao dịch c&oacute; thể thực hiện</li>
						</ul>
						<li>T&agrave;i khoản GooPay chưa x&aacute;c thực bằng thẻ/t&agrave;i khoản ng&acirc;n h&agrave;ng:
							T&agrave;i
							khoản n&agrave;y kh&ocirc;ng thể sử dụng nguồn tiền GooPay trong v&iacute;.
						</li>
					</ol>
				</ol>
				
				
				<ul>
					<li>Hạn mức thanh to&aacute;n bằng thẻ: tối đa 10.000.000đ/giao dịch/ng&agrave;y</li>
					<li>Kh&ocirc;ng giới hạn số lượt giao dịch c&oacute; thể thực hiện</li>
				</ul>
				<p>Bạn c&oacute; thể mở hạn mức giao dịch bằng c&aacute;ch li&ecirc;n kết v&iacute; GooPay với thẻ/t&agrave;i
					khoản ng&acirc;n h&agrave;ng, xem hướng dẫn c&aacute;ch li&ecirc;n kết tại đ&acirc;y.</p>
				<ol start="5">
					<li><strong>Tiền trong t&agrave;i khoản GooPay c&oacute; phải tiền thật ?</strong></li>
				</ol>
				<p>Tiền trong t&agrave;i khoản GooPay c&oacute; gi&aacute; trị như tiền mặt v&agrave; được bảo chứng 100% bởi
					c&aacute;c Ng&acirc;n H&agrave;ng đang hợp t&aacute;c với V&iacute; GooPay. Bạn ho&agrave;n to&agrave;n
					c&oacute; thể nạp/ r&uacute;t tiền dễ d&agrave;ng qua t&agrave;i khoản của c&aacute;c ng&acirc;n h&agrave;ng
					li&ecirc;n kết.</p>
				<ol start="6">
					<li><strong>GooPay l&agrave; g&igrave; ?</strong></li>
				</ol>
				<p>Ứng dụng V&iacute; điện tử GooPay l&agrave; sản phẩm của C&ocirc;ng ty cổ phần Thanh To&aacute;n Trực Tuyến
					To&agrave;n Cầu (GooPay) . Ứng dụng cho ph&eacute;p bạn tạo v&agrave; nạp tiền v&agrave;o t&agrave;i khoản
					GooPay để thanh to&aacute;n cho hơn 50 dịch vụ như mua v&eacute; xe kh&aacute;ch Futa BusLines, thanh
					to&aacute;n Futa Express, nạp tiền điện thoại, thanh to&aacute;n điện nước, thanh to&aacute;n vay ti&ecirc;u
					d&ugrave;ng, v.v...</p>
				<p>GooPay l&agrave; đối t&aacute;c chiến lược của Vietcombank, OCB, VPBank, Vietinbank, TPBank, ACB, Eximbank,
					Sacombank, VIB, BIDV v&agrave; Shinhan Bank. Từ ng&agrave;y 25/11/2021, GooPay được Ng&acirc;n h&agrave;ng
					Nh&agrave; nước Việt Nam cấp giấy ph&eacute;p cung cấp dịch vụ V&iacute; điện tử số 16/GP-NHNN.</p>
				<ol start="7">
					<li><strong>GooPay cũng c&oacute; những dịch vụ g&igrave; ?</strong></li>
					<p>"GooPay c&oacute; hơn 50 dịch vụ cho bạn tha hồ lựa chọn như:</p>
					<ol>
						<li>Chuyển tiền cho người kh&aacute;c qua số điện thoại</li>
						<li>Mua v&eacute; xe li&ecirc;n tỉnh FUTA Bus Line ( Phương Trang)</li>
						<li>Thanh to&aacute;n chuyển ph&aacute;t nhanh Phương Trang FUTA</li>
						<li>Mua v&eacute; xe Bus Phương Trang</li>
						<li>Giao dịch tr&ecirc;n s&agrave;n funong.vn</li>
						<li>Nạp tiền điện thoại, mua m&atilde; thẻ di động</li>
						<li>Thanh to&aacute;n điện nước</li>
					</ol>
				</ol>
				
				<ol start="8">
					<li><strong>V&iacute; điện tử l&agrave; g&igrave; ?</strong></li>
				</ol>
				<p>"V&iacute; điện tử hay c&ograve;n được gọi l&agrave; V&iacute; tiền trực tuyến (online) l&agrave; một
					t&agrave;i khoản thanh to&aacute;n c&aacute;c giao dịch trực tuyến phổ biến nhất hiện nay như: mua
					v&eacute; xe kh&aacute;ch, thanh to&aacute;n tiền điện, nước, học ph&iacute;, nạp tiền điện thoại (Top Up),
					mua v&eacute; xe bus, mua sắm trực tuyến,...</p>
				<p>Chức năng hoạt động của V&iacute; điện tử thực hiện bằng c&aacute;ch Li&ecirc;n kết T&agrave;i Khoản
					ng&acirc;n h&agrave;ng (Xem danh s&aacute;ch ng&acirc;n h&agrave;ng), nạp tiền v&agrave;o
					V&iacute; v&agrave; thanh to&aacute;n bất k&igrave; dịch vụ c&oacute; li&ecirc;n kết một c&aacute;ch đơn giản,
					tiện lợi.</p>
				<p>Chỉ cần bạn c&oacute; Smartphone, mạng Wifi v&agrave; t&agrave;i khoản đ&atilde; c&oacute; thể thực hiện mọi
					giao dịch ở mọi nơi.</p>
				<ol start="9">
					<li><strong>Sử dụng GooPay c&oacute; an to&agrave;n ?</strong></li>
					<p>Bạn ho&agrave;n to&agrave;n y&ecirc;n t&acirc;m khi sử dụng GooPay, v&igrave; 4 l&yacute; do:</p>
					<ol>
						<li>Bảo chứng bởi ng&acirc;n h&agrave;ng: Tiền trong t&agrave;i khoản GooPay được bảo chứng bởi BIDV
							v&agrave; ng&acirc;n h&agrave;ng li&ecirc;n kết với t&agrave;i khoản của bạn. được Ng&acirc;n h&agrave;ng
							Nh&agrave; nước cấp giấy ph&eacute;p v&agrave; quản l&yacute;.
						</li>
						<li>Mạng lưới bảo mật đa tầng: Mỗi bước giao dịch của bạn đều được GooPay m&atilde; h&oacute;a v&agrave; bảo
							vệ ngay tức th&igrave;. GooPay tự kh&oacute;a ứng dụng nếu kh&ocirc;ng được sử dụng trong v&ograve;ng 5
							ph&uacute;t v&agrave; sử dụng c&ocirc;ng nghệ th&ocirc;ng minh để tự động nhận diện v&agrave; ngăn chặn
							giao
							dịch đ&aacute;ng ngờ.
						</li>
						<li>Hỗ trợ đa k&ecirc;nh 24/7: Trung t&acirc;m Chăm s&oacute;c kh&aacute;ch h&agrave;ng của GooPay sẵn
							s&agrave;ng phục vụ 24/7, kể cả ng&agrave;y nghỉ để hỗ trợ kh&aacute;ch h&agrave;ng.
						</li>
					</ol>
				</ol>
				
				
				<ol start="10">
					<li><strong>Quy tr&igrave;nh khiếu nại với bộ phận CSKH</strong></li>
				</ol>
				<p>"Bước 1: Tạo y&ecirc;u cầu hỗ trợ/ khiếu nại</p>
				<p>Xem ngay tại:........</p>
				<p>Bước 2: CSKH tiếp nhận th&ocirc;ng tin v&agrave; xử l&yacute;</p>
				<p>Khi tiếp nhận th&ocirc;ng tin, nh&acirc;n vi&ecirc;n CSKH c&oacute; thể y&ecirc;u cầu bạn cung cấp c&aacute;c
					th&ocirc;ng tin li&ecirc;n quan t&ugrave;y theo bản chất khiếu nại của bạn. Xin lưu &yacute; rằng CSKH sẽ
					kh&ocirc;ng bao giờ y&ecirc;u cầu bạn cung cấp số OTP v&agrave; mật khẩu t&agrave;i khoản GooPay.</p>
				<p>Kể từ l&uacute;c bạn gửi y&ecirc;u cầu hỗ trợ/ khiếu nại, CSKH sẽ xử l&yacute; trong v&ograve;ng 24 giờ. Tuy
					nhi&ecirc;n trong một số trường hợp khi vấn đề khiếu nại cần th&ecirc;m thời gian để xử l&yacute;, nh&acirc;n
					vi&ecirc;n CSKH sẽ th&ocirc;ng b&aacute;o với bạn về thời gian dự kiến ho&agrave;n th&agrave;nh.</p>
				<p>Bước 3: CSKH phản hồi kết quả</p>
				<p>Bộ phận CSKH sẽ chủ động li&ecirc;n hệ bạn theo 1 trong 3 h&igrave;nh thức sau:</p>
				<ol>
					<li>Gửi tin nhắn tr&ecirc;n ứng dụng GooPay</li>
					<li>Gửi tin nhắn SMS tới số điện thoại bạn sử dụng để li&ecirc;n hệ CSKH</li>
					<li>Gọi điện thoại trực tiếp đến bạn</li>
				</ol>
				<ol start="11">
					<li><strong>Tải ứng dụng V&iacute; GooPay</strong></li>
				</ol>
				<p>T&igrave;m từ kh&oacute;a "GooPay" tr&ecirc;n App Store hoặc Google Play Store, v&agrave; l&agrave;m theo
					hướng dẫn.</p>
				<ol start="12">
					<li><strong>T&agrave;i khoản GooPay l&agrave; g&igrave; ?</strong></li>
				</ol>
				<p>Số t&agrave;i khoản GooPay ch&iacute;nh l&agrave; số điện thoại bạn đ&atilde; d&ugrave;ng để đăng
					k&iacute; t&agrave;i khoản GooPay đ&oacute;. Mỗi t&agrave;i khoản GooPay tương ứng với duy nhất 1 số điện
					thoại, v&agrave; mỗi số điện thoại cũng chỉ đăng k&yacute; được duy nhất 1 t&agrave;i khoản GooPay.</p>
				<ol start="13">
					<li><strong>Dịch vụ li&ecirc;n kết l&agrave; g&igrave;?</strong></li>
				</ol>
				<p>Dịch vụ li&ecirc;n kết l&agrave; luồng thanh to&aacute;n cho ph&eacute;p kh&aacute;ch h&agrave;ng thiết lập
					một lần duy nhất giữa V&iacute; GooPay v&agrave; nh&agrave; cung cấp dịch vụ. C&aacute;c th&ocirc;ng tin thanh
					to&aacute;n sẽ được m&atilde; h&oacute;a v&agrave; lưu lại cho c&aacute;c lần giao dịch tiếp theo
					v&agrave; người d&ugrave;ng sẽ kh&ocirc;ng cần phải x&aacute;c nhận sau mỗi giao dịch như luồng thanh
					to&aacute;n th&ocirc;ng thường.</p>
				<p>Việc li&ecirc;n kết V&iacute; GooPay cũng tương tự như việc lưu thẻ ng&acirc;n h&agrave;ng l&agrave;m phương
					thức thanh to&aacute;n tr&ecirc;n c&aacute;c trang thương mại điện tử, đặt v&eacute; xe kh&aacute;ch, v.v.,
					gi&uacute;p cho việc thanh to&aacute;n trở n&ecirc;n nhanh ch&oacute;ng v&agrave; dễ d&agrave;ng hơn.</p>
				<p>Khi đ&atilde; li&ecirc;n kết V&iacute; GooPay, người d&ugrave;ng cần quản l&yacute; c&aacute;c dịch vụ gia
					hạn tự động (nếu c&oacute;), duy tr&igrave; số dư, v&agrave; điều chỉnh hạn mức ph&ugrave; hợp với nhu cầu
					thanh to&aacute;n, mua sắm.</p>
				<ol start="14">
					<li><strong>C&aacute;c c&aacute;ch quản l&yacute; dịch vụ li&ecirc;n kết ?</strong></li>
				</ol>
				<p>Với c&aacute;c dịch vụ li&ecirc;n kết, bạn c&oacute; thể điều chỉnh hạn mức ph&ugrave; hợp với nhu cầu mua
					sắm, tạm dừng, hoặc hủy li&ecirc;n kết bằng c&aacute;ch v&agrave;o V&iacute; của t&ocirc;i &gt; Dịch vụ
					li&ecirc;n kết.</p>
				<p>Khi tạm dừng li&ecirc;n kết, nh&agrave; cung cấp dịch vụ sẽ kh&ocirc;ng thể tiếp tục y&ecirc;u cầu thanh
					to&aacute;n qua V&iacute; GooPay. Đ&acirc;y l&agrave; c&aacute;ch nhanh nhất để dừng c&aacute;c khoản thanh
					to&aacute;n kh&ocirc;ng mong muốn. Bạn cũng c&oacute; thể tiếp tục li&ecirc;n kết bất cứ l&uacute;c
					n&agrave;o.</p>
				<p>Khi huỷ li&ecirc;n kết, nh&agrave; cung cấp dịch vụ sẽ kh&ocirc;ng thể tiếp tục y&ecirc;u cầu thanh
					to&aacute;n qua V&iacute; GooPay. Nếu muốn sử dụng GooPay l&agrave;m phương thức thanh to&aacute;n, bạn sẽ cần
					thiết lập lại li&ecirc;n kết từ ph&iacute;a nh&agrave; cung cấp dịch vụ.</p>
				<ol start="15">
					<li><strong>L&agrave;m thế n&agrave;o để biết giao dịch đ&atilde; th&agrave;nh c&ocirc;ng?</strong></li>
				</ol>
				<p>GooPay sẽ gửi th&ocirc;ng b&aacute;o ngay sau khi giao dịch th&agrave;nh c&ocirc;ng. Bạn c&oacute; thể xem
					lại th&ocirc;ng b&aacute;o khi nhấp v&agrave;o biểu tượng Quả chu&ocirc;ng tại g&oacute;c phải tr&ecirc;n
					c&ugrave;ng ở m&agrave;n h&igrave;nh ch&iacute;nh.</p>
				<p>Đồng thời bạn c&oacute; thể kiểm tra số dư t&agrave;i khoản hoặc v&agrave;o mục ""Lịch sử giao dịch"" từ
					m&agrave;n h&igrave;nh ch&iacute;nh để biết ch&iacute;nh x&aacute;c kết quả giao dịch của m&igrave;nh.</p>
				<ol start="16">
					<li><strong>C&aacute;ch nạp tiền v&agrave;o GooPay</strong></li>
					<p>Bạn c&oacute; thể nạp tiền v&agrave;o t&agrave;i khoản GooPay th&ocirc;ng qua c&aacute;c h&igrave;nh thức
						sau
						:</p>
					<ol>
						<li>Từ t&agrave;i khoản của c&aacute;c ng&acirc;n h&agrave;ng li&ecirc;n kết với GooPay: Vietcombank,
							Vietinbank, Agribank, BIDV, ACB, TPBank, VPBank, Eximbank, OCB, VIB, Shinhan Bank, SCB, Thẻ nội địa
							Sacombank, VRB, BAOVIET Bank, ABBANK, OceanBank, MBBank, PVcomBank, VietCapital Bank, Nam &Aacute; Bank,
							SHB, Bac A Bank, HDBank, SAIGONBANK (Ho&agrave;n to&agrave;n miễn ph&iacute;)
						</li>
						<li>Từ thẻ ATM của 30 ng&acirc;n h&agrave;ng thuộc hệ thống Napas. (Ho&agrave;n to&agrave;n miễn
							ph&iacute;.)<br/><em><u>Lưu &yacute;</u></em>: với 2 h&igrave;nh thức tr&ecirc;n bạn cần đăng
							k&yacute; dịch
							vụ thanh to&aacute;n trực tuyến (Internet Banking) &amp; sử dụng phương thức x&aacute;c thực bằng SMS của
							ng&acirc;n h&agrave;ng.
						</li>
						<li>Nhận tiền từ 1 t&agrave;i khoản GooPay kh&aacute;c chuyển đến t&agrave;i khoản GooPay của bạn.</li>
					</ol>
				</ol>
				
				
				<ol start="17">
					<li><strong>L&agrave;m thế n&agrave;o để kiểm tra th&ocirc;ng tin đơn h&agrave;ng v&agrave; t&igrave;m trợ
						gi&uacute;p sau khi mua h&agrave;ng?</strong></li>
				</ol>
				<p>Bạn c&oacute; thể v&agrave;o phần Lịch sử GD, chọn giao dịch li&ecirc;n quan bằng c&aacute;ch sử
					dụng &ocirc; t&igrave;m kiếm hoặc bộ lọc để thao t&aacute;c nhanh hơn, chọn phần Trợ gi&uacute;p ở cuối để
					giải đ&aacute;p c&aacute;c vấn đề thường gặp. Tại đ&acirc;y bạn cũng c&oacute; thể gửi y&ecirc;u cầu trợ
					gi&uacute;p nếu cần hỗ trợ th&ecirc;m.</p>
				<ol start="18">
					<li><strong>R&uacute;t tiền từ GooPay về t&agrave;i khoản ng&acirc;n h&agrave;ng li&ecirc;n kết c&oacute; mất
						ph&iacute; kh&ocirc;ng?</strong></li>
				</ol>
				<p>Khi r&uacute;t tiền từ GooPay về t&agrave;i khoản Ng&acirc;n h&agrave;ng li&ecirc;n kết, bạn sẽ được MIỄN
					PH&Iacute; r&uacute;t tiền cho 10 GIAO DỊCH R&Uacute;T TIỀN ĐẦU TI&Ecirc;N TRONG TH&Aacute;NG, tối đa đến 50
					triệu đồng/th&aacute;ng.</p>
				<p>Với giao dịch R&uacute;t tiền từ lần thứ 11 (hoặc hết hạn mức) sẽ t&iacute;nh mức ph&iacute; 3.300Đ + 0,65% x
					Gi&aacute; trị giao dịch.</p>
				<ol start="19">
					<li><strong>V&igrave; sao cần x&aacute;c thực email ?</strong></li>
				</ol>
				<p>Sau bước tạo t&agrave;i khoản với GooPay, GooPay sẽ gửi email đến địa chỉ email đ&atilde; nhập của bạn để
					x&aacute;c nhận đ&acirc;y đ&uacute;ng l&agrave; địa chỉ email của bạn. Sau khi đ&atilde; x&aacute;c thực
					email, bạn sẽ nhận được c&aacute;c th&ocirc;ng tin li&ecirc;n quan tới bảo mật t&agrave;i khoản trong
					c&aacute;c trường hợp như:</p>
				<ul>
					<li>Cảnh b&aacute;o t&agrave;i khoản được đăng nhập tr&ecirc;n thiết bị kh&aacute;c</li>
					<li>Lấy lại mật khẩu khi bạn c&oacute; y&ecirc;u cầu</li>
					<li>Th&ocirc;ng tin kết quả giao dịch</li>
				</ul>
			</div>
		</div>
	)
};

export default FAQInfo;
