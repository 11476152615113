import React from 'react';
import { VARIANT } from '../../../constants';
import cn from 'classnames';



const Button = ({ children, onClick, className, variant }) => {
  let variantClass = '';
  if(variant === VARIANT.PRIMARY) {
    variantClass += 'bg-primary text-white';
  } else if(variant === VARIANT.SECONDARY) {
    variantClass += 'bg-secondary text-white';
  } else if(variant === VARIANT.OUTLINE) {
    variantClass += 'border-primary text-primary';
  }  /*else if(variant === VARIANT.GRADIENT) {
    variantClass += 'gradient-box';
  }
  */
  return (
    <button className={cn('relative px-3 py-2.5 rounded-md w-fit', variantClass, className)} onClick={onClick}>
      {variant === VARIANT.GRADIENT && <i className="absolute w-1 h-5 bg-[#01B54F] left-0 rounded-r-md" />}
      {variant === VARIANT.GRADIENT && <i className='absolute w-1 h-5 bg-[#F05A24] right-0 rounded-l-md' />}
      {children}
    </button>
  )
}

export default Button