import React from 'react';
import './style.css';
import cn from 'classnames';


const Underline = ({className, style}) => {
  
  return (
    <div className={cn('color-line', className)} style={style}>
      <div className="color-1"></div>
      <div className="color-2"></div>
      <div className="color-3"></div>
    </div>
  )
}

export default Underline