import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';

const data = [
    {
        id: 1,
        title: 'Tại giao diện chính, chọn icon “Nạp / Rút tiền”',
    },
    {
        id: 2,
        title: 'Chọn “Nạp tiền vào ví”',
    },
    {id: 3, title: 'Nhập số tiền cần nạp và nhấn “Tiếp tục”',},
    {
        id: 4, title: 'Xác nhận thông tin nạp tiền.',
        subtitle: ['Bạn có thể thay đổi nguồn nạp tiền bằng cách chọn “Thay đổi” <br/>Nguồn tiền là các ngân hàng bạn đã liên kết trước đó']
    },
    {id: 5, title: 'Nhập mật khẩu thanh toán để xác nhận giao dịch'},
];

const TopUpContent = ({setStep}) => {
    return (
        <div className="w-full max-w-4xl py-4 px-2 md:px-4 mx-auto">
            <FeatureRichList data={data} setStep={setStep}/>
        </div>
    );
};

export default TopUpContent;