import React from 'react';
import FeatureRichList from '../Common/RichList/FeatureRichList';


const data = [
    {
        id: 1,
        title: 'Tải ứng dụng miễn phí bằng cách tìm kiếm từ khóa "GooPay" trên Apple Store hoặc Google Play',
    },
    {
        id: 2,
        title: 'Mở ứng dụng GooPay và chọn "Đăng ký/ Đăng nhập"',
        subtitle: ['Nhập số điện thoại và xác nhận mã OTP gửi qua tin nhắn'],
    },
    {id: 3, title: 'Tạo mật khẩu và bổ sung thông tin cá nhân'},
    {id: 4, title: 'Đăng ký thành công & Hoàn tất đăng ký'},
    {id: 5, title: 'Đăng nhập bằng số điện thoại và bắt đầu sử dụng'},
];

const RegisterLoginContent = ({setStep}) => {
    return (
        <div className="w-full max-w-4xl mx-auto py-4 px-2 md:px-4 flex justify-center">
            <FeatureRichList data={data} setStep={setStep}/>
        </div>
    );
};

export default RegisterLoginContent;