// src/hooks/useClickOutside.js
import { useEffect } from 'react';

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const faBars = document.getElementById('FaBars');
      const faTimes = document.getElementById('FaTimes');
      
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      } else if (faBars && faBars.contains(event.target)) {
        return;
      } else if (faTimes && faTimes.contains(event.target)) {
        return;
      }
      handler(event);
    };
    
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;