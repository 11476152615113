import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import Banner from './index';

const FAQBanner = ({ title }) => {
	const banner = {
		id: 'main-banner-1',
		image: '/img/faq/banner.png',
		imageMb: '/img/faq/banner-mobile.png',
		title: 'Hỏi & Đáp',
	};
	return (
		<Banner {...banner}>
			<div className="absolute top-0 md:top-[-60px] w-full h-full flex flex-col md:justify-center px-10 pt-4">
				<p className="text-center font-bold text-xl md:text-3xl md:w-[400px] md:ml-40">{title || banner.title}</p>
			</div>
		</Banner>
	);
};

export default FAQBanner;