import React from 'react';
import Button from '../Common/Button';
import {PATH, VARIANT} from '../../constants';
import NewLink from "../NewLink/NewLink";

const FeatureBlock = () => {
	const features = [
		{
			icon: '/img/faq-user.png',
			title: 'Tài khoản Ví GooPay là gì?',
			questIdEl: 'cau-12',
			description: 'Chính là số điện thoại bạn đã dùng để đăng kí tài khoản GooPay, mỗi số điện thoại chỉ đăng ký được duy nhất 1 tài khoản GooPay.',
		},
		{
			icon: '/img/faq-dollar.png',
			title: 'Tiền trong tài khoản GooPay có phải là tiền thật ?',
			questIdEl: 'cau-5',
			description: 'Tiền trong tài khoản GooPay có giá trị như tiền mặt và được bảo chứng 100% bởi các Ngân Hàng đang hợp tác với Ví GooPay. ',
		},
		{
			icon: '/img/faq-fee.png',
			title: 'Sử dụng GooPay có mất phí không?',
			questIdEl: 'cau-2',
			description: 'Bạn yên tâm vì khi sử dụng Ví GooPay bạn sẽ không cần phải lo lắng về phí duy trì hay bất kỳ khoản phí ẩn nào.',
		},
		{
			icon: '/img/faq-point.png',
			title: 'Hạn mức giao dịch mỗi ngày?',
			questIdEl: 'cau-4',
			description: 'Hạn mức giao dịch của tài khoản GooPay tùy thuộc vào tình trạng tài khoản đã được xác thực bằng thẻ/ tài khoản ngân hàng hay chưa',
		},
	];
	
	return (
		<div className="p-8 md:px-[120px] rounded-xl bg-[#FAFAFA]">
			<h2 className="text-xl md:text-2xl font-bold text-center mb-8 pt-4 uppercase">Các câu hỏi thường gặp</h2>
			<div className="flex flex-col lg:flex-row gap-10">
				<div className="lg:w-[450px]">
					<img
						src="/img/faq-phone.png"
						alt="faq-phone"
						className="w-full h-auto object-cover rounded-lg"
					/>
				</div>
				<div className="px-8 lg:w-[60%] grid grid-cols-1 md:grid-cols-2 gap-2">
					{features.map((feature, index) => (
						<div
							key={index}
							className="px-2 rounded-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-green-50"
						>
							<div className="text-2xl mb-2.5">
								<img src={feature.icon} alt={feature.title}/>
							</div>
							<h3 className="text-base font-semibold mb-2 text-gray-800">{feature.title}</h3>
							<p className="text-sm text-gray-600 mb-2.5">{feature.description}</p>
							<NewLink
								className="flex gap-2 text-sm focus:underline text-primary items-center"
								to={`${PATH.FAQ_INFO}#${feature.questIdEl}`}
							>
								<span>Xem chi tiết</span><img src="/img/icon/expand_more_24px.png" alt="arrow-right"/>
							</NewLink>
						</div>
					))}
				</div>
			</div>
			<div className="w-full text-center">
				<NewLink to={PATH.FAQ}>
					<Button variant={VARIANT.PRIMARY}>
						Xem tất cả
					</Button>
				</NewLink>
			</div>
		</div>
	);
};

export default FeatureBlock;