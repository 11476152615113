import React from 'react';
import InfoImageBlock from '../components/Common/InfoImageBlock';
import AboutUsBanner from '../components/Banner/AboutUsBanner';
import Button from "../components/Common/Button";
import {PATH, VARIANT} from "../constants";
import NewLink from "../components/NewLink/NewLink";

const About = () => (
	<div className="mx-auto w-full animate-fadeIn">
		<AboutUsBanner/>
		<InfoImageBlock
			title="Sứ mệnh"
			position="right"
			bgClass="bg-white"
			textClass="md:w-[700px]"
			description={
				`<p>GooPay ra đời với sứ mệnh cung cấp những giải pháp tài chính chuyên biệt giúp giải quyết các vấn đề thanh toán khi di chuyển và chuyển phát hàng hóa của khách hàng trở nên thuận tiện và nhanh chóng trong bối cảnh toàn cầu hóa – hiện đại hóa.<p/>`
			}
			image="/img/about-us/ab1.png"
		/>
		<InfoImageBlock
			title="Tầm nhìn"
			textClass="md:w-[700px]"
			description={
				`<p>Cung cấp dịch vụ tài chính đa năng, dẫn đầu về năng lực kiến tạo các giá trị trong ngành vận tải nói riêng thông qua các giải pháp tài chính toàn diện với
					sức mạnh tổng hợp của hệ sinh thái cũng như ứng dụng công nghệ tiên tiến, nhằm đem lại trải nghiệm tốt nhất cho khách hàng.
				</p>`}
			image="/img/about-us/ab2.png"
		/>
		<InfoImageBlock
			position="right"
			bgClass="bg-white"
			title="Giá trị cốt lõi"
			textClass="md:w-[700px]"
			heightClass='h-[600px]'
			description={
				`	<p><b>1. Chính trực:</b> GooPay tin rằng đây là phẩm chất đạo đức quan trọng nhất để xây dựng nền móng cho một tập thể giàu mạnh. Mọi hành động của GooPay luôn minh bạch, chính trực và tôn trọng tất cả khách hàng, đối tác, nhân viên.<p/>
					<p><b>2. Hiệu quả: </b> GooPay cung cấp dịch vụ thanh toán nhanh chóng, đơn giản và an toàn bằng những sản phẩm thân thiện và dễ sử dụng, đáp ứng tất cả nhu cầu của mọi nhà.<p/>
					<p><b>3. Khát vọng:</b> GooPay luôn khát vọng sự đổi mới, luôn phấn đấu để phát triển nhằm cung cấp đến khách hàng những sản phẩm tân tiến và toàn diện.<p/>
				`
			}
			image="/img/about-us/ab3.png"
		/>
		
		<div className='flex flex-col justify-center items-center gap-5 bg-[#FAFAFA] py-8'>
			Tham gia cùng chúng tôi
			<NewLink to={PATH.HIRING}>
				<Button className='w-[250px] h-[40px]' variant={VARIANT.PRIMARY}>
					Xem thông tin tuyển dụng
				</Button>
			</NewLink>
		</div>
	</div>
);

export default About;