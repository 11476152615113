// src/pages/Contact.js
import React, {useState} from 'react';
import axios from 'axios';
import ContactBanner from '../components/Banner/ContactBanner';
import Button from '../components/Common/Button';
import {CONTACT_SHEET, PATH, VARIANT} from '../constants';
import Popup from "../components/Popup";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {isValidPhone, validateEmail} from "../services/services";
import cn from "classnames";
import NewLink from "../components/NewLink/NewLink";

const Contact = () => {
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [error, setError] = useState({
		email: false,
		phone: false
	});
	const [contact, setDataContact] = useState({
		Name: '',
		Email: '',
		Phone: '',
		Content: ''
	});
	
	const submitHandler = e => {
		e.preventDefault();
		if (checkContact(contact)) {
			axios.post(CONTACT_SHEET.API_URL, contact)
				.then(response => {
					if (response?.status === 200) {
						setIsPopupOpen(true);
						setDataContact({
							Name: '',
							Email: '',
							Phone: '',
							Content: ''
						});
					}
				})
				.catch(error => {
					toast.error('Đã xảy ra lỗi khi gửi thông tin.', {position: 'bottom-right'});
				});
		}
	};
	
	const checkContact = (dataContact) => {
		let valid = true;
		if (!dataContact?.Name) {
			valid = false;
			toast.info('Vui lòng nhập tên', {position: 'bottom-right'});
		} else if (!dataContact?.Email) {
			valid = false;
			toast.info('Vui lòng nhập email', {position: 'bottom-right'});
		} else if (!dataContact?.Phone) {
			valid = false;
			toast.info('Vui lòng nhập số điện thoại', {position: 'bottom-right'});
		} else if (!validateEmail(dataContact?.Email)) {
			valid = false;
			setError({...error, email: true});
		} else if (!isValidPhone(dataContact?.Phone)) {
			valid = false;
			setError({...error, phone: true});
		}
		
		
		return valid;
	};
	
	const setContact = (key, value) => {
		setDataContact({...contact, [key]: value});
		setError({email: false, phone: false});
	};
	
	
	return (
		<div className="mx-auto w-full animate-fadeIn">
			<ContactBanner/>
			<div className='flex flex-col md:flex-row justify-center gap-8 my-[60px] mx-8'>
				<div className="flex flex-col gap-5 order-last md:order-first">
					<div>
						<h2 className="text-2xl font-bold gap-5">Liên hệ</h2>
						<p className="text-sm text-gray-500">Vui lòng nhập đầy đủ thông tin bên dưới</p>
					</div>
					<input className="px-4 text-sm border border-[#CDCDCD] rounded-md w-[320px] md:w-[510px] h-[40px]" type="text"
					       placeholder="Họ tên" value={contact.Name} onChange={(e) => setContact('Name', e.target.value)}/>
					<input
						className={cn("px-4 text-sm border border-[#CDCDCD] rounded-md w-[320px] md:w-[510px] h-[40px]", error?.email ? 'border-red-500 text-red-500' : '')}
						type="email" pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
						placeholder="Email" value={contact.Email}
						onChange={(e) => setContact('Email', e.target.value)}/>
					<input
						className={cn("px-4 text-sm border border-[#CDCDCD] rounded-md w-[320px] md:w-[510px] h-[40px]", error?.phone ? 'border-red-500 text-red-500' : '')}
						type="number"
						placeholder="Số điện thoại" value={contact.Phone}
						onChange={(e) => setContact('Phone', e.target.value)}/>
					<textarea className="px-4 py-3 text-sm border border-[#CDCDCD] rounded-md w-[320px] md:w-[510px] h-[130px]"
					          name="" id="" cols="30"
					          rows="10" placeholder="Nội dung"
					          value={contact.Content}
					          onChange={(e) => setContact('Content', e.target.value)}/>
					
					<Button className='w-[64px] h-[40px]' onClick={submitHandler} variant={VARIANT.PRIMARY}>Gửi</Button>
				</div>
				
				<div className='flex flex-col gap-5'>
					<h2 className="text-2xl font-bold">Địa chỉ</h2>
					<div className="text-sm font-bold">
						Công ty cổ phần thanh toán trực tuyến
						toàn cầu GooPay
					</div>
					<div className="flex items-center gap-2 text-sm">
						<img src="/img/icon/location.png" alt="location"/>
						<span>76-82 Trần Hưng Đạo, P. Phạm Ngũ Lão, Quận 1, TP. HCM </span>
					</div>
					<div className="flex items-center gap-2 text-sm">
						<img src="/img/icon/mail.png" alt="mail"/>
						<span>cskh@goopay.vn</span>
					</div>
					<div className="flex items-center gap-2 text-sm">
						<img src="/img/icon/phone-blue.png" alt="phone"/>
						<span>1900 6038</span>
					</div>
				</div>
			</div>
			
			<Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
				<div className='flex flex-col justify-center items-center gap-3'>
					<div className='text-center text-sm '>
						<p className='font-bold'>Cảm ơn bạn đã gửi thông tin đến GooPay.</p>
						<p>Bộ phận CSKH GooPay sẽ liên hệ đến bạn sớm nhất có thể.</p>
					</div>
					<NewLink to={PATH.HOME}>
						<Button className='text-sm' variant={VARIANT.PRIMARY}>
							Quay lại trang chủ
						</Button>
					</NewLink>
				</div>
			</Popup>
			<ToastContainer/>
		</div>
	);
};

export default Contact;