// src/components/Header/Header.js
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FaBars, FaTimes} from 'react-icons/fa';
import {DOWNLOAD_APP_LINK, MENU_ITEMS, PATH, VARIANT} from '../../constants';
import MenuItem from '../Common/MenuItem';
import {handleActiveItem} from '../../services/services';
import {useLocation} from 'react-router-dom';
import Button from '../Common/Button';
import useClickOutside from '../../hooks/useClickOutside';
import Popup from '../Popup';
import NewLink from "../NewLink/NewLink";

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	// const [isMobile, setIsMobile] = useState(false);
	const {t} = useTranslation();
	const location = useLocation();
	const {pathname, hash} = location || {};
	const activeId = handleActiveItem(pathname);
	const menuRef = useRef(null);
	
	useEffect(() => {
		// scroll to top when route changes smoothly
		if (!hash) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
		}
	}, [pathname]);
	
	useEffect(() => {
		setIsOpen(false);
	}, [pathname])
	
	const toggleMenu = () => {
		setIsOpen(!isOpen);
	};
	
	useClickOutside(menuRef, () => setIsOpen(false));
	
	return (
		<header className="bg-white shadow-md h-[90px] sticky top-0 z-50">
			<div className="px-8 md:px-[120px] py-4 flex flex-wrap items-center justify-between bg-white opacity-95">
				<div>
					<NewLink to={PATH.HOME}>
						<img
							src="/img/logo.png"
							alt="Restaurant Logo"
							className="h-12 w-auto"
						/>
					</NewLink>
				
				</div>
				<button
					onTouchEnd={toggleMenu}
					className="md:hidden text-theme-color-primary focus:outline-none"
					aria-label="Toggle menu"
				>
					{isOpen ? <FaTimes id={'FaTimes'} size={24}/> : <FaBars id={'FaBars'} size={24}/>}
				</button>
				<nav
					ref={menuRef}
					className={`${
						isOpen ? 'block' : 'hidden'
					} w-full lg:flex lg:items-center lg:w-auto mt-4 lg:mt-0`}
				>
					<ul className="lg:flex items-center space-y-4 lg:space-y-0 lg:space-x-8">
						{MENU_ITEMS.map((item) => (
							<MenuItem
								key={item.id}
								to={item.path}
								label={t(item.label)}
								variant={item.id === activeId && VARIANT.ACTIVE}
							/>
						))}
						<Button
							variant={VARIANT.GRADIENT}
							className="bg-primary text-white flex justify-center items-center h-10 w-[142px] gap-[6px]"
						>
							<img className="w-5" src="/img/icon/phone.png" alt=""/>
							<div className="flex flex-col justify-center py-3">
								<p className="text-start text-[10px] font-bold h-fit mb-[-5px]">Hotline</p>
								<p className="text-lg font-bold h-fit">19006038</p>
							</div>
						</Button>
						<NewLink className='md:hidden' to={DOWNLOAD_APP_LINK.ONE_LINK} target='_blank'>
							<Button
								variant={VARIANT.GRADIENT}
								className="bg-primary mt-4 text-white flex justify-center items-center h-10 w-[142px] px-2"
								onClick={() => setIsOpen(false)}
							>
								<img src="/img/icon/download.png" alt=""/>
								<p className="text-[12px] font-bold w-[84px]">Tải ứng dụng GooPay</p>
							</Button>
						</NewLink>
						
						<Button
							variant={VARIANT.GRADIENT}
							className="hidden bg-primary text-white md:flex justify-center items-center h-10 w-[142px] px-2"
							onClick={() => setIsPopupOpen(true)}
						>
							<img src="/img/icon/download.png" alt=""/>
							<p className="text-[12px] font-bold w-[84px]">Tải ứng dụng GooPay</p>
						</Button>
					</ul>
				</nav>
			</div>
			
			<Popup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
				<div className='relative w-[370px]'>
					<div className='flex flex-col justify-center items-center gap-4'>
						<p className='font-bold'>Tải ứng dụng GooPay</p>
						<div className='text-sm text-center'>Tải ngay ứng dụng GooPay để trải nghiệm và tận hưởng ưu đãi từ những
							dịch vụ vận tải & vận chuyển hành
							khách
						</div>
						<div className='relative'>
							<img src="/img/frame-qr.png" alt="qrcode-popup"/>
							<img className='absolute top-5 left-5 w-[154px] h-[154px]' src="/img/one-link-qr.jpg" alt="qrcode-popup"/>
						</div>
						<p className='text-sm'>Dùng điện thoại quét QR Code để tải ứng dụng</p>
						<p className='text-[10px]'>hoặc tải tại Apple Store và Google Play</p>
						<div className="flex">
							<img src="/img/download-ios.png" alt=""/>
							<img src="/img/download-android.png" alt=""/>
						</div>
					</div>
				</div>
			</Popup>
		</header>
	);
};

export default Header;
