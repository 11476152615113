// src/components/Home.js
import React from 'react';
import FAQBanner from '../../components/Banner/FAQBanner';
import {Link} from "react-router-dom";
import {PATH} from "../../constants";
import NewLink from "../../components/NewLink/NewLink";

const PolicyPrivacy = () => (
	<div className="mx-auto w-full animate-fadeIn text-sm">
		<FAQBanner title='CHÍNH SÁCH QUYỀN RIÊNG TƯ'/>
		<div className='flex px-8 md:px-[256px] pt-8 md:pb-8 justify-center items-center'>
			<NewLink to={PATH.FAQ} className='hidden md:block'>
				<img className='w-[20px]' src="/img/icon/back.png" alt="back"/>
			</NewLink>
			<h2 className='text-center w-full font-bold text-xl uppercase text-primary'>CHÍNH SÁCH QUYỀN RIÊNG TƯ</h2>
		</div>
		<div className='px-8 md:px-[256px] py-8'
		     dangerouslySetInnerHTML={{
			     __html:
				     '<p>Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư n&agrave;y (&ldquo;Ch&iacute;nh s&aacute;ch&rdquo;) l&agrave; một phần của c&aacute;c Điều khoản v&agrave; Điều kiện về Dịch vụ d&agrave;nh cho người d&ugrave;ng sử dụng ứng dụng GooPay của C&Ocirc;NG TY CỔ PHẦN THANH TO&Aacute;N TRỰC TUYẾN TO&Agrave;N CẦU, cho biết c&aacute;ch ch&uacute;ng t&ocirc;i thu thập, tiếp nhận, lưu trữ, tổng hợp, xử l&yacute;, sử dụng, tiết lộ v&agrave; chia sẻ th&ocirc;ng tin của c&aacute;c c&aacute; nh&acirc;n v&agrave; tổ chức (&ldquo;Người d&ugrave;ng&rdquo;) khi truy cập, sử dụng c&aacute;c sản phẩm v&agrave; dịch vụ của ch&uacute;ng t&ocirc;i được cung cấp qua website, ứng dụng GooPay v&agrave; c&aacute;c k&ecirc;nh tương t&aacute;c khả dụng kh&aacute;c.</p>\n' +
				     '<p>Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư n&agrave;y c&oacute; thể được điều chỉnh v&agrave; sửa đổi, Người d&ugrave;ng n&ecirc;n truy cập v&agrave; kiểm tra thường xuy&ecirc;n để cập nhật những thay đổi gần nhất. V&iacute; GooPayc&oacute; quyền sửa đổi, bổ sung hoặc điều chỉnh c&aacute;c điều khoản của Ch&iacute;nh s&aacute;ch n&agrave;y bất cứ l&uacute;c n&agrave;o th&ocirc;ng qua việc c&ocirc;ng bố cập nhật tr&ecirc;n website ch&iacute;nh thức của V&iacute; GooPay hoặc hiển thị tr&ecirc;n ứng dụng GooPay.</p>\n' +
				     '<p>Bằng việc tiếp tục sử dụng sản phẩm, dịch vụ của ch&uacute;ng t&ocirc;i sau ng&agrave;y c&aacute;c thay đổi của Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư n&agrave;y c&oacute; hiệu lực, Người d&ugrave;ng biểu thị sự chấp thuận với nội dung của Ch&iacute;nh s&aacute;ch được sửa đổi v&agrave; cập nhật đ&oacute;.</p>\n' +
				     '<p>Th&ocirc;ng tin Người d&ugrave;ng: l&agrave; bất kỳ th&ocirc;ng tin, dữ liệu n&agrave;o c&oacute; thể được sử dụng để nhận dạng Người d&ugrave;ng hoặc dựa v&agrave;o đ&oacute; m&agrave; Người d&ugrave;ng được x&aacute;c định v&agrave; li&ecirc;n quan đến/xuất ph&aacute;t từ việc Người d&ugrave;ng sử dụng Dịch vụ v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c được dẫn chiếu trong Ch&iacute;nh s&aacute;ch n&agrave;y, bao gồm nhưng kh&ocirc;ng giới hạn c&aacute;c dữ liệu như t&ecirc;n, quốc tịch, số điện thoại, chi tiết thẻ thanh to&aacute;n v&agrave; ng&acirc;n h&agrave;ng, sở th&iacute;ch c&aacute; nh&acirc;n, địa chỉ, vị tr&iacute;, h&igrave;nh ảnh, th&ocirc;ng tin CMND/CCCD, ng&agrave;y sinh, t&igrave;nh trạng h&ocirc;n nh&acirc;n, th&ocirc;ng tin bảo hiểm, c&aacute;o buộc phạm tội, th&ocirc;ng tin t&agrave;i ch&iacute;nh, th&ocirc;ng tin giao dịch, lịch sử truy cập, h&agrave;nh tr&igrave;nh kh&aacute;ch h&agrave;ng. Ngo&agrave;i ra, một số th&ocirc;ng tin về bản chất mang t&iacute;nh nhạy cảm như chủng tộc, sức khỏe, niềm tin t&ocirc;n gi&aacute;o, quan điểm ch&iacute;nh trị, dữ liệu sinh trắc học v&agrave; c&aacute;c th&ocirc;ng tin kh&aacute;c c&oacute; thể được ch&uacute;ng t&ocirc;i thu thập khi cần thiết.</p>\n' +
				     '<p>Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư n&agrave;y gồm c&aacute;c nội dung sau:</p>\n' +
				     '<p>Dữ liệu v&agrave; th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i thu thập.</p>\n' +
				     '<p>C&aacute;ch thức ch&uacute;ng t&ocirc;i sử dụng th&ocirc;ng tin Người d&ugrave;ng</p>\n' +
				     '<p>C&aacute;ch thức ch&uacute;ng t&ocirc;i chia sẻ th&ocirc;ng tin Người d&ugrave;ng</p>\n' +
				     '<p>Quyền truy cập v&agrave; lựa chọn của Người d&ugrave;ng</p>\n' +
				     '<p>Th&ocirc;ng tin li&ecirc;n hệ</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">1.Dữ liệu v&agrave; th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i thu thập</h1>\n' +
				     '<p>V&iacute; GooPay thu thập c&aacute;c dữ liệu v&agrave; Th&ocirc;ng tin Người d&ugrave;ng cần thiết để đảm bảo việc cung cấp v&agrave; vận h&agrave;nh c&aacute;c sản phẩm, dịch vụ tu&acirc;n thủ c&aacute;c quy định ph&aacute;p luật. Một số th&ocirc;ng tin l&agrave; kh&ocirc;ng bắt buộc, tuy nhi&ecirc;n nếu kh&ocirc;ng được cung cấp sẽ ảnh hưởng đến chất lượng v&agrave; trải nghiệm của Người d&ugrave;ng.</p>\n' +
				     '<br/><h2 style="font-size:14px; font-weight: bold;">1.1 C&aacute;c loại th&ocirc;ng tin m&agrave; ch&uacute;ng t&ocirc;i thu thập:</h2>\n' +
				     '<p>Th&ocirc;ng tin do Người d&ugrave;ng cung cấp.</p>\n' +
				     '<p>Th&ocirc;ng tin được thu thập tự động.</p>\n' +
				     '<p>Hoạt động của Người d&ugrave;ng v&agrave; c&aacute;c th&ocirc;ng tin được tạo ra khi Người d&ugrave;ng tương t&aacute;c với c&aacute;c Dịch vụ của ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<p>Th&ocirc;ng tin từ c&aacute;c nguồn kh&aacute;c như từ đối t&aacute;c thương mại v&agrave; c&aacute;c b&ecirc;n thứ ba kh&aacute;c.</p>\n' +
				     '<p>Th&ocirc;ng tin do Người d&ugrave;ng cung cấp</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i thu thập bất kỳ th&ocirc;ng tin n&agrave;o do Người d&ugrave;ng cung cấp khi đăng k&yacute;, đăng nhập, tương t&aacute;c qua c&aacute;c k&ecirc;nh tương t&aacute;c khả dụng của ch&uacute;ng t&ocirc;i hoặc b&ecirc;n thứ ba để sử dụng Dịch vụ của ch&uacute;ng t&ocirc;i, bao gồm nhưng kh&ocirc;ng giới hạn việc thực hiện giao dịch, tham gia c&aacute;c cuộc thi, tr&ograve; chơi, chương tr&igrave;nh hoặc sự kiện, sử dụng t&iacute;nh năng sinh trắc học trong việc nhận diện c&aacute; nh&acirc;n hoặc x&aacute;c thực giao dịch, li&ecirc;n hệ, khiếu nại, thực hiện khảo s&aacute;t, điều tra&hellip;v.v.</p>\n' +
				     '<p>Người d&ugrave;ng c&oacute; tr&aacute;ch nhiệm đảm bảo những th&ocirc;ng tin Người d&ugrave;ng cung cấp l&agrave; đầy đủ, ch&iacute;nh x&aacute;c v&agrave; lu&ocirc;n cập nhật th&ocirc;ng tin nhằm đảm bảo quyền lợi của Người d&ugrave;ng theo quy định của việc sử dụng Dịch vụ tương ứng.</p>\n' +
				     '<p>Trong trường hợp Người d&ugrave;ng cung cấp th&ocirc;ng tin của b&ecirc;n thứ ba, Người d&ugrave;ng c&oacute; tr&aacute;ch nhiệm v&agrave; bảo đảm rằng đ&atilde; c&oacute; được đầy đủ sự đồng &yacute; v&agrave; chấp thuận của b&ecirc;n thứ ba đ&oacute; v&agrave; c&aacute;c chấp thuận kh&aacute;c theo quy định của ph&aacute;p luật đối với việc cung cấp th&ocirc;ng tin cho ch&uacute;ng t&ocirc;i v&agrave; đối với việc ch&uacute;ng t&ocirc;i sử dụng c&aacute;c th&ocirc;ng tin đ&oacute; theo Ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; quy định của ph&aacute;p luật c&oacute; li&ecirc;n quan.</p>\n' +
				     '<p>Th&ocirc;ng tin được thu thập tự động</p>\n' +
				     '<p>C&aacute;c th&ocirc;ng tin được tự động thu thập khi Người d&ugrave;ng thực hiện c&aacute;c hoạt động trong qu&aacute; tr&igrave;nh sử dụng Dịch vụ của ch&uacute;ng t&ocirc;i, bao gồm nhưng kh&ocirc;ng giới hạn c&aacute;c th&ocirc;ng tin về c&aacute;c b&ecirc;n cung cấp sản phẩm, dịch vụ cho Người d&ugrave;ng, th&ocirc;ng tin giao dịch được thực hiện, (loại sản phẩm, dịch vụ, địa điểm, thời gian thực hiện giao dịch), phương thức thanh to&aacute;n (kh&ocirc;ng bao gồm số thẻ chi tiết, số CVV hoặc m&atilde; số x&aacute;c thực kh&aacute;c c&oacute; gi&aacute; trị ph&aacute;p l&yacute; tương đương), th&ocirc;ng tin về ứng dụng v&agrave; thiết bị sử dụng (địa chỉ IP, phần mềm, hệ điều h&agrave;nh, định vị, loại tr&igrave;nh duyệt, địa chỉ trang web giới thiệu hoặc gh&eacute; thăm từ c&aacute;c k&ecirc;nh tương t&aacute;c khả dụng, c&aacute;c ứng dụng di động, số lần truy cập, phản hồi, c&aacute;c phi&ecirc;n bản, c&aacute;c định dạng quảng c&aacute;o v&agrave; c&aacute;c th&ocirc;ng tin c&oacute; li&ecirc;n quan nếu c&oacute;).</p>\n' +
				     '<p>C&aacute;c loại dữ liệu v&agrave; th&ocirc;ng tin về ứng dụng v&agrave; thiết bị ch&uacute;ng t&ocirc;i thu thập v&agrave; thời gian ch&uacute;ng t&ocirc;i lưu trữ sẽ t&ugrave;y thuộc một phần v&agrave;o thiết bị v&agrave; c&aacute;c chế độ c&agrave;i đặt, thiết lập của Người d&ugrave;ng. V&iacute; dụ: Người d&ugrave;ng c&oacute; thể bật hoặc tắt vị tr&iacute; của thiết bị th&ocirc;ng qua ứng dụng c&agrave;i đặt của thiết bị&hellip;v.v.</p>\n' +
				     '<p>Th&ocirc;ng tin c&aacute; nh&acirc;n kh&aacute;c m&agrave; ch&uacute;ng t&ocirc;i thu thập sẽ t&ugrave;y thuộc v&agrave;o c&agrave;i đặt bảo mật m&agrave; Người d&ugrave;ng đ&atilde; thiết lập với nh&agrave; cung cấp mạng x&atilde; hội, tr&ecirc;n c&aacute;c thi&ecirc;́t bị v&agrave; ứng dụng của m&igrave;nh.</p>\n' +
				     '<p>Hoạt động của Người d&ugrave;ng:</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i thu thập th&ocirc;ng tin th&ocirc;ng qua hoạt động của Người d&ugrave;ng v&agrave; c&aacute;c th&ocirc;ng tin do Người d&ugrave;ng tạo ra trong qu&aacute; tr&igrave;nh tương t&aacute;c với c&aacute;c Dịch vụ của ch&uacute;ng t&ocirc;i như c&aacute;c hoạt động t&igrave;m kiếm, c&aacute;c video được xem, hoạt động xem v&agrave; tương t&aacute;c với với nội dung quảng c&aacute;o, c&aacute;c b&ecirc;n thứ ba m&agrave; Người d&ugrave;ng li&ecirc;n lạc hoặc chia sẻ nội dung, hoạt động mua h&agrave;ng&hellip;v.v.</p>\n' +
				     '<p>Thu thập th&ocirc;ng qua Cookie của c&aacute;c trang web, dịch vụ trực tuyến, ứng dụng tương t&aacute;c của ch&uacute;ng t&ocirc;i v&agrave; c&aacute;c b&ecirc;n thứ ba để hiểu r&otilde; hơn về h&agrave;nh vi của Người d&ugrave;ng bao gồm nhằm mục đ&iacute;ch bảo mật v&agrave; ngăn chặn gian lận, cho ch&uacute;ng t&ocirc;i biết Người d&ugrave;ng đ&atilde; truy cập v&agrave;o những phần n&agrave;o của trang web, tạo sự thuận lợi v&agrave; đo lường hiệu quả của quảng c&aacute;o v&agrave; việc t&igrave;m kiếm tr&ecirc;n web, từ đ&oacute; ghi nhớ sự lựa chọn của Người d&ugrave;ng v&agrave; gi&uacute;p ch&uacute;ng t&ocirc;i mang lại trải nghiệm d&agrave;nh ri&ecirc;ng cho Người d&ugrave;ng như cung cấp c&aacute;c đề xuất v&agrave; hiển thị nội dung được c&aacute; nh&acirc;n h&oacute;a, cung cấp c&aacute;c t&iacute;nh năng v&agrave; dịch vụ t&ugrave;y chỉnh. Người d&ugrave;ng c&oacute; thể quản l&yacute; Cookies tr&igrave;nh duyệt th&ocirc;ng qua việc c&agrave;i đặt theo hướng dẫn cụ thể tr&ecirc;n c&aacute;c tr&igrave;nh duyệt web. T&iacute;nh năng Trợ gi&uacute;p tr&ecirc;n hầu hết c&aacute;c tr&igrave;nh duyệt v&agrave; thiết bị sẽ cho Người d&ugrave;ng biết c&aacute;ch ngăn tr&igrave;nh duyệt hoặc thiết bị của m&igrave;nh chấp nhận Cookies mới, l&agrave;m thế n&agrave;o để tr&igrave;nh duyệt th&ocirc;ng b&aacute;o cho Người d&ugrave;ng khi nhận được Cookies mới hoặc c&aacute;ch tắt hẳn chức năng Cookies.</p>\n' +
				     '<p>Th&ocirc;ng tin được thu thập từ c&aacute;c nguồn kh&aacute;c:</p>\n' +
				     '<p>Trong một số trường hợp, ch&uacute;ng t&ocirc;i sẽ thu thập th&ocirc;ng tin Người d&ugrave;ng từ c&aacute;c nguồn c&oacute; thể truy cập c&ocirc;ng khai l&agrave; c&aacute;c th&ocirc;ng tin c&oacute; sẵn v&agrave; c&ocirc;ng khai tr&ecirc;n c&aacute;c trang web v&agrave; mạng x&atilde; hội.</p>\n' +
				     '<p>Th&ocirc;ng tin Người d&ugrave;ng cũng c&oacute; thể được thu thập từ b&ecirc;n thứ ba, bao gồm c&aacute;c cơ quan nh&agrave; nước c&oacute; thẩm quyền hoặc c&aacute;c đối t&aacute;c thương mại l&agrave; c&aacute;c b&ecirc;n cung cấp dịch vụ, c&aacute;c đối t&aacute;c thực hiện chương tr&igrave;nh giới thiệu về kh&aacute;ch h&agrave;ng tiềm năng, c&aacute;c đối t&aacute;c cung cấp c&aacute;c th&ocirc;ng tin để chống lại h&agrave;nh vi gian lận, phạm tội, c&aacute;c đối t&aacute;c cung cấp dịch vụ quảng c&aacute;o&hellip;v.v.</p>\n' +
				     '<p>Khi ch&uacute;ng t&ocirc;i thu thập Th&ocirc;ng tin Người d&ugrave;ng từ c&aacute;c nguồn kh&aacute;c, ch&uacute;ng t&ocirc;i đảm bảo rằng dữ liệu đ&oacute; được chuyển cho ch&uacute;ng t&ocirc;i một c&aacute;ch ph&ugrave; hợp v&agrave; tu&acirc;n thủ nghi&ecirc;m ngặt c&aacute;c quy định của ph&aacute;p luật hiện h&agrave;nh.</p>\n' +
				     '<br/><h2 style="font-size:14px; font-weight: bold;">1.2 Trẻ em</h2>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i hiểu tầm quan trọng của việc bảo vệ quyền ri&ecirc;ng tư của trẻ em. Cụ thể, c&aacute;c trang web v&agrave; ứng dụng của ch&uacute;ng t&ocirc;i kh&ocirc;ng được thiết kế hoặc hướng đến trẻ em dưới 13 tuổi, ch&uacute;ng t&ocirc;i kh&ocirc;ng bao giờ cố &yacute; thu thập hoặc lưu giữ th&ocirc;ng tin về bất kỳ ai dưới 13 tuổi, ngoại trừ nếu đ&oacute; l&agrave; một phần của cam kết cung cấp dịch vụ chuy&ecirc;n m&ocirc;n.</p>\n' +
				     '<p>Nếu ch&uacute;ng t&ocirc;i biết rằng dữ liệu c&aacute; nh&acirc;n của trẻ em bị thu thập khi chưa c&oacute; sự cho ph&eacute;p th&iacute;ch hợp, dữ liệu đ&oacute; sẽ bị x&oacute;a ngay khi c&oacute; thể.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">2. C&aacute;ch thức ch&uacute;ng t&ocirc;i sử dụng Th&ocirc;ng tin Người d&ugrave;ng</h1>\n' +
				     '<p>Th&ocirc;ng tin Người d&ugrave;ng sẽ được ch&uacute;ng t&ocirc;i sử dụng theo Ch&iacute;nh s&aacute;ch Quyền ri&ecirc;ng tư n&agrave;y v&agrave; cho mục đ&iacute;ch cụ thể. Ch&uacute;ng t&ocirc;i chỉ sử dụng Th&ocirc;ng tin Người d&ugrave;ng khi ch&uacute;ng t&ocirc;i c&oacute; cơ sở ph&aacute;p l&yacute; hợp lệ để l&agrave;m như vậy, t&ugrave;y thuộc v&agrave;o từng trường hợp, ch&uacute;ng t&ocirc;i c&oacute; thể căn cứ tr&ecirc;n sự cho ph&eacute;p của Người d&ugrave;ng hoặc căn cứ v&agrave;o qu&aacute; tr&igrave;nh xử l&yacute; l&agrave; cần thiết để thực hiện giao kết với Người d&ugrave;ng, bảo vệ lợi &iacute;ch trọng yếu của Người d&ugrave;ng hoặc của những người kh&aacute;c hoặc để tu&acirc;n thủ ph&aacute;p luật.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i cũng c&oacute; thể xử l&yacute; Th&ocirc;ng tin Người d&ugrave;ng khi ch&uacute;ng t&ocirc;i tin rằng dữ liệu đ&oacute; l&agrave; v&igrave; lợi &iacute;ch hợp ph&aacute;p của ch&uacute;ng t&ocirc;i hoặc của người kh&aacute;c, c&oacute; t&iacute;nh đến quyền, lợi &iacute;ch v&agrave; mong đợi của Người d&ugrave;ng.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i kh&ocirc;ng c&oacute; ch&iacute;nh s&aacute;ch cung cấp Th&ocirc;ng tin Người d&ugrave;ng cho bất kỳ b&ecirc;n thứ ba n&agrave;o cho mục đ&iacute;ch tiếp thị trực tiếp của họ hoặc bất kỳ mục đ&iacute;ch n&agrave;o kh&aacute;c, trừ khi ch&uacute;ng t&ocirc;i đ&atilde; tu&acirc;n thủ tr&igrave;nh tự được ph&eacute;p theo c&aacute;c quy định ph&aacute;p luật về bảo mật dữ liệu để thực hiện việc n&agrave;y một c&aacute;ch hợp ph&aacute;p.</p>\n' +
				     '<p>Th&ocirc;ng tin Người d&ugrave;ng được ch&uacute;ng t&ocirc;i sử dụng để vận h&agrave;nh, cung cấp v&agrave; cải tiến Dịch vụ, cho c&aacute;c mục đ&iacute;ch:</p>\n' +
				     '<p>Cung cấp, c&aacute; nh&acirc;n h&oacute;a, duy tr&igrave; v&agrave; cải thiện c&aacute;c Dịch vụ của ch&uacute;ng t&ocirc;i, bao gồm:</p>\n' +
				     '<p>Cung cấp c&aacute;c Dịch vụ cho Người d&ugrave;ng, giới thiệu c&aacute;c Dịch vụ mới của ch&uacute;ng t&ocirc;i hoặc c&aacute;c đối t&aacute;c của ch&uacute;ng t&ocirc;i dựa tr&ecirc;n nhu cầu v&agrave; c&aacute;c th&oacute;i quen của Người d&ugrave;ng trong qu&aacute; tr&igrave;nh tương t&aacute;c v&agrave;/hoặc sử dụng Dịch vụ.</p>\n' +
				     '<p>Xử l&yacute; c&aacute;c giao dịch v&agrave;/hoặc y&ecirc;u cầu của Người d&ugrave;ng li&ecirc;n quan đến việc sử dụng Dịch vụ như tạo mới, quản trị v&agrave; cập nhật t&agrave;i khoản Người d&ugrave;ng; thực hiện việc x&aacute;c minh, thẩm định c&aacute;c th&ocirc;ng tin do Người d&ugrave;ng cung cấp, x&aacute;c thực danh t&iacute;nh v&agrave; thực hiện quy tr&igrave;nh định danh kh&aacute;ch h&agrave;ng theo quy định của ph&aacute;p luật; quản l&yacute;, vận h&agrave;nh, cung cấp v&agrave; quản trị việc sử dụng, hoạt động của Người d&ugrave;ng khi sử dụng Dịch vụ; x&aacute;c nhận c&aacute;c giao dịch v&agrave; thực hiện c&aacute;c khoản thanh to&aacute;n; xử l&yacute; v&agrave; quản l&yacute; c&aacute;c phần thưởng/giải thưởng/ưu đ&atilde;i của Người d&ugrave;ng.</p>\n' +
				     '<p>Thực hiện c&aacute;c hoạt động cần thiết để cung cấp dịch vụ, bao gồm khắc phục c&aacute;c lỗi phần mềm, sự cố vận h&agrave;nh.</p>\n' +
				     '<p>Tiến h&agrave;nh ph&acirc;n t&iacute;ch dữ liệu, thử nghiệm v&agrave; nghi&ecirc;n cứu, gi&aacute;m s&aacute;t v&agrave; ph&acirc;n t&iacute;ch xu hướng sử dụng v&agrave; hoạt động của Người d&ugrave;ng tr&ecirc;n c&aacute;c nền tảng của ch&uacute;ng t&ocirc;i; hiển thị c&aacute;c t&iacute;nh năng c&aacute; nh&acirc;n h&oacute;a trải nghiệm của Người d&ugrave;ng như nhận diện sở th&iacute;ch, danh s&aacute;ch c&aacute;c ứng dụng y&ecirc;u th&iacute;ch v&agrave; th&ocirc;ng tin quan t&acirc;m, đề xuất Dịch vụ; cho ph&eacute;p tương t&aacute;c giữa c&aacute;c Người d&ugrave;ng, giữa Người d&ugrave;ng v&agrave; ch&uacute;ng t&ocirc;i hay với c&aacute;c đối t&aacute;c được li&ecirc;n kết, cho ph&eacute;p c&aacute;c đối t&aacute;c của ch&uacute;ng t&ocirc;i quản l&yacute; v&agrave; ph&acirc;n bổ nguồn lực để cung cấp dịch vụ; đề xuất, c&oacute; được, cung cấp hoặc tạo điều kiện cho c&aacute;c giải ph&aacute;p bảo hiểm hoặc t&agrave;i ch&iacute;nh.</p>\n' +
				     '<p>Thực hiện khảo sát, nghi&ecirc;n cứu thị trường, ph&acirc;n t&iacute;ch dữ liệu li&ecirc;n quan đến c&aacute;c loại dịch vụ, đo lường việc sử dụng, ph&acirc;n t&iacute;ch hiệu suất, t&igrave;m phương hướng cải thiện v&agrave; ph&aacute;t triển Dịch vụ;</p>\n' +
				     '<p>H&ocirc;̃ trợ vi&ecirc;̣c xử lý y&ecirc;u c&acirc;̀u và trợ giúp Người d&ugrave;ng: C&aacute;c cuộc gọi, tin nhắn v&agrave;/hoặc c&aacute;c h&igrave;nh thức li&ecirc;n hệ kh&aacute;c từ v&agrave;/hoặc đến ch&uacute;ng t&ocirc;i sẽ được lưu trữ dưới h&igrave;nh thức bao gồm nhưng kh&ocirc;ng giới hạn ghi &acirc;m, ghi h&igrave;nh, tự động hoặc chủ động, nhằm hỗ trợ giải quyết c&aacute;c y&ecirc;u cầu hợp lệ từ Người d&ugrave;ng, theo d&otilde;i v&agrave; cải thiện c&aacute;ch phản hồi từ bộ phận hỗ trợ kh&aacute;ch h&agrave;ng, cập nhật hệ thống th&ocirc;ng tin, n&acirc;ng cao chất lượng dịch vụ.</p>\n' +
				     '<p>Nghi&ecirc;n cứu ph&aacute;t triển: thực hiện c&aacute;c hoạt động nghi&ecirc;n cứu, ph&acirc;n t&iacute;ch, thử nghiệm v&agrave; ph&aacute;t triển bao gồm nhưng kh&ocirc;ng giới hạn ph&acirc;n t&iacute;ch dữ liệu, khảo s&aacute;t, ph&aacute;t triển sản phẩm v&agrave; dịch vụ v&agrave;/hoặc tạo hồ sơ kh&aacute;ch h&agrave;ng, để ph&acirc;n t&iacute;ch c&aacute;ch Người d&ugrave;ng tiến h&agrave;nh c&aacute;c hoạt động tr&ecirc;n c&aacute;c nền tảng của ch&uacute;ng t&ocirc;i, cải tiến c&aacute;c Dịch vụ để n&acirc;ng cao trải nghiệm của Người d&ugrave;ng, ph&aacute;t triển c&aacute;c t&iacute;nh năng, sản phẩm v&agrave; dịch vụ mới v&agrave; tạo điều kiện cho c&aacute;c giải ph&aacute;p t&agrave;i ch&iacute;nh v&agrave; bảo hiểm đồng thời bảo vệ dữ liệu, cải thiện v&agrave; tăng cường an to&agrave;n, an ninh, bảo mật.</p>\n' +
				     '<p>Tiếp thị v&agrave; quảng b&aacute;: ch&uacute;ng t&ocirc;i c&oacute; thể li&ecirc;n lạc với Người d&ugrave;ng theo nhiều h&igrave;nh thức như email, gọi điện, dịch vụ tin nhắn, gửi tin trực tuyến cho mục đ&iacute;ch tiếp thị v&agrave; quảng c&aacute;o c&aacute;c dịch vụ của ch&uacute;ng t&ocirc;i (kh&ocirc;ng loại trừ c&aacute;c sản phẩm, dịch vụ của c&aacute;c đối t&aacute;c được ph&aacute;t triển v&agrave; cung cấp đến Người d&ugrave;ng th&ocirc;ng qua nền tảng ứng dụng GooPay).</p>\n' +
				     '<p>Đảm bảo và tăng cường tính an to&agrave;n và bảo mật: thực hiện c&aacute;c h&agrave;nh động nhằm ngăn ngừa gian lận, h&agrave;nh vi phạm tội, đảm bảo an ninh hệ thống, thực hiện lưu trữ, quản l&yacute;, dự ph&ograve;ng cho việc phục hồi sự cố hay cho c&aacute;c mục đ&iacute;ch tương tự. Bảo vệ t&iacute;nh bảo mật hoặc t&iacute;nh to&agrave;n vẹn của Dịch vụ v&agrave; mọi phương tiện/thiết bị được sử dụng để cung cấp Dịch vụ</p>\n' +
				     '<p>Ph&ograve;ng chống gian lận v&agrave; quản l&yacute; rủi ro: thực hiện c&aacute;c h&agrave;nh động nhằm ph&aacute;t hiện, ngăn chặn c&aacute;c hoạt động giả mạo nhận dạng Người d&ugrave;ng hay bất cứ h&agrave;nh vi gian lận, bất hợp ph&aacute;p li&ecirc;n quan đến việc sử dụng Dịch vụ v&agrave; truy tố tội phạm; ph&aacute;t hiện, ngăn chặn c&aacute;c h&agrave;nh vi sai tr&aacute;i, lừa đảo hoặc thiếu s&oacute;t li&ecirc;n quan đến Dịch vụ hoặc bất kỳ hoạt động n&agrave;o kh&aacute;c ph&aacute;t sinh từ hay li&ecirc;n quan đến mối quan hệ giữa ch&uacute;ng t&ocirc;i v&agrave; Người d&ugrave;ng hoặc c&aacute;c đối t&aacute;c trong việc cung cấp, sử dụng Dịch vụ; sử dụng c&aacute;c phương ph&aacute;p chấm điểm để đ&aacute;nh gi&aacute; v&agrave; quản trị rủi ro.</p>\n' +
				     '<p>Tu&acirc;n thủ nghĩa vụ ph&aacute;p l&yacute; v&agrave; quy định ph&aacute;p luật, v&iacute; dụ như thực hiện c&aacute;c b&aacute;o c&aacute;o theo quy định của ph&aacute;p luật, lưu trữ th&ocirc;ng tin dữ liệu giao dịch.</p>\n' +
				     '<p>C&aacute;c mục đ&iacute;ch hợp ph&aacute;p kh&aacute;c ph&ugrave; hợp với mục ti&ecirc;u của Dịch vụ.</p>\n' +
				     '<p>Trong một số trường hợp c&oacute; li&ecirc;n quan đến việc sử dụng một số Dịch vụ cụ thể, v&agrave; đối với c&aacute;c mục đ&iacute;ch sử dụng Th&ocirc;ng tin Người d&ugrave;ng kh&ocirc;ng được đề cập ở tr&ecirc;n v&agrave;/hoặc cần sự đồng &yacute; cụ thể của Người d&ugrave;ng, ch&uacute;ng t&ocirc;i sẽ y&ecirc;u cầu sự cho ph&eacute;p của Người d&ugrave;ng về việc sử dụng Th&ocirc;ng tin Người d&ugrave;ng v&agrave; n&oacute; chỉ được thực hiện sau khi c&oacute; sự cho ph&eacute;p n&agrave;y.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">3. C&aacute;ch thức ch&uacute;ng t&ocirc;i chia sẻ Th&ocirc;ng tin Người d&ugrave;ng</h1>\n' +
				     '<p>Trong trường hợp chuyển giao Th&ocirc;ng tin Người d&ugrave;ng đến một c&ocirc;ng ty thuộc nh&oacute;m c&aacute;c c&ocirc;ng ty của V&iacute; GooPay, b&ecirc;n thứ ba hoặc cơ quan thực thi ph&aacute;p luật kh&aacute;c, ch&uacute;ng t&ocirc;i đảm bảo rằng việc chuyển giao n&agrave;y tu&acirc;n thủ theo ph&aacute;p luật về bảo mật dữ liệu. Khi cần thiết, ch&uacute;ng t&ocirc;i sẽ r&agrave; so&aacute;t c&aacute;c ch&iacute;nh s&aacute;ch bảo mật v&agrave; an to&agrave;n th&ocirc;ng tin của b&ecirc;n nhận dữ liệu nhằm đảm bảo rằng họ đang &aacute;p dụng hoặc phải cam kết tu&acirc;n thủ một c&aacute;ch nghi&ecirc;m ngặt c&aacute;c ti&ecirc;u chuẩn tương đương với c&aacute;c nguy&ecirc;n tắc của Ch&iacute;nh s&aacute;ch n&agrave;y v&agrave; c&aacute;c quy định của ph&aacute;p luật c&oacute; li&ecirc;n quan.</p>\n' +
				     '<br/><h2 style="font-size:14px; font-weight: bold;">3.1 Chia sẻ th&ocirc;ng tin trong mạng lưới c&aacute;c c&ocirc;ng ty trong nh&oacute;m c&aacute;c c&ocirc;ng ty của V&iacute; GooPay</h2>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i trao đổi, cung cấp v&agrave; chia sẻ Th&ocirc;ng tin Người d&ugrave;ng với c&aacute;c c&ocirc;ng ty trong nh&oacute;m c&aacute;c c&ocirc;ng ty của V&iacute; GooPay như một phần của c&aacute;c cam kết hoặc đ&aacute;p ứng c&aacute;c y&ecirc;u cầu về nghĩa vụ ph&aacute;p l&yacute; tương ứng.</p>\n' +
				     '<br/><h2 style="font-size:14px; font-weight: bold;">3.2 Chia sẻ th&ocirc;ng tin cho b&ecirc;n thứ ba</h2>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i kh&ocirc;ng chia sẻ th&ocirc;ng tin c&aacute; nh&acirc;n của Người d&ugrave;ng cho b&ecirc;n thứ ba, trừ khi cần thiết cho nhu cầu kinh doanh hợp ph&aacute;p của ch&uacute;ng t&ocirc;i v&agrave; c&aacute;c đối t&aacute;c của V&iacute; GooPay nhằm cung cấp sản phẩm, dịch vụ cho Người d&ugrave;ng hoặc nghi&ecirc;n cứu đ&aacute;nh gi&aacute; để hiểu về nhu cầu sử dụng sản phẩm, dịch vụ của Người d&ugrave;ng, để thực hiện c&aacute;c y&ecirc;u cầu của Người d&ugrave;ng v&agrave;/hoặc theo y&ecirc;u cầu hoặc cho ph&eacute;p của ph&aacute;p luật, cụ thể:</p>\n' +
				     '<p>Thực hiện theo y&ecirc;u cầu của c&aacute;c cơ quan nh&agrave; nước c&oacute; thẩm quyền hoặc theo quy đinh của ph&aacute;p luật.</p>\n' +
				     '<p>Chia sẻ Th&ocirc;ng tin Người d&ugrave;ng với Người d&ugrave;ng kh&aacute;c trong trường hợp Người d&ugrave;ng sử dụng c&ocirc;ng cụ tương t&aacute;c tr&ecirc;n c&aacute;c k&ecirc;nh tương t&aacute;c khả dụng; c&ocirc;ng khai c&aacute;c th&ocirc;ng tin c&ocirc;ng khai trong trường hợp Người d&ugrave;ng sử dụng c&aacute;c t&iacute;nh năng của c&aacute;c k&ecirc;nh tương t&aacute;c khả dụng.</p>\n' +
				     '<p>Cung cấp, chia sẻ Th&ocirc;ng tin Người d&ugrave;ng với đối t&aacute;c theo y&ecirc;u cầu hoặc x&aacute;c nhận của Người d&ugrave;ng, th&ocirc;ng qua bất kỳ h&igrave;nh thức c&aacute;c phương tiện điện tử tr&ecirc;n c&aacute;c k&ecirc;nh tương t&aacute;c hoặc c&aacute;c h&igrave;nh thức kh&aacute;c. V&iacute; dụ khi Người d&ugrave;ng y&ecirc;u cầu một dịch vụ th&ocirc;ng qua đối t&aacute;c của ch&uacute;ng t&ocirc;i hoặc sử dụng chương tr&igrave;nh khuyến mại do đối t&aacute;c cung cấp, Người d&ugrave;ng đồng thời cũng đồng &yacute; rằng ch&uacute;ng t&ocirc;i c&oacute; thể chia sẻ Th&ocirc;ng tin Người d&ugrave;ng với c&aacute;c đối t&aacute;c đ&oacute; v&agrave; Th&ocirc;ng tin Người d&ugrave;ng chia sẻ với đối t&aacute;c c&oacute; thể được chuyển giao tới c&aacute;c b&ecirc;n trong mối li&ecirc;n hệ với Dịch vụ được sử dụng bởi Người d&ugrave;ng. C&aacute;c đối t&aacute;c của ch&uacute;ng t&ocirc;i bao gồm c&aacute;c đối t&aacute;c t&iacute;ch hợp với ứng dụng của ch&uacute;ng t&ocirc;i hoặc ứng dụng của ch&uacute;ng t&ocirc;i t&iacute;ch hợp với hệ thống của đối t&aacute;c, đối t&aacute;c dịch vụ vận chuyển, đối t&aacute;c t&agrave;i ch&iacute;nh, hoặc c&aacute;c đối t&aacute;c kinh doanh m&agrave; ch&uacute;ng t&ocirc;i hợp t&aacute;c để cung cấp chương tr&igrave;nh khuyến mại, c&aacute;c dịch vụ cạnh tranh hoặc dịch vụ đặc biệt kh&aacute;c.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i c&oacute; thể cung cấp Th&ocirc;ng Tin Người D&ugrave;ng cho c&aacute;c nh&agrave; cung cấp, chuy&ecirc;n gia tư vấn, đối t&aacute;c tiếp thị, c&ocirc;ng ty nghi&ecirc;n cứu v&agrave; nh&agrave; cung cấp dịch vụ hoặc đối t&aacute;c kinh doanh kh&aacute;c, bao gồm bộ phận xử l&yacute; thanh to&aacute;n v&agrave; bộ phận hỗ trợ; dịch vụ chống rửa tiền; nh&agrave; cung cấp dịch vụ m&aacute;y chủ, dịch vụ c&ocirc;ng nghệ th&ocirc;ng tin, nh&agrave; cung cấp lưu trữ đ&aacute;m m&acirc;y; đối t&aacute;c quảng c&aacute;o v&agrave; nh&agrave; cung cấp nền tảng quảng c&aacute;o; nh&agrave; cung cấp ph&acirc;n t&iacute;ch dữ liệu; c&aacute;c đối t&aacute;c nghi&ecirc;n cứu, bao gồm cả những người thực hiện khảo s&aacute;t hoặc dự &aacute;n nghi&ecirc;n cứu hợp t&aacute;c với ch&uacute;ng t&ocirc;i hoặc thay mặt ch&uacute;ng t&ocirc;i; đối t&aacute;c bảo hiểm, t&agrave;i ch&iacute;nh v&agrave; ng&acirc;n h&agrave;ng;</p>\n' +
				     '<p>Trao đổi, cung cấp, chia sẻ Th&ocirc;ng Tin Người D&ugrave;ng cho c&aacute;c b&ecirc;n thứ ba l&agrave; cố vấn, chuy&ecirc;n gia, đại l&yacute; hoặc nh&agrave; cung cấp dịch vụ, nh&agrave; thầu phụ của ch&uacute;ng t&ocirc;i để triển khai c&aacute;c c&ocirc;ng việc, dịch vụ ph&ugrave; hợp với mục đ&iacute;ch sử dụng.</p>\n' +
				     '<p>Tiết lộ t&agrave;i khoản v&agrave; Th&ocirc;ng Tin Người D&ugrave;ng khi ch&uacute;ng t&ocirc;i tin rằng việc đ&oacute; l&agrave; ph&ugrave; hợp để tu&acirc;n thủ ph&aacute;p luật, để thực thi hoặc &aacute;p dụng c&aacute;c điều khoản v&agrave; thỏa thuận kh&aacute;c của ch&uacute;ng t&ocirc;i hoặc để bảo vệ quyền, t&agrave;i sản hoặc an ninh của ch&uacute;ng t&ocirc;i, Người D&ugrave;ng, hoặc bất kỳ người n&agrave;o kh&aacute;c. C&aacute;c c&ocirc;ng việc n&ecirc;u tr&ecirc;n c&oacute; thể bao gồm việc trao đổi th&ocirc;ng tin với c&aacute;c c&ocirc;ng ty v&agrave; tổ chức kh&aacute;c để ngăn chặn v&agrave; ph&aacute;t hiện gian lận v&agrave; giảm rủi ro.</p>\n' +
				     '<p>Trong c&aacute;c trường hợp kh&aacute;c, Người d&ugrave;ng sẽ nhận được th&ocirc;ng b&aacute;o khi Th&ocirc;ng Tin Người D&ugrave;ng c&oacute; thể được chia sẻ với b&ecirc;n thứ ba v&agrave; Người d&ugrave;ng sẽ c&oacute; quyền kh&ocirc;ng chấp thuận việc chia sẻ th&ocirc;ng tin.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">4. Quyền truy cập v&agrave; lựa chọn của Người d&ugrave;ng</h1>\n' +
				     '<p>Người d&ugrave;ng kh&ocirc;ng bắt buộc phải cung cấp bất kỳ th&ocirc;ng tin n&agrave;o cho ch&uacute;ng t&ocirc;i, tuy nhi&ecirc;n ch&uacute;ng t&ocirc;i sẽ y&ecirc;u cầu Người d&ugrave;ng cung cấp một số th&ocirc;ng tin nhất định để Người d&ugrave;ng c&oacute; thể nhận được c&aacute;c th&ocirc;ng tin về c&aacute;c sản phẩm, dịch vụ v&agrave; sự kiện của ch&uacute;ng t&ocirc;i cũng như để c&oacute; thể sử dụng được c&aacute;c sản phẩm, dịch vụ của ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<p>Người d&ugrave;ng c&oacute; thể xem, cập nhật v&agrave; x&oacute;a một số th&ocirc;ng tin nhất định về t&agrave;i khoản v&agrave; c&aacute;c tương t&aacute;c của Người d&ugrave;ng với Dịch vụ. Trong trường hợp Người d&ugrave;ng kh&ocirc;ng thể tự truy cập hoặc cập nhật th&ocirc;ng tin của m&igrave;nh, Người d&ugrave;ng lu&ocirc;n c&oacute; thể li&ecirc;n hệ với ch&uacute;ng t&ocirc;i để được hỗ trợ. Tuy nhi&ecirc;n trước khi cấp quyền truy cập hoặc cung cấp th&ocirc;ng tin cho Người d&ugrave;ng, ch&uacute;ng t&ocirc;i c&oacute; thể y&ecirc;u cầu c&aacute;c bằng chứng nhận dạng v&agrave; c&aacute;c th&ocirc;ng tin đầy đủ về c&aacute;c tương t&aacute;c của Người d&ugrave;ng với ch&uacute;ng t&ocirc;i theo ch&iacute;nh s&aacute;ch bảo vệ v&agrave; bảo mật dữ liệu của ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<p>Người d&ugrave;ng c&oacute; nhiều lựa chọn về việc thu thập v&agrave; sử dụng Th&ocirc;ng tin Người d&ugrave;ng của m&igrave;nh. Nhiều Dịch vụ c&oacute; chức năng cho ph&eacute;p Người d&ugrave;ng t&ugrave;y chọn về c&aacute;ch th&ocirc;ng tin đang được sử dụng. Người d&ugrave;ng c&oacute; thể chọn kh&ocirc;ng cung cấp một số th&ocirc;ng tin nhất định, nhưng sau đ&oacute; Người d&ugrave;ng c&oacute; thể kh&ocirc;ng tận dụng được một số Dịch vụ. Vui l&ograve;ng thực hiện theo hướng dẫn cụ thể tr&ecirc;n trang web v&agrave; ứng dụng của ch&uacute;ng t&ocirc;i tại từng thời điểm.</p>\n' +
				     '<p>Người d&ugrave;ng c&oacute; thể y&ecirc;u cầu ch&uacute;ng t&ocirc;i ngừng sử dụng hoặc gửi th&ocirc;ng tin cho mục đ&iacute;ch quảng b&aacute; bất cứ khi n&agrave;o v&agrave; v&agrave;o bất kỳ l&uacute;c n&agrave;o, vui l&ograve;ng hủy đăng k&yacute; hoặc điều chỉnh t&ugrave;y chọn theo hướng dẫn cụ thể tr&ecirc;n trang web v&agrave; ứng dụng của ch&uacute;ng t&ocirc;i tại từng thời điểm. Trường hợp Người d&ugrave;ng kh&ocirc;ng muốn nhận th&ocirc;ng b&aacute;o trong ứng dụng từ ch&uacute;ng t&ocirc;i, vui long điều chỉnh c&agrave;i đặt th&ocirc;ng b&aacute;o trong ứng dụng hoặc thiết bị của m&igrave;nh.</p>\n' +
				     '<p>Nếu Người d&ugrave;ng quyết định hủy đăng k&yacute; dịch vụ hoặc r&uacute;t lại sự đồng &yacute; của m&igrave;nh hoặc y&ecirc;u cầu ch&uacute;ng t&ocirc;i chỉnh sửa hay x&oacute;a c&aacute;c th&ocirc;ng tin, ch&uacute;ng t&ocirc;i sẽ cố gắng x&oacute;a th&ocirc;ng tin của Người d&ugrave;ng ngay khi c&oacute; thể hoặc trong thời hạn theo quy định của ph&aacute;p luật, mặc d&ugrave; ch&uacute;ng t&ocirc;i c&oacute; thể sẽ phải y&ecirc;u cầu th&ocirc;ng tin bổ sung trước khi c&oacute; thể xử l&yacute; y&ecirc;u cầu của Người d&ugrave;ng. Trong trường hợp n&agrave;y, ch&uacute;ng t&ocirc;i thường giữ một bản sao của phi&ecirc;n bản trước.</p>\n' +
				     '<p>Nếu Người d&ugrave;ng quyết định từ chối cung cấp th&ocirc;ng tin hoặc r&uacute;t lại sự đồng &yacute; của m&igrave;nh, xin lưu &yacute; rằng trong nhiều trường hợp ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng thể cung cấp Dịch vụ cho Người d&ugrave;ng hay đ&aacute;p ứng c&aacute;c y&ecirc;u cầu của Người d&ugrave;ng.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">5. Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng</h1>\n' +
				     '<p>Kh&ocirc;ng tiết lộ : Trong qu&aacute; tr&igrave;nh sử dụng Dịch Vụ m&agrave; ch&uacute;ng t&ocirc;i cung cấp, Người d&ugrave;ng c&oacute; quyền lựa chọn về việc cung cấp Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng cho ch&uacute;ng t&ocirc;i để việc trải nghiệm sử dụng Dịch Vụ đạt hiệu quả cao nhất. V&agrave; bất cứ khi n&agrave;o, Người d&ugrave;ng cũng c&oacute; to&agrave;n quyền quyết định việc ngừng cung cấp quyền truy cập th&ocirc;ng tin Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng bằng việc thực hiện theo hướng dẫn cụ thể tr&ecirc;n trang web v&agrave; ứng dụng của ch&uacute;ng t&ocirc;i tại từng thời điểm.</p>\n' +
				     '<p>Bằng việc lưu trữ, bảo mật v&agrave; to&agrave;n vẹn dữ liệu, ch&uacute;ng t&ocirc;i cam kết kh&ocirc;ng tiết lộ Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng cho bất kỳ b&ecirc;n n&agrave;o v&agrave; trong mọi trường hợp nếu kh&ocirc;ng c&oacute; sự đồng &yacute; trước của Người d&ugrave;ng.</p>\n' +
				     '<p>Kh&ocirc;ng tiết lộ việc sử dụng: Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng chỉ được sử dụng khi Người d&ugrave;ng cần th&ocirc;ng tin của người trong Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng để thực hiện một hoặc một số Dịch Vụ, Hoạt động tr&ecirc;n ứng dụng hoặc trang web của ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<p>Việc sử dụng Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng sẽ chỉ do Người d&ugrave;ng thực hiện. Ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng tiết lộ việc sử dụng Danh s&aacute;ch li&ecirc;n hệ của Người d&ugrave;ng cho bất kỳ b&ecirc;n thứ ba n&agrave;o, cho bất kỳ mục đ&iacute;ch n&agrave;o m&agrave; kh&ocirc;ng c&oacute; sự đồng &yacute; trước của Người d&ugrave;ng.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">6. Lưu trữ, Bảo mật v&agrave; to&agrave;n vẹn dữ liệu</h1>\n' +
				     '<p>Bảo mật l&agrave; ưu ti&ecirc;n h&agrave;ng đầu của ch&uacute;ng t&ocirc;i, hệ thống của ch&uacute;ng t&ocirc;i được thiết kế c&oacute; t&iacute;nh đến khả năng bảo đảm an to&agrave;n v&agrave; ri&ecirc;ng tư cho th&ocirc;ng tin của Người d&ugrave;ng, to&agrave;n bộ c&aacute;c Th&ocirc;ng tin Người d&ugrave;ng ph&aacute;t sinh từ v&agrave;/hoặc li&ecirc;n quan đến Dịch vụ sẽ được ch&uacute;ng t&ocirc;i lưu trữ, bảo mật v&agrave; đảm bảo an to&agrave;n bởi hệ thống của ch&uacute;ng t&ocirc;i hoặc đơn vị cung cấp dịch vụ cho ch&uacute;ng t&ocirc;i theo quy định của ph&aacute;p luật v&agrave; Ch&iacute;nh s&aacute;ch n&agrave;y.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i sẽ nỗ lực tối đa trong phạm vi cho ph&eacute;p để đảm bảo Th&ocirc;ng tin Người d&ugrave;ng được bảo vệ v&agrave; kh&ocirc;ng bị truy cập tr&aacute;i ph&eacute;p hoặc t&igrave;nh cờ, bị xử l&yacute; hoặc x&oacute;a bỏ. Ch&uacute;ng t&ocirc;i c&oacute; c&aacute;c biện ph&aacute;p th&iacute;ch hợp về kỹ thuật v&agrave; an ninh v&agrave; sẽ duy tr&igrave; c&aacute;c ti&ecirc;u chuẩn bảo mật hợp l&yacute;, đảm bảo rằng chỉ những người nhất định được tiếp cận dữ liệu m&agrave; ch&uacute;ng t&ocirc;i đang xử l&yacute;, được ph&eacute;p thực hiện việc x&oacute;a hoặc ti&ecirc;u hủy c&aacute;c th&ocirc;ng tin n&agrave;y.</p>\n' +
				     '<p>Th&ocirc;ng tin Người d&ugrave;ng sẽ được ch&uacute;ng t&ocirc;i lưu trữ trong khoảng thời gian cần thiết để ho&agrave;n th&agrave;nh c&aacute;c mục đ&iacute;ch sử dụng th&ocirc;ng tin như được đề cập trong Ch&iacute;nh s&aacute;ch n&agrave;y, đảm bảo cho Người d&ugrave;ng khả năng sử dụng li&ecirc;n tục c&aacute;c Dịch vụ trừ khi c&oacute; y&ecirc;u cầu hoặc ph&aacute;p luật c&oacute; quy định thời gian lưu trữ l&acirc;u hơn. Ch&uacute;ng t&ocirc;i sẽ thực hiện tất cả c&aacute;c bước hợp l&yacute; để đảm bảo rằng Th&ocirc;ng tin Người d&ugrave;ng kh&ocirc;ng bị lưu giữ l&acirc;u hơn mức cần thiết v&agrave; rằng ch&uacute;ng t&ocirc;i sẽ lu&ocirc;n tu&acirc;n thủ những y&ecirc;u cầu của luật ph&aacute;p v&agrave; quy định quản l&yacute; của Việt Nam li&ecirc;n quan đến việc lưu trữ th&ocirc;ng tin nhận dạng c&aacute; nh&acirc;n.</p>\n' +
				     '<p>Một số Th&ocirc;ng tin Người d&ugrave;ng c&oacute; thể được lưu trữ, truy cập từ hoặc chuyển đến c&aacute;c quốc gia ngo&agrave;i l&atilde;nh thổ Việt Nam hoặc chuyển giao cho đối t&aacute;c nước ngo&agrave;i cho c&aacute;c mục đ&iacute;ch như được m&ocirc; tả trong Ch&iacute;nh s&aacute;ch n&agrave;y. Trong c&aacute;c trường hợp như vậy, Th&ocirc;ng tin Người d&ugrave;ng sẽ tiếp tục được bảo vệ th&ocirc;ng qua c&aacute;c hợp đồng m&agrave; ch&uacute;ng t&ocirc;i giao kết với c&aacute;c c&aacute;c đối t&aacute;c hoặc b&ecirc;n cung cấp dịch vụ, đảm bảo c&oacute; c&aacute;c điều khoản bảo vệ dữ liệu c&aacute; nh&acirc;n theo ti&ecirc;u chuẩn của v&agrave; quy định của ph&aacute;p luật Việt Nam. Ch&uacute;ng t&ocirc;i cũng sẽ r&agrave; so&aacute;t c&aacute;c ch&iacute;nh s&aacute;ch bảo mật v&agrave; an to&agrave;n th&ocirc;ng tin của c&aacute;c b&ecirc;n thứ ba n&agrave;y nhằm đảm bảo rằng họ đang &aacute;p dụng c&aacute;c ti&ecirc;u chuẩn tương đương với c&aacute;c nguy&ecirc;n tắc theo Ch&iacute;nh s&aacute;ch n&agrave;y.</p>\n' +
				     '<p>Người d&ugrave;ng c&oacute; tr&aacute;ch nhiệm bảo vệ th&ocirc;ng tin t&agrave;i khoản của m&igrave;nh v&agrave; kh&ocirc;ng cung cấp bất kỳ th&ocirc;ng tin n&agrave;o li&ecirc;n quan đến t&agrave;i khoản, mật khẩu hay c&aacute;c phương thức x&aacute;c thực (như m&atilde; OTP) truy cập tr&ecirc;n c&aacute;c website, ứng dụng, phần mềm v&agrave; c&aacute;c c&ocirc;ng cụ kh&aacute;c (nếu c&oacute;). Trong trường hợp cần kh&ocirc;i phục mật khẩu t&agrave;i khoản, Người d&ugrave;ng đồng &yacute; chủ động lựa chọn v&agrave; cho ph&eacute;p b&ecirc;n thứ ba m&agrave; Người d&ugrave;ng đ&atilde; lựa chọn nhận mật khẩu sử dụng một lần (OTP). Theo đ&oacute;, Người d&ugrave;ng đồng &yacute; chia sẻ th&ocirc;ng tin t&agrave;i khoản của m&igrave;nh cho b&ecirc;n thứ ba đ&oacute; v&agrave; tự chịu tr&aacute;ch nhiệm với việc chia sẻ n&agrave;y.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i kh&ocirc;ng chịu tr&aacute;ch nhiệm về những vấn đề ph&aacute;t sinh khi Người d&ugrave;ng sử dụng Dịch vụ từ c&aacute;c c&ocirc;ng cụ, giao diện kh&aacute;c kh&ocirc;ng phải l&agrave; website, ứng dụng, phần mềm ch&iacute;nh thức, được ph&eacute;p sử dụng trong phạm vi của Dịch vụ. B&ecirc;n cạnh đ&oacute;, Dịch vụ c&oacute; thể chứa c&aacute;c li&ecirc;n kết đến v&agrave; từ dịch vụ của c&aacute;c mạng đối t&aacute;c, c&ocirc;ng ty quảng c&aacute;o hoặc c&aacute;c đơn vị phụ thuộc của ch&uacute;ng. Xin lưu &yacute; rằng mỗi b&ecirc;n sẽ c&oacute; ch&iacute;nh s&aacute;ch bảo mật ri&ecirc;ng của m&igrave;nh v&agrave; việc lưu trữ, bảo mật, sử dụng những th&ocirc;ng tin cung cấp cho c&aacute;c b&ecirc;n n&agrave;y nằm ngo&agrave;i phạm vi quản l&yacute; của ch&uacute;ng t&ocirc;i. Do đ&oacute;, ch&uacute;ng t&ocirc;i sẽ kh&ocirc;ng chịu bất kỳ tr&aacute;ch nhiệm hoặc nghĩa vụ ph&aacute;p l&yacute; n&agrave;o đối với việc việc lưu trữ, bảo mật v&agrave; sử dụng những th&ocirc;ng tin n&agrave;y. Vui l&ograve;ng kiểm tra ch&iacute;nh s&aacute;ch về quyền ri&ecirc;ng tư, ch&iacute;nh s&aacute;ch bảo mật hoặc c&aacute;c ch&iacute;nh s&aacute;ch tương tự của c&aacute;c đơn vị n&agrave;y trước khi gửi bất kỳ dữ liệu c&aacute; nh&acirc;n n&agrave;o cho họ.</p>\n' +
				     '<br/><h1 style="font-size:16px; font-weight: bold; color: #338FE3;">7. Li&ecirc;n hệ</h1>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i cam kết bảo vệ sự ri&ecirc;ng tư của th&ocirc;ng tin thu thập được. Trường hợp c&oacute; c&acirc;u hỏi hoặc y&ecirc;u cầu n&agrave;o li&ecirc;n quan đến dữ liệu của m&igrave;nh, vui l&ograve;ng li&ecirc;n hệ với ch&uacute;ng t&ocirc;i bất cứ khi n&agrave;o theo c&aacute;c hướng dẫn cụ thể tr&ecirc;n trang web v&agrave; ứng dụng của ch&uacute;ng t&ocirc;i.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i sẽ xử l&yacute; y&ecirc;u cầu của Người d&ugrave;ng trong v&ograve;ng 14 ng&agrave;y v&agrave; t&igrave;m c&aacute;ch giải quyết vấn đề trong v&ograve;ng một th&aacute;ng kể từ khi nhận được. Trường hợp vấn đề của Người d&ugrave;ng phức tạp hoặc ch&uacute;ng t&ocirc;i c&oacute; khối lượng lớn c&aacute;c vấn đề cần giải quyết, ch&uacute;ng t&ocirc;i sẽ th&ocirc;ng tin đến Người d&ugrave;ng về việc ch&uacute;ng t&ocirc;i cần nhiều thời gian hơn để giải quyết v&agrave; ch&uacute;ng t&ocirc;i sẽ t&igrave;m c&aacute;ch giải quyết trong v&ograve;ng ba th&aacute;ng kể từ khi vấn đề được n&ecirc;u ra lần đầu ti&ecirc;n.</p>\n' +
				     '<p>Ch&uacute;ng t&ocirc;i c&oacute; thể chấp nhận lời đề nghị của Người d&ugrave;ng hoặc ch&uacute;ng t&ocirc;i c&oacute; thể từ chối lời đề nghị của Người d&ugrave;ng với l&yacute; do ch&iacute;nh đ&aacute;ng, v&iacute; dụ như y&ecirc;u cầu x&oacute;a dữ liệu giao dịch l&agrave; kh&ocirc;ng được chấp nhận v&igrave; ch&uacute;ng t&ocirc;i phải lưu trữ dữ liệu n&agrave;y theo quy định của ph&aacute;p luật&hellip;v.v.</p>'
		     }}
		/>
	</div>
);

export default PolicyPrivacy;