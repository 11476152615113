// src/components/common/Text/index.jsx
import React from 'react';
import cn from 'classnames';
import {VARIANT} from '../../../constants';
import Underline from '../Underline/Underline';
import NewLink from "../../NewLink/NewLink";

const MenuItem = ({label, className, variant, to, id}) => {
	
	let classN = ''
	if (variant === VARIANT.ACTIVE) {
		//generate tailwind css text sm, text bold, primary color
		classN += 'font-bold text-primary';
	}
	
	return (
		<div className='w-fit'>
			<NewLink
				to={to}
				className={cn('text-sm', classN, className)}
				aria-label={label}
			
			>
				{label}
				{variant === VARIANT.ACTIVE && <Underline/>}
			</NewLink>
		</div>
	)
};

export default MenuItem;